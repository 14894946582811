<div
  *ngIf="isVisible"
  [@fadeInOut]="'in'"
  class="absolute bottom-14 left-1/2 transform -translate-x-1/2 p-4 rounded-lg shadow flex items-center justify-center z-[9999]
    bg-{{ toastServiceOptions.backgroundColor }}
    text-{{ toastServiceOptions.textColor }}">
  {{ toastServiceOptions.message }}
  <div
    (click)="close()"
    class="ml-8 inline-block p-0.5 hover:cursor-pointer hover:rounded-full hover:bg-white hover:bg-opacity-50 hover:shadow">
    <crew-ui-icon name="close" [color]="toastServiceOptions.textColor" />
  </div>
</div>
