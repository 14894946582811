import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  public get isXs() {
    return window.innerWidth <= this.screens.xs.max;
  }

  public get isSm() {
    return (
      window.innerWidth >= this.screens.sm.min &&
      window.innerWidth <= this.screens.sm.max
    );
  }

  public get isMd() {
    return (
      window.innerWidth >= this.screens.md.min &&
      window.innerWidth <= this.screens.md.max
    );
  }

  public get isLg() {
    return (
      window.innerWidth >= this.screens.lg.min &&
      window.innerWidth <= this.screens.lg.max
    );
  }

  public get isXl() {
    return (
      window.innerWidth >= this.screens.xl.min &&
      window.innerWidth <= this.screens.xl.max
    );
  }

  public get isLtSm() {
    return window.innerWidth <= this.screens['lt-sm'].max;
  }

  public get isLtMd() {
    return window.innerWidth <= this.screens['lt-md'].max;
  }

  public get isLtLg() {
    return window.innerWidth <= this.screens['lt-lg'].max;
  }

  public get isLtXl() {
    return window.innerWidth <= this.screens['lt-xl'].max;
  }

  public get isGtXs() {
    return window.innerWidth >= this.screens['gt-xs'].min;
  }

  public get isGtSm() {
    return window.innerWidth >= this.screens['gt-sm'].min;
  }

  public get isGtMd() {
    return window.innerWidth >= this.screens['gt-md'].min;
  }

  public get isGtLg() {
    return window.innerWidth >= this.screens['gt-lg'].min;
  }

  // copied from lib tailwind.config.js
  private screens = {
    xs: { max: 599 },
    sm: { min: 600, max: 959 },
    md: { min: 960, max: 1279 },
    lg: { min: 1280, max: 1919 },
    xl: { min: 1920, max: 5000 },
    'lt-sm': { max: 599 },
    'lt-md': { max: 959 },
    'lt-lg': { max: 1279 },
    'lt-xl': { max: 5000 },
    'gt-xs': { min: 600 },
    'gt-sm': { min: 960 },
    'gt-md': { min: 1280 },
    'gt-lg': { min: 1920 },
  };
}
