<crew-ui-overlay *ngIf="isVisible">
  <crew-ui-card class="lt-sm:h-full lt-sm:w-full">
    <div class="roundex-t-xl h-full overflow-hidden px-4 pb-4">
      <div
        class="-mx-4 flex items-center justify-between rounded-t-xl px-4 py-4 gt-lg:px-6">
        <span
          (click)="close()"
          class="cursor-pointer text-stratosphere dark:text-troposphere">
          Close
        </span>
        <span
          [class.cursor-pointer]="sendable"
          [class.dark:text-troposphere]="sendable"
          [class.text-stratosphere]="sendable"
          [class.cursor-none]="!sendable || sending"
          [class.dark:text-steel]="!sendable || sending"
          [class.text-aluminum]="!sendable || sending"
          (click)="send()">
          Send
        </span>
      </div>
      <div class="border-y border-cirrus py-2 dark:border-steel">
        <p class="mb-2 text-lg font-bold gt-lg:ml-4">HISEND</p>
        <p class="gt-lg:ml-4">
          <span class="text-nickel">To: </span>
          <span>{{ title }}</span>
        </p>
      </div>
      <div class="h-[70vh] w-[70vw] lt-sm:w-full gt-lg:mt-4 gt-lg:px-4">
        <textarea
          [disabled]="sending"
          [(ngModel)]="message"
          autofocus="true"
          class="h-full w-full border-none dark:bg-charcoal dark:text-white"></textarea>
      </div>
    </div>
  </crew-ui-card>
</crew-ui-overlay>
