import { Injectable, signal } from '@angular/core';

import { Colors } from '../colors';
import { CrewUIToastOptions } from '../lib/crew-ui-toast/crew-ui-toast.types';
import { CrewUIDarkModeService } from './darkmode.service';

@Injectable({
  providedIn: 'root',
})
export class CrewUIToastService {
  isVisible = signal(false);

  options = signal<CrewUIToastOptions>({
    message: '',
    textColor: this.defaultTextColor,
    backgroundColor: this.defaultBackgroundColor,
    timeout: 3000,
  });

  constructor(private darkModeService: CrewUIDarkModeService) {}

  hideToast() {
    this.isVisible.set(false);
    this.options.set({ message: '' });
  }

  showToast(options: CrewUIToastOptions) {
    options.textColor = options.textColor ?? this.defaultTextColor;
    options.backgroundColor =
      options.backgroundColor ?? this.defaultBackgroundColor;

    this.options.set(options);
    this.isVisible.set(true);

    setTimeout(() => {
      this.hideToast();
    }, options.timeout ?? 3000);
  }

  get defaultTextColor() {
    return this.darkModeService.isDarkMode() ? Colors.White : Colors.Black;
  }

  get defaultBackgroundColor() {
    return this.darkModeService.isDarkMode() ? Colors.Black : Colors.White;
  }
}
