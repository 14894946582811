import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import {
  Colors,
  CrewUIDarkModeService,
  CrewUIIconComponent,
} from '../../../shared/components/crew-ui';
import { MessagePriority } from '../../../shared/services/azure/message.service.types';
import { MessageService, MessageTitles } from '../message.service';

@Component({
  selector: 'crew-web-message-menu-list-item',
  templateUrl: './message-menu-list-item.component.html',
  styleUrls: ['./message-menu-list-item.component.css'],
  standalone: true,
  imports: [CommonModule, CrewUIIconComponent],
})
export class MessageMenuListItemComponent {
  @Input()
  priority?: MessagePriority;

  @Input()
  title?: MessageTitles;

  @Input()
  color = Colors.Black;

  @Input({ required: true })
  icon!: string;

  @Input()
  isModal = false;

  @Input()
  modalTitle?: string;

  @Input()
  messageCount?: number;

  colors = Colors;

  get selectedPriority(): MessagePriority | undefined {
    return this.messageService.selectedMenu();
  }

  get isDark(): boolean {
    return this.darkModeService.isDarkMode();
  }

  constructor(
    private darkModeService: CrewUIDarkModeService,
    private messageService: MessageService,
  ) {}

  // count(): number {
  //   return this.messageService.getUnreadCount(this.priority) || 0;
  // }

  showMessageMenu(event: Event): void {
    event.preventDefault();
    if (this.isModal) this.showMessageModal();
    else this.messageService.selectedMenu.set(this.priority);
  }

  showMessageModal(): void {
    if (!this.modalTitle) return;
    this.messageService.selectedModalTitle.set(this.modalTitle);
    this.messageService.isMessageModalVisible.set(
      !this.messageService.isMessageModalVisible(),
    );
  }
}
