import { computed, Injectable, signal, WritableSignal } from '@angular/core';

import { SabreDocumentKeywordDisplay } from '../../shared/components/sabre-document/sabre-document.constant';
import { SabreDocumentService } from '../../shared/components/sabre-document/sabre-document.service';
import {
  FullMessageData,
  MessagePriority,
} from '../../shared/services/azure/message.service.types';
import {
  CommonResults,
  GetSabreCommandsResponse,
} from '../../shared/services/azure/sabre.service.types';
import { TimeService } from '../../shared/services/time.service';
import { UserService } from '../../shared/services/user.service';
import { BusinessUnit } from '../../shared/types';
import { formatDate } from '../../shared/utils';
import { sortArrayBy } from '../../shared/utils/array.utils';
export enum MessageTitles {
  Deleted = 'Deleted',
  HI6 = 'HI6',
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  ReadAndAcknowledge = 'Read & Acknowledge',
  Saved = 'Saved',
}

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(
    private userService: UserService,
    private sabreDocumentService: SabreDocumentService,
    private timeService: TimeService,
  ) {}

  private logState = false;

  hi6Count: WritableSignal<number> = signal(0);

  hi6Message: WritableSignal<string> = signal(``);

  selectedMessage = signal<FullMessageData | undefined>(undefined);

  selectedMenu = signal<MessagePriority | undefined>(undefined);

  selectedTitle = computed(() => {
    switch (this.selectedMenu()) {
      case MessagePriority.Deleted:
        return MessageTitles.Deleted;
      // case MessagePriority.HI6:
      //   return MessageTitles.HI6;
      case MessagePriority.High:
        return MessageTitles.High;
      case MessagePriority.Low:
        return MessageTitles.Low;
      case MessagePriority.Medium:
        return MessageTitles.Medium;
      case MessagePriority.ReadAndAcknowledge:
        return MessageTitles.ReadAndAcknowledge;
      case MessagePriority.Saved:
        return MessageTitles.Saved;
      default:
        return;
    }
  });

  selectedModalTitle = signal<string>('');

  foundMessages = signal<FullMessageData[] | undefined>(undefined);

  allMessages = signal<FullMessageData[] | undefined>(undefined);

  allUnreadMessagesCount = computed(
    () => this.allMessages()?.filter((m) => m.messageRead === 0).length,
  );

  readAcknowledgePriorityMessages = computed(() => {
    if (this.userService.apiDetails()?.businessUnit === BusinessUnit.AAPI) {
      let messages = this.allMessages()?.filter(
        (m) =>
          m.priorityNumber === MessagePriority.ReadAndAcknowledge &&
          !m.acknowledged &&
          !m.messageDeleted,
      );

      if (!messages) return;

      messages = sortArrayBy(messages, 'effectiveDate', 'desc');

      return messages;
    } else return undefined;
  });

  readAcknowledgePriorityUnreadMessagesCount = computed(
    () =>
      this.readAcknowledgePriorityMessages()?.filter((m) => m.messageRead === 0)
        .length,
  );

  allReadAcknowledgePriorityMessages = computed(() => {
    if (this.userService.apiDetails()?.businessUnit === BusinessUnit.AAPI) {
      let messages = this.allMessages()?.filter(
        (m) =>
          m.priorityNumber === MessagePriority.ReadAndAcknowledge &&
          !m.messageDeleted,
      );

      if (!messages) return;

      messages = sortArrayBy(messages, 'effectiveDate', 'desc');

      return messages;
    } else return;
  });

  // hi6PriorityMessages = computed(() =>
  //   this.allMessages()?.filter((m) => m.priorityNumber === MessagePriority.HI6),
  // );

  // hi6PriorityUnreadMessagesCount = computed(
  //   () => this.hi6PriorityMessages()?.filter((m) => m.messageRead === 0).length,
  // );

  highPriorityMessages = computed(() => {
    if (this.userService.apiDetails()?.businessUnit === BusinessUnit.AAPI) {
      return undefined;
    } else {
      let messages = this.allMessages()?.filter(
        // using R/A here is not a bug
        (m) =>
          m.priorityNumber === MessagePriority.ReadAndAcknowledge &&
          !m.messageDeleted,
      );
      if (!messages) return;

      messages = sortArrayBy(messages, 'effectiveDate', 'desc');

      return messages;
    }
  });

  highPriorityUnreadMessagesCount = computed(
    () =>
      this.highPriorityMessages()?.filter((m) => m.messageRead === 0).length,
  );

  mediumPriorityMessages = computed(() => {
    let messages = this.allMessages()?.filter(
      (m) => m.priorityNumber === MessagePriority.Medium && !m.messageDeleted,
    );

    if (!messages) return;

    messages = sortArrayBy(messages, 'effectiveDate', 'desc');

    return messages;
  });

  mediumPriorityUnreadMessagesCount = computed(
    () =>
      this.mediumPriorityMessages()?.filter((m) => m.messageRead === 0).length,
  );

  lowPriorityMessages = computed(() => {
    let messages = this.allMessages()?.filter(
      (m) => m.priorityNumber === MessagePriority.Low && !m.messageDeleted,
    );

    if (!messages) return;

    messages = sortArrayBy(messages, 'effectiveDate', 'desc');

    return messages;
  });

  lowPriorityUnreadMessagesCount = computed(
    () => this.lowPriorityMessages()?.filter((m) => m.messageRead === 0).length,
  );

  deletedMessages = computed(() => {
    let messages = this.allMessages()?.filter((m) => m.messageDeleted);

    if (!messages) return;

    messages = sortArrayBy(messages, 'effectiveDate', 'desc');

    return messages;
  });

  deletedMessagesCount = computed(() => this.deletedMessages()?.length);

  // * This is sorted and set by 'message.idb.service.ts:refreshMessages()'
  savedMessages: WritableSignal<FullMessageData[] | undefined> =
    signal(undefined);

  savedMessagesUnreadCount = computed(() => this.savedMessages()?.length);

  lowAndMediumUnreadMessageCount = computed(() => {
    const low = this.lowPriorityUnreadMessagesCount() ?? 0;
    const medium = this.mediumPriorityUnreadMessagesCount() ?? 0;
    return low + medium;
  });

  isMessageModalVisible = signal(false);

  hasNextMessage = computed(() => {
    const selected = this.selectedMessage();
    const menuMessages = this.getSelectedMenuMessages();

    if (!menuMessages || !selected) return false;

    const selectedIndex = menuMessages.findIndex(
      (message) => message.messageId === selected.messageId,
    );

    const result =
      selectedIndex >= 0 && selectedIndex < menuMessages.length - 1;

    return result;
  });

  hasPreviousMessage = computed(() => {
    const selected = this.selectedMessage();
    const menuMessages = this.getSelectedMenuMessages();

    if (!menuMessages || !selected) return false;

    const selectedIndex = menuMessages.findIndex(
      (message) => message.messageId === selected.messageId,
    );

    const result = selectedIndex > 0;

    return result;
  });

  lastRefreshTime = signal(new Date());

  lastRefreshTimeFormatted = computed(() => {
    return `Updated ${formatDate(
      this.lastRefreshTime(),
      'MM/DD/YYYY',
    )} at ${this.timeService.getTime(this.lastRefreshTime())}`;
  });

  getSelectedMenuMessages(): FullMessageData[] | undefined {
    if (!this.selectedMenu()) return;

    if (this.foundMessages()) return this.foundMessages();

    switch (this.selectedMenu()) {
      // case MessagePriority.HI6:
      //   return this.hi6PriorityMessages();
      case MessagePriority.High:
        return this.highPriorityMessages();
      case MessagePriority.Low:
        return this.lowPriorityMessages();
      case MessagePriority.Medium:
        return this.mediumPriorityMessages();
      case MessagePriority.ReadAndAcknowledge:
        return this.allReadAcknowledgePriorityMessages();
      case MessagePriority.Saved:
        return this.savedMessages();
      case MessagePriority.Deleted:
        return this.deletedMessages();
      default:
        return;
    }
  }

  getUnreadCount(priority?: MessagePriority): number | undefined {
    switch (priority) {
      case MessagePriority.Deleted:
        return this.deletedMessagesCount();
      // case MessagePriority.HI6:
      //   return this.hi6PriorityUnreadMessagesCount();
      case MessagePriority.High:
        return this.highPriorityUnreadMessagesCount();
      case MessagePriority.Low:
        return this.lowPriorityUnreadMessagesCount();
      case MessagePriority.Medium:
        return this.mediumPriorityUnreadMessagesCount();
      case MessagePriority.ReadAndAcknowledge:
        return this.readAcknowledgePriorityUnreadMessagesCount();
      case MessagePriority.Saved:
        return this.savedMessagesUnreadCount();
      default:
        return;
    }
  }

  search(input: string): void {
    input = input.trim().toLowerCase();

    const messages = this.getSelectedMenuMessages();

    if (!messages) return;

    const found = messages.filter(
      (m) =>
        m.messageTitle.toLowerCase().includes(input) ||
        m.messageSignature.toLowerCase().includes(input) ||
        m.body?.toLowerCase().includes(input),
    );

    this.foundMessages.set(found);
  }

  clearSearch(): void {
    this.foundMessages.set(undefined);
  }

  setSelectedMessage(message: FullMessageData): void {
    this.selectedMessage.set(message);
  }

  /**
   * Get Sabre HI6 messages
   */
  fetchHi6Get(showNextMessage = false): void {
    const payload = this.sabreDocumentService.getHi6MessagePayload(
      SabreDocumentKeywordDisplay.HI6GET,
      showNextMessage,
    );
    if (!payload) return;
    this.sabreDocumentService.getSabreCommandRawData(payload).subscribe({
      next: (response: GetSabreCommandsResponse) => {
        const hasCommonResults =
          response.success &&
          response.commonResults &&
          response.commonResults.length > 0;
        if (hasCommonResults) {
          const keyword = showNextMessage
            ? SabreDocumentKeywordDisplay.HI6DELETE
            : SabreDocumentKeywordDisplay.HI6GET;
          const messageData = response.commonResults.filter(
            (data: CommonResults) => keyword === data.keywordDisplay,
          );
          this.hi6Message.set(messageData[0]?.returnData);
          if (response.commonResults.length > 1) {
            const messageCount = response.commonResults.filter(
              (data: CommonResults) =>
                SabreDocumentKeywordDisplay.HI6COUNT === data.keywordDisplay,
            );
            this.setHi6Count(messageCount[0]?.returnData);
          }
        }
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  hi6MessageTemplate(message: string): FullMessageData {
    return {
      messageId: Date.now(),
      messageTitle: '',
      priorityNumber: MessagePriority.Saved,
      effectiveDate: new Date().toString(),
      acknowledgementRequired: false,
      messageSignature: 'HI6 Message',
      messageDeleted: false,
      messageRead: 0,
      messageTab: SabreDocumentKeywordDisplay.HI6GET,
      acknowledged: false,
      acknowledgeRequired: 0,
      delEmpId: 0,
      empId: 0,
      insEmpId: 0,
      messageReadUnique: 0,
      siteMinderEmpId: 0,
      updEmpId: 0,
      emulated: false,
      messageExpired: false,
      body: message,
      isHi6Message: true,
    };
  }

  /**
   * Set HI6 message count
   * @param count
   */
  setHi6Count(count: string): void {
    this.hi6Count.set(Math.max(0, parseInt(count, 10)));
  }

  /**
   * Get Sabre HI6 count
   */
  fetchHi6Count(): void {
    const payload = this.sabreDocumentService.getHi6MessagePayload(
      SabreDocumentKeywordDisplay.HI6COUNT,
    );
    if (!payload) return;
    this.sabreDocumentService.getSabreCommandRawData(payload).subscribe({
      next: (response: GetSabreCommandsResponse) => {
        const hasCommonResults =
          response.success &&
          response.commonResults &&
          response.commonResults.length > 0;
        if (hasCommonResults) {
          this.setHi6Count(response.commonResults[0]?.returnData);
        }
      },
      error: (e) => {
        console.error(e);
      },
    });
  }
}
