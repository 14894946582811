<div *ngIf="pdfString" class="h-full w-full">
  <ngx-extended-pdf-viewer
    [base64Src]="pdfString"
    [filenameForDownload]="getFileName()"
    [contextMenuAllowed]="false"
    [showToolbar]="true"
    [showSidebarButton]="false"
    [showFindButton]="true"
    [showPagingButtons]="false"
    [showDrawEditor]="false"
    [showStampEditor]="false"
    [showTextEditor]="false"
    [showZoomButtons]="false"
    [showPresentationModeButton]="false"
    [showOpenFileButton]="false"
    [showPrintButton]="false"
    [showDownloadButton]="true"
    [showSecondaryToolbarButton]="true"
    [showRotateButton]="false"
    [showHandToolButton]="false"
    [showScrollingButton]="false"
    [showSpreadButton]="false"
    [showPropertiesButton]="false">
  </ngx-extended-pdf-viewer>
</div>
