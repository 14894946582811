import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';

import {
  Colors,
  CrewUICardComponent,
  CrewUIDarkModeService,
  CrewUIOverlayComponent,
  CrewUIToastService,
} from '../../../shared/components/crew-ui';
import { CalendarService } from '../../../shared/services/azure/calendar.service';
import { GetScheduleDetailsRequest } from '../../../shared/services/azure/calendar.service.types';
import { SabreService } from '../../../shared/services/azure/sabre.service';
import { HiSendServiceRequest } from '../../../shared/services/azure/sabre.service.types';
import { UserService } from '../../../shared/services/user.service';
import { BusinessUnit } from '../../../shared/types';
import { MessageService } from '../message.service';

@Component({
  selector: 'crew-web-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    CrewUIOverlayComponent,
    CrewUICardComponent,
    FormsModule,
  ],
})
export class MessageModalComponent {
  colors = Colors;

  message = '';

  sending = false;

  get sendable() {
    return this.message.trim() !== '';
  }

  get isDark() {
    return this.darkModeService.isDarkMode();
  }

  get isVisible() {
    return this.messageService.isMessageModalVisible();
  }

  get title() {
    return this.messageService.selectedModalTitle();
  }

  constructor(
    private calendarService: CalendarService,
    private darkModeService: CrewUIDarkModeService,
    private messageService: MessageService,
    private sabreService: SabreService,
    private toastService: CrewUIToastService,
    private userService: UserService,
  ) {}

  close() {
    this.message = '';
    this.messageService.isMessageModalVisible.set(false);
  }

  send() {
    this.sending = true;

    const getScheduleDetailsPayload: GetScheduleDetailsRequest = {
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      businessUnit: this.userService.emulatedOrDefaultBusinessUnit(),
      employeeLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      appSessionId: this.userService.appSession(),
      siteMinderEmployeeId: this.userService.employeeNumber(),
    };

    this.calendarService
      .getScheduleDetails(getScheduleDetailsPayload)
      .subscribe((scheduleDetails) => {
        if (!scheduleDetails.bidStatuses || !scheduleDetails.bidStatuses[0]) {
          this.showError(
            `Could not load bid status for ${
              this.userService.apiDetails()?.emulatedFirstName ??
              this.userService.firstName()
            } ${
              this.userService.apiDetails()?.emulatedLastName ??
              this.userService.lastName()
            }.`,
          );
          return;
        }

        const hisendPayload: HiSendServiceRequest = {
          airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
          businessUnit:
            this.userService.emulatedOrDefaultBusinessUnit() ??
            BusinessUnit.AAPI,
          employeeLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
          siteMinderEmpId: this.userService.employeeNumber(),
          appSessionId: this.userService.appSession(),
          fourpartBidStatus: {
            crewBase: scheduleDetails.bidStatuses[0].currentBase,
            crewEquipment: scheduleDetails.bidStatuses[0].currentEquipment,
            crewDivison: scheduleDetails.bidStatuses[0].currentDivision,
            crewPosition: scheduleDetails.bidStatuses[0].currentPosition,
          },
          commonRequestArray: [
            {
              commandId: 9999,
              keyword: 'HISEND',
              keywordDisplay: this.title,
              parameters: this.message.trim(),
              printOrder: 1,
              systemName: 'FOS',
              deleteHI6Message: false,
              firstDeleteHI6Message: false,
            },
          ],
          print: false,
          printerLNIATA: '',
          view: true,
        };

        this.sabreService.hiSendService(hisendPayload).subscribe((x) => {
          if (x.errorMessage) {
            this.showError(x.errorMessage);
          } else {
            this.close();

            this.toastService.showToast({
              backgroundColor: Colors.Zanzibar,
              message: 'Message sent successfully.',
            });
          }

          this.sending = false;
        });
      });
  }

  private showError(message: string) {
    this.toastService.showToast({
      backgroundColor: Colors.Afterburner,
      message,
    });
  }
}
