export enum SabreDocumentSystemName {
  FOS = 'FOS',
  RES = 'RES',
}
export enum SabreDocumentKeyword {
  HSS = 'HSS',
  NSV = 'NSV',
  NAP = 'NAP',
  NSNST = 'NS/NST',
  CDI = 'CDI',
  RGT = 'RGT',
  EFLIGHTPLAN = 'CIJX_com.amr.crewcheckin.connector.FlightPlanRequest',
  FLIGHTKEYSEFLIGHTPLAN = 'CIJX_FlightKeys',
  VPQ = 'VPQ',
  VPR = 'VPR',
  VPDH = 'VPDH',
}

export enum SabreDocumentKeywordDisplay {
  HSS = 'HSS',
  CREWHOTELLIMOINFO = 'CREW HOTEL LIMO INFO',
  HI2 = 'HI2',
  HI1 = 'HI1',
  HI6GET = 'HI6 Get',
  HI6COUNT = 'HI6 Count',
  HI6DELETE = 'HI6 Delete',
  NSNST = 'NS/NST',
  CDI = 'CDI',
  RGT = 'RGT ARR',
  EFLIGHTPLAN = 'EFLIGHT PLAN',
  FLIGHTKEYSEFLIGHTPLAN = 'FLIGHT KEYS-EFLIGHT PLAN',
}

export enum TripSignInMessage {
  EFlightPlanSuccess = 'The flight plan has been signed and also confirms you fit for duty.',
  EFlightPlanFailure = `We couldn't load the information you're looking for.`,
  CrewTripSignInFailure = `We couldn’t sign you in for this trip. Please try again or contact the CCI team for support.`,
  CrewTripSignInSuccessPilot = `You're signed in for this trip and confirmed fit for duty for leg 1.`,
  CrewTripSignInSuccessFo = `You're signed in for this trip.`,
  V2SignFlightPlanSuccess = 'The flight plan has been signed and also confirms you fit for duty.',
  V2SignFlightPlanFailure = `We couldn't load the information you're looking for.`,
  V2ConfirmFFDSuccess = `you've been confirmed fit for duty.`,
}

export enum SabreCommandParameter {
  HSS = `/[CREW_SEAT]/[SEQ_NUMBER]/[SEQ_DATE]`,
  CREWHOTELLIMOINFO = '/[FLIGHTNUMBER]/[DEPARTUREDATE]/[DEPARTURESTATION]/[ARRIVALSTATION]',
  HI2 = `/[EMPNUMBER]/O`,
  HI1 = `/[EMPNUMBER]`,
  HI6 = `/[EMPNUMBER]`,
  NSNST = `/[FLIGHTNUMBER]/[DEPARTUREDATE]/[DEPARTURESTATION]`,
  CDI = `[FLIGHTNUMBER][DEPARTUREDATE][DEPARTURESTATION][ARRIVALSTATION][DEPTIME-ACTUAL][GMTDEPDATETIME]`,
  RGT = `[ARRIVALSTATION]/[ARRIVALGATE]/[DEPARTURETIME]/[ARRIVALTIME]`,
  EFLIGHTPLAN = `PTR/514F07|WBD*[FLIGHTNUMBER]/[FLTORIGINDATE]/[DEPTIME-ACTUAL][SPACE][DEPARTURESTATION]|JPR[FLIGHTNUMBER]/[FLTORIGINDATE]/[DEPTIME-ACTUAL][SPACE][DEPARTURESTATION]|FIL[FLIGHTNUMBER]/[FLTORIGINDATE]/[DEPTIME-ACTUAL][SPACE][DEPARTURESTATION]/APAX|SLWB[FLIGHTNUMBER]/[DEPARTUREDATE-DAY]/[DEPTIME-ACTUAL][SPACE][DEPARTURESTATION]|FI[FLIGHTNUMBER]/[FLTORIGINDATE]/[DEPTIME-ACTUAL][SPACE][DEPARTURESTATION]|IFTA/[FLIGHTNUMBER]/[FLTORIGINDATE]/[DEPTIME-ACTUAL]/[DEPARTURESTATION]|NSC/[FLIGHTNUMBER]/[DEPARTUREDATE]/[DEPARTURESTATION]|PTR/END`,
  FLIGHTKEYSEFLIGHTPLAN = `WBD*[FLIGHTNUMBER]/[DEPARTUREDATE]/[DEPTIME-ACTUAL][SPACE][DEPARTURESTATION]/|FKY_FLIGHT_PLAN|NSC/[FLIGHTNUMBER]/[DEPARTUREDATE]/[DEPARTURESTATION]/[DEPTIME-ACTUAL]|FI[FLIGHTNUMBER]/[FLTORIGINDATE]/[DEPTIME-ACTUAL][SPACE][DEPARTURESTATION]|FIL[FLIGHTNUMBER]/[FLTORIGINDATE]/[DEPTIME-ACTUAL][SPACE][DEPARTURESTATION]/APAX|SLS*[DEPARTURESTATION]/FC|SLS*[DEPARTURESTATION]/DI|SLS*[ARRIVALSTATION]/FC|IFTA/[FLIGHTNUMBER]/[FLTORIGINDATE]/[DEPTIME-ACTUAL]/[DEPARTURESTATION]`,
}

export enum FlightPlanSignature {
  ConfirmFFD = `confirmedFFD`,
  SignedFlightPlan = `signedFlightPlan`,
}

export enum ApiCallTypes {
  SabreCall = 'Sabre',
  TripSignIn = `TripSignIn`,
  V2SignFlightPlan = `V2SignFlightPlan`,
}

export const SIGNATURE_REMARK = `Signature from Crew web`;
export const FLIGHT_PLAN_VERSION = `1.0`;
