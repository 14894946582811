export const removeDeepLinkingTags = (str: string): string => {
  return str
    ? str.replace(new RegExp('<a[^>]*deep-link[^>]*>(.*?)</a>', 'g'), '$1')
    : '';
};

export const sanitizeText = (
  text: string,
  wrapPre?: boolean,
  preserveHtml?: boolean,
): string => {
  if (text && text.indexOf('CDATA') !== -1) {
    text = removeCData(text);
  }
  if (wrapPre) {
    return wrapText(text.replace(new RegExp('\r?\n', 'g'), '<br/>'), {
      startWrap: '<pre>',
      endWrap: '</pre>',
    });
  } else {
    return preserveHtml ? text : escapeHtml(text);
  }
};

export const removeCData = (text: string): string => {
  return text.replace('<![CDATA[', '').replace(']]>', '');
};

export const wrapText = (
  text: string,
  wrapper: {
    startWrap: string;
    endWrap: string;
  },
): string => {
  return `${wrapper.startWrap}${text}${wrapper.endWrap}`;
};

/*
 * We have challenge with deep linking and sabre commands that has <S in the response.
 * we either dont show one so this method initially converts everything to HTML encoding and replace
 * tags only for deep linking back to < and > - Sai K Tagore.
 */
export const escapeHtml = (unsafe: string): string => {
  return (
    unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      // .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
      // https://regex101.com/r/LCO1nK/1 - To see how it works - Sai K Tagore.
      .replace(
        new RegExp(
          '(&lt;)(a.*?deep-link")(&gt;)([ a-zA-Z0-9/]{1,10})(&lt;)(/a)(&gt;)',
          'g',
        ),
        '<$2>$4<$6>',
      )
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepClone = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};
