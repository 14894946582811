export const environment = {
  version: '1.0.8-pwa',
  production: false,
  serviceWorker: false,
  azure: {
    services: {
      retryCount: 2,
      timeOut: 120000,
      url: 'https://services.cci-dev.aa.com',
      readAndAckMessageRefreshIntervalMs: 30000,
      unAckRefreshIntervalMs: 30000,
    },
    insights: {
      primaryInstrumentationKey: 'f6951146-ba5b-4ae8-a2bf-884c985bee03',
      secondaryInstrumentationKey: '',
    },
  },
  pingFederate: {
    clientId: 'CrewChkIn-141609-test-cvDGl', // CrewChkIn-141609-test-BLLyz // Crew_Check-In_Azure ( no refresh token ) --> was supposed to be renamed to crewcheckin-test // old -> cciweb_test
    logoutUrl: 'https://cci-dev.aa.com',
    url: 'https://idptest.aa.com',
  },
  firebase: {
    config: {
      apiKey: 'AIzaSyBKHKhw-MulO1ya58eVOWP-ax6KmGqAvh8',
      authDomain: 'mobilecci-qa.firebaseapp.com',
      projectId: 'mobilecci-qa',
      storageBucket: 'mobilecci-qa.appspot.com',
      messagingSenderId: '686966760276',
      appId: '1:686966760276:web:45ce0961f4f654542f1ec1',
      measurementId: 'G-08VEPHTP0K',
    },
    vapidKey:
      'BCfXyq0DV_aaBUDsCeCXme-psCTd0TRTJsdNtcvU8xgG_svkzGZvdaAB0-3YByFfhkgqJA5G-K15gt10XcrbI7A',
  },
  seatMapUrl: 'https://obs.aa.com/Onboardservices/obs/seatingChartMap',
  aircraftUrl: 'https://techopshub.me.aa.com/flight/',
};

import 'zone.js/plugins/zone-error'; // easier debugging
