import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { FlightLeg } from '../../../../shared/services/azure/calendar.service.types';
import { airlineCodeStyle } from '../../../../shared/utils/flightLeg.utils';
@Component({
  selector: 'crew-web-card-text-highlight',
  templateUrl: './card-text-highlight.component.html',
  styleUrls: ['./card-text-highlight.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class CardTextHighlightComponent {
  @Input() flightInfo = {} as FlightLeg;

  showFlightNumber(flightInfo: FlightLeg): string {
    let response = `${flightInfo.airlineCode} ${flightInfo.flightNumber}`;
    if (flightInfo.deadHeadAirlineCode || flightInfo.cancelled) {
      response = flightInfo.deadHeadAirlineCode
        ? `${flightInfo.deadHeadAirlineCode} ${flightInfo.flightNumber}`
        : response;
    }
    return response;
  }

  showAirlineCodeStyle(flightInfo: FlightLeg, type: string) {
    return airlineCodeStyle(flightInfo, type);
  }
}
