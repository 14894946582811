import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

export type Selected = {
  event: Event;
  selectedValue: string | number;
  selected: boolean;
};

export enum SizeTypes {
  Small = 'small',
  Default = 'default',
  Large = 'large',
}

@Component({
  selector: 'crew-ui-switch',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './crew-ui-switch.component.html',
  styleUrls: ['./crew-ui-switch.component.css'],
})
export class CrewUISwitchComponent {
  @Output() selected = new EventEmitter<Selected>();

  @Input()
  id = null;

  @Input()
  value = null;

  @Input()
  name = null;

  @Input()
  checked = false;

  @Input()
  disabled = false;

  @Input()
  size = SizeTypes.Default;

  sizes = SizeTypes;

  checkboxDivStyle(): string {
    switch (this.size) {
      case SizeTypes.Small:
        return 'w-9 h-5 after:top-[2px] after:left-[2px] after:h-4 after:w-4';
      case SizeTypes.Default:
        return 'w-11 h-6 after:top-[2px] after:left-[2px] after:h-5 after:w-5';
      case SizeTypes.Large:
        return 'w-14 h-7 after:top-0.5 after:left-[4px] after:h-6 after:w-6';
    }
  }

  onSelect(event: Event, value: string | null): void {
    this.checked = !this.checked;
    this.selected.emit({
      event: event,
      selectedValue: this.checked && value ? value : '',
      selected: this.checked,
    });
  }
}
