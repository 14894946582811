<div class="ml-px bg-white pt-4 dark:bg-charcoal">
  <div class="flex items-center justify-start">
    <crew-web-settings-button class="ml-4" />
    <div
      class="flex w-full flex-col items-end justify-between px-3 xs:visible gt-xs:hidden">
      <ng-container *ngTemplateOutlet="messageRefreshTime"></ng-container>
    </div>
  </div>
  <div
    class="flex w-full flex-col items-start justify-between px-3 xs:hidden sm:pt-2 gt-xs:visible"
    ><ng-container *ngTemplateOutlet="messageRefreshTime"></ng-container></div
  >
  <div class="ml-auto flex justify-between border-b pb-2 dark:border-steel">
    <h1 class="ml-3 mt-4 pr-4 text-[32px] font-bold leading-10 dark:text-white">
      Messages
    </h1>
    <button
      title="Refresh schedule"
      type="button"
      (click)="refreshMessage()"
      class="ml-3 mt-4 pr-4 pt-1">
      <crew-ui-icon
        name="refresh"
        [color]="
          isDark ? colors.Troposphere : colors.Stratosphere
        "></crew-ui-icon>
    </button>
  </div>
  <!-- TODO Remove everything related to HI6 when it's no longer needed -->
  <ol>
    <crew-web-message-menu-list-item
      [priority]="messagePriority.HI6"
      [title]="messageTitles.HI6"
      [color]="colors.Monarch"
      [messageCount]="hi6Count"
      icon="assignment_late" />
  </ol>
  <div class="h-full overflow-auto pb-4">
    <!-- TODO See above -->
    <h5
      class="border-y bg-stratus px-4 py-3 font-medium dark:border-steel dark:bg-carbon dark:text-white">
      CCI messages
    </h5>
    <ol>
      <crew-web-message-menu-list-item
        *ngIf="businessUnit === businessUnits.AAPI"
        [priority]="messagePriority.ReadAndAcknowledge"
        [title]="messageTitles.ReadAndAcknowledge"
        [color]="colors.Afterburner"
        [messageCount]="messageCount(messagePriority.ReadAndAcknowledge)"
        icon="error" />
      <crew-web-message-menu-list-item
        *ngIf="businessUnit !== businessUnits.AAPI"
        [priority]="messagePriority.High"
        [title]="messageTitles.High"
        [color]="colors.Afterburner"
        [messageCount]="messageCount(messagePriority.High)"
        icon="error" />
      <crew-web-message-menu-list-item
        [priority]="messagePriority.Medium"
        [title]="messageTitles.Medium"
        [color]="colors.Nectarine"
        [messageCount]="messageCount(messagePriority.Medium)"
        icon="warning" />
      <crew-web-message-menu-list-item
        [priority]="messagePriority.Low"
        [title]="messageTitles.Low"
        [color]="colors.Stratosphere"
        [messageCount]="messageCount(messagePriority.Low)"
        icon="info" />
      <crew-web-message-menu-list-item
        [priority]="messagePriority.Saved"
        [title]="messageTitles.Saved"
        [color]="colors.Stratosphere"
        [messageCount]="messageCount(messagePriority.Saved)"
        icon="file_save" />
      <crew-web-message-menu-list-item
        [priority]="messagePriority.Deleted"
        [title]="messageTitles.Deleted"
        [color]="colors.Stratosphere"
        [messageCount]="messageCount(messagePriority.Deleted)"
        icon="delete" />
    </ol>
    <div *ngIf="businessUnit === businessUnits.AAPI">
      <h5
        class="border-y bg-stratus px-4 py-3 font-medium dark:border-steel dark:bg-carbon dark:text-white">
        HISEND messages
      </h5>
      <ol>
        <crew-web-message-menu-list-item
          [color]="colors.Stratosphere"
          [isModal]="true"
          icon="edit_square"
          modalTitle="Crew Scheduling" />
        <crew-web-message-menu-list-item
          [color]="colors.Stratosphere"
          [isModal]="true"
          icon="edit_square"
          modalTitle="Crew Planning" />
        <crew-web-message-menu-list-item
          [color]="colors.Stratosphere"
          [isModal]="true"
          icon="edit_square"
          modalTitle="Crew Manning" />
      </ol>
    </div>
  </div>
</div>
<!-- <div class="mt-4 flex justify-center">
  <span class="text-center font-normal text-nickel dark:text-white">
    {{ lastRefreshTime }}
  </span>
</div> -->
<ng-template #messageRefreshTime>
  <span
    class="text-[13px] font-normal leading-[18px] text-nickel dark:text-white">
    {{ lastRefreshTime }}
  </span>
</ng-template>
