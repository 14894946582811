export enum Colors {
  Afterburner = 'afterburner',
  Aluminum = 'aluminum',
  Black = 'black',
  Calathea = 'calathea',
  Carbon = 'carbon',
  Charcoal = 'charcoal',
  Cirrus = 'cirrus',
  Mesosphere = 'mesosphere',
  Midnight = 'midnight',
  Monarch = 'monarch',
  Nectarine = 'nectarine',
  Nickel = 'nickel',
  Ozone = 'ozone',
  Steel = 'steel',
  Stratosphere = 'stratosphere',
  Stratus = 'stratus',
  Sushi = 'sushi',
  Tangerine = 'tangerine',
  Thermosphere = 'thermosphere',
  Troposphere = 'troposphere',
  Turbine = 'turbine',
  White = 'white',
  Zanzibar = 'zanzibar',
}
