<div class="fixed inset-0 z-10 overflow-y-auto">
  <div
    class="flex min-h-screen items-center justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:h-screen sm:align-middle"></span>
    <div
      class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
      <div class="sm:flex sm:items-start">
        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Load next Hi6 Message?
          </h3>
          <div class="mt-2">
            <p class="text-sm leading-5 text-gray-500">
              Loading the next Hi6 message will delete the current message.
            </p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button
            type="button"
            (click)="onClickYes($event)"
            class="focus:shadow-outline-green inline-flex w-full justify-center rounded-md border border-transparent bg-stratosphere px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out focus:outline-none dark:bg-troposphere sm:text-sm sm:leading-5">
            Yes
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            type="button"
            class="focus:shadow-outline-blue inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5">
            No
          </button>
        </span>
      </div>
    </div>
  </div>
</div>
