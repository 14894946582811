<div
  class="h-full overflow-y-scroll bg-cirrus p-8 dark:bg-charcoal dark:text-white">
  <h1 class="flex items-center justify-center text-3xl font-bold">
    Installation Guide
  </h1>
  <div class="my-4">
    <crew-ui-divider [kind]="'secondary'" />
  </div>
  <div class="pl-2">
    <!-- ! IOS -->
    <h1
      (click)="copy('ios')"
      id="ios"
      class="cursor-pointer text-2xl font-bold hover:underline">
      IOS (Safari)
    </h1>
    <ol class="install-guide-list pl-2">
      <li class="my-2 list-decimal">This is only supported in Safari.</li>
      <li class="my-2 list-decimal">
        You must install the PWA to enable push notifications.
      </li>
      <li class="my-2 list-decimal">Tap the share button:</li>
      <img class="h-128 w-64" src="assets/images/ios/install-guide-0.jpeg" />
      <li class="my-2 list-decimal">Tap Add to Home Screen:</li>
      <img class="h-128 w-64" src="assets/images/ios/install-guide-1.jpeg" />
      <li class="my-2 list-decimal">
        Optionally label your application before tapping Add:
      </li>
      <img class="h-128 w-64" src="assets/images/ios/install-guide-2.jpeg" />
      <li class="my-2 list-decimal">
        You will see the application listed in your apps:
      </li>
      <img class="h-128 w-64" src="assets/images/ios/install-guide-3.jpeg" />
      <li class="my-2 list-decimal">
        After launching the application you will be presented with a native
        window:
      </li>
      <img class="h-128 w-64" src="assets/images/ios/install-guide-4.jpeg" />
      <li class="my-2 list-decimal">
        Toggle Notifications to receive notification and Dark mode per your
        preference:
      </li>
      <img class="h-128 w-64" src="assets/images/ios/install-guide-5.jpeg" />
      <li class="my-2 list-decimal">
        To remove app touch and hold app icon till menu appears, then press
        Delete Bookmark:
      </li>
      <img class="h-128 w-64" src="assets/images/ios/install-guide-6.jpeg" />
    </ol>
  </div>
  <!-- ! IOS ABOVE -->
  <div class="my-4">
    <crew-ui-divider [kind]="'secondary'" />
  </div>
  <!-- ! ANDROID BELOW -->
  <h1
    (click)="copy('android')"
    id="android"
    class="cursor-pointer text-2xl font-bold hover:underline">
    Android (Chrome)
  </h1>
  <ol class="install-guide-list pl-2">
    <li class="my-2 list-decimal">Expand the settings menu:</li>
    <img class="max-w-xs" src="assets/images/android/install-guide-0.jpeg" />
    <li class="my-2 list-decimal">Tap Add to Home screen:</li>
    <img class="max-w-xs" src="assets/images/android/install-guide-1.jpeg" />
    <li class="my-2 list-decimal">Tap the Install button:</li>
    <img class="max-w-xs" src="assets/images/android/install-guide-2.jpeg" />
    <li class="my-2 list-decimal">Tap Install:</li>
    <img class="max-w-xs" src="assets/images/android/install-guide-3.jpeg" />
    <li class="my-2 list-decimal">Installing indicator:</li>
    <img class="max-w-xs" src="assets/images/android/install-guide-4.jpeg" />
    <li class="my-2 list-decimal">
      You will receive a notification when the App has installed:
    </li>
    <img class="max-w-xs" src="assets/images/android/install-guide-5.jpeg" />
    <li class="my-2 list-decimal">
      You will see the CCI listed in your apps:
    </li>
    <img class="max-w-xs" src="assets/images/android/install-guide-6.jpeg" />
    <li class="my-2 list-decimal">
      After launching the application you will be presented with a native
      window:
    </li>
    <img class="max-w-xs" src="assets/images/android/install-guide-7.jpeg" />
    <li class="my-2 list-decimal">
      Toggle Notifications to receive notification and Dark mode per your
      preference:
    </li>
    <img class="max-w-xs" src="assets/images/android/install-guide-8.jpeg" />
  </ol>
</div>
