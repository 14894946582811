<div
  class="h-full overflow-y-auto bg-white dark:bg-charcoal"
  [class.fullscreen]="isFullscreen">
  <div class="flex items-center justify-start py-4">
    <crew-ui-icon
      id="back-button"
      title="Back"
      *ngIf="isXs"
      (click)="goBack($event)"
      class="ml-4 cursor-pointer"
      name="chevron_left"
      [color]="isDark ? colors.Cirrus : colors.Black"
      [size]="'3xl'" />
    <crew-ui-icon
      id="fullscreen-button"
      [title]="isFullscreen ? 'Close' : 'Open'"
      *ngIf="isGtSm"
      (click)="toggleFullscreen($event)"
      class="ml-4 cursor-pointer"
      [name]="isFullscreen ? 'close_fullscreen' : 'open_in_full'"
      [color]="isDark ? colors.Troposphere : colors.Stratosphere"
      [size]="'3xl'" />
    <crew-ui-icon
      id="next-button"
      title="Next"
      (click)="nextMessage($event)"
      class="ml-4 cursor-pointer"
      name="expand_more"
      [color]="nextMessageIconColor"
      [size]="'3xl'" />
    <crew-ui-icon
      id="previous-button"
      title="Previous"
      (click)="previousMessage($event)"
      class="ml-4 mr-auto cursor-pointer"
      name="expand_less"
      [color]="previousMessageIconColor"
      [size]="'3xl'" />
    <crew-ui-icon
      id="delete-button"
      *ngIf="showDeleteButton()"
      title="Delete"
      (click)="deleteMessage($event)"
      [color]="isDark ? colors.Troposphere : colors.Stratosphere"
      class="cursor-pointer"
      name="delete"
      [size]="'3xl'" />
    <crew-ui-icon
      id="restore-button"
      title="Restore"
      *ngIf="message?.messageDeleted"
      (click)="restoreDeletedMessage($event)"
      [color]="isDark ? colors.Troposphere : colors.Stratosphere"
      class="cursor-pointer"
      name="restore_from_trash"
      [size]="'3xl'" />
    <crew-ui-icon
      id="save-button"
      [title]="isSavedMessage() ? 'Save off' : 'Save'"
      *ngIf="!message?.messageDeleted"
      (click)="toggleSaveMessage($event)"
      [name]="isSavedMessage() ? 'file_save_off' : 'file_save'"
      [color]="isDark ? colors.Troposphere : colors.Stratosphere"
      class="mx-2 cursor-pointer"
      [size]="'3xl'" />
    <crew-ui-icon
      *ngIf="isMoreIconVisible()"
      (click)="toggleDropdownMenu($event)"
      title="More"
      id="more-button"
      class="mr-4 cursor-pointer"
      name="more_vert"
      [color]="isDark ? colors.Troposphere : colors.Stratosphere"
      [size]="'3xl'" />
  </div>
  <h1
    *ngIf="showTitle(message)"
    class="ml-4 border-cirrus py-4 pr-4 text-[32px] font-bold leading-10 dark:border-charcoal dark:text-white">
    {{ showTitle(message) }}
  </h1>
  <crew-ui-divider />
  <ng-container
    *ngIf="selectedMenu !== messagePriority.HI6 && !message?.isHi6Message">
    <div
      class="flex items-center justify-between border-cirrus px-4 py-2 dark:border-charcoal">
      <p class="text-base font-normal dark:text-white">
        Signed By: {{ message?.messageSignature }}
      </p>
      <p class="text-base font-normal text-nickel">
        {{ showDateTime(message?.effectiveDate) }}
      </p>
    </div>
    <crew-ui-divider />
  </ng-container>
  <div class="overflow-x-auto">
    <div
      class="mb-4 p-4"
      *ngIf="message?.body || hi6Message; else zero_message">
      <article
        *ngIf="
          message &&
          message.body &&
          selectedMenu !== messagePriority.HI6 &&
          !message?.isHi6Message
        "
        [ngClass]="{ 'message-body-dark-theme': isDark }"
        class="message-body"
        [style]="buildFontSize()"
        [innerHTML]="message.body | safeHtml">
      </article>
      <div *ngIf="hi6Message">
        <crew-web-safe-html
          *ngIf="
            hi6Message &&
            (selectedMenu === messagePriority.HI6 || message?.isHi6Message)
          "
          [content]="hi6Message"></crew-web-safe-html>
      </div>
    </div>
    <ng-template #zero_message
      ><div class="mb-4 p-4 dark:text-white">
        {{
          selectedMenu === messagePriority.HI6 || message?.isHi6Message
            ? 'No messages found'
            : 'Loading message body...'
        }}
      </div></ng-template
    >
  </div>
</div>

<crew-ui-overlay
  id="more-button-overlay"
  *ngIf="isDropdownVisible"
  (click)="toggleDropdownMenu($event)">
  <div
    class="text-md absolute right-6 z-50 w-60 rounded-lg bg-white shadow-lg dark:bg-steel lt-sm:fixed lt-sm:bottom-0 lt-sm:left-0 lt-sm:right-0 lt-sm:my-2 lt-sm:w-full lt-sm:p-2 gt-xs:top-12">
    <div class="flex cursor-pointer items-center justify-start pb-4 pl-4 pt-6">
      <crew-ui-icon
        class="mr-2 dark:text-white"
        name="download"
        [color]="isDark ? colors.Troposphere : colors.Stratosphere" />
      <span
        (click)="toggleForwardByEmailModal($event)"
        class="cursor-pointer align-middle dark:text-white">
        Forward via email
      </span>
    </div>
    <div class="px-4">
      <crew-ui-divider kind="tertiary" />
    </div>
    <div
      class="flex items-center justify-between px-12 pb-4 pt-2 text-2xl text-stratosphere dark:text-troposphere">
      <span
        (click)="decreaseFontSize($event)"
        [class.cursor-default]="decreaseFontSizeDisabled"
        [class.cursor-pointer]="!decreaseFontSizeDisabled"
        [class.text-charcoal]="decreaseFontSizeDisabled"
        class="cursor-pointer">
        -aA
      </span>
      <span
        (click)="increaseFontSize($event)"
        [class.cursor-default]="increaseFontSizeDisabled"
        [class.cursor-pointer]="!increaseFontSizeDisabled"
        [class.text-charcoal]="increaseFontSizeDisabled"
        class="cursor-pointer">
        +aA
      </span>
    </div>
  </div>
</crew-ui-overlay>

<crew-ui-overlay
  *ngIf="isForwardByEmailModalVisible"
  (click)="toggleForwardByEmailModal($event)">
  <crew-ui-card
    class="h-full w-full gt-sm:h-2/3 gt-sm:w-2/3"
    (click)="$event.stopPropagation()">
    <div class="h-full w-full rounded-2xl dark:bg-carbon dark:text-white">
      <div
        class="flex items-center justify-between rounded-t-2xl px-4 py-2 dark:bg-steel gt-lg:px-6 gt-lg:py-4">
        <span
          (click)="toggleForwardByEmailModal($event)"
          class="cursor-pointer text-stratosphere dark:text-troposphere">
          Cancel
        </span>
        <span
          (click)="forwardByEmail($event)"
          [class.cursor-pointer]="isSendable"
          [class.text-nickel]="!isSendable"
          [class.text-stratosphere]="isSendable"
          [class.dark:text-troposphere]="isSendable">
          Send
        </span>
      </div>
      <div class="px-4 gt-lg:px-6">
        <p class="my-3 text-2xl font-bold">Forward Message</p>
        <div class="flex flex-col">
          <form
            [formGroup]="forwardByEmailForm"
            class="h-[75vh] w-full overflow-y-auto">
            <label>
              <span class="mr-2 text-nickel">To:</span>
              <input
                (click)="$event.stopPropagation()"
                formControlName="to"
                placeholder="email@example.com;"
                class="w-full bg-transparent focus:!outline-none" />
            </label>
            <hr class="my-2" />

            <label>
              <span class="mr-2 text-nickel">CC:</span>
              <input
                (click)="$event.stopPropagation()"
                formControlName="cc"
                placeholder="email@example.com;"
                class="w-full bg-transparent focus:!outline-none" />
            </label>
            <hr class="my-2" />

            <label>
              <span class="mr-2 text-nickel">Subject:</span>
              <input
                (click)="$event.stopPropagation()"
                formControlName="subject"
                class="w-full bg-transparent focus:!outline-none" />
            </label>
            <hr class="my-2" />

            <textarea
              (click)="$event.stopPropagation()"
              formControlName="body"
              class="mt-2 w-full whitespace-normal border-none bg-transparent dark:text-white"
              rows="3"></textarea>
            <hr class="my-2" />

            <p
              class="message-body mt-2"
              [style]="buildFontSize()"
              [innerHTML]="message?.body">
            </p>
          </form>
        </div>
      </div>
    </div>
  </crew-ui-card>
</crew-ui-overlay>

<crew-ui-overlay *ngIf="showHi6NextButton" (click)="nextMessage($event)">
  <crew-web-dialog-confirm-modal (confirmYes)="getNextHi6Message($event)" />
</crew-ui-overlay>
