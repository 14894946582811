<ng-container *ngIf="!isEmailResponseVisible">
  <crew-ui-modal>
    <div
      class="pointer-events-none fixed start-0 top-0 z-[80] size-full overflow-y-auto overflow-x-hidden">
      <div
        class="m-3 h-[calc(100%-4.5rem)] sm:mx-auto sm:w-full sm:max-w-2xl gt-sm:mx-auto gt-sm:max-w-4xl">
        <div
          class="pointer-events-auto flex max-h-full flex-col overflow-hidden rounded-xl bg-white text-carbon shadow-sm dark:bg-charcoal dark:text-white">
          <form
            class="form overflow-y-auto"
            [formGroup]="forwardMessageForm"
            (ngSubmit)="onClickSend($event)">
            <div
              class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b border-turbine p-4 dark:border-charcoal">
              <!-- Modal title -->
              <button
                type="button"
                (click)="onClickCancel()"
                class="text-md text-stratosphere dark:text-troposphere">
                Cancel
              </button>
              <button
                type="submit"
                [disabled]="!forwardMessageForm.valid && !isSubmit"
                class="text-md ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-stratosphere disabled:text-nickel dark:text-troposphere dark:disabled:text-steel">
                Send
              </button>
            </div>
            <div class="relative overflow-y-auto p-4">
              <div class="py-2">
                <div class="flex flex-row xs:flex-col">
                  <label for="title" class="text-lx flex items-center"
                    >To</label
                  >
                  <input
                    formControlName="to"
                    type="text"
                    class="text-md w-full rounded-md border px-2 py-2 outline-none dark:bg-charcoal dark:text-white gt-xs:ml-2" /> </div
              ></div>
              <div class="py-2">
                <div class="flex flex-row xs:flex-col">
                  <label for="title" class="text-lx flex items-center"
                    >CC</label
                  >
                  <input
                    formControlName="cc"
                    type="text"
                    class="text-md w-full rounded-md border px-2 py-2 outline-none dark:bg-charcoal dark:text-white gt-xs:ml-2" /> </div
              ></div>
              <div class="py-2">
                <div class="flex flex-row xs:flex-col">
                  <label for="title" class="text-lx flex items-center"
                    >Subject</label
                  >
                  <input
                    formControlName="subject"
                    type="text"
                    class="text-md w-full rounded-md border px-2 py-2 outline-none dark:bg-charcoal dark:text-white gt-xs:ml-2" /> </div
              ></div>
              <div class="py-2">
                <div class="flex flex-row xs:flex-col">
                  <textarea
                    formControlName="comments"
                    rows="4"
                    class="text-md block w-full rounded-md border px-2 py-2 outline-none dark:bg-charcoal dark:text-white"
                    placeholder="Enter any comments here (optional)"></textarea></div
              ></div>
              <div>
                <div class="flex flex-row xs:flex-col">
                  <label for="title" class="text-lx flex items-center"
                    >Title</label
                  >
                  <label class="w-full py-1 gt-xs:ml-2">{{
                    sabreResponse?.keywordDisplay
                  }}</label>
                </div>
                <div class="flex flex-row xs:flex-col">
                  <label for="title" class="text-lx flex items-center"
                    >Date</label
                  >
                  <label class="w-full py-1 gt-xs:ml-2">{{
                    getMessageTime()
                  }}</label>
                </div></div
              >
              <div
                *ngIf="sabreResponse?.returnData"
                class="min-h-40 overflow-x-auto rounded-md border border-turbine p-4 dark:border-steel">
                <crew-web-safe-html
                  *ngIf="sabreResponse?.returnData"
                  [content]="sabreResponse?.returnData"></crew-web-safe-html>
              </div>
              <div
                class="min-h-40 overflow-x-auto p-4"
                *ngIf="sabreResponse?.pdfOutput">
                <crew-web-pdf-viewer
                  [fileName]="getEFlightPlanPdfFileName()"
                  [pdfString]="sabreResponse?.pdfOutput"></crew-web-pdf-viewer>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </crew-ui-modal>
</ng-container>
<ng-container *ngIf="isEmailResponseVisible">
  <crew-web-alert-modal
    *ngIf="tripSignInAlert && tripSignInAlert?.message !== ''"
    [alertMessage]="tripSignInAlert"
    (clickClose)="onClickClose()"></crew-web-alert-modal>
</ng-container>
