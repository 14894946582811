<crew-ui-overlay
  *ngIf="alertMessage && alertMessage.message !== ''"
  (click)="closeAlert($event)">
  <div
    class="left-0 right-0 top-0 z-50 m-auto max-h-full w-full overflow-y-auto overflow-x-hidden p-4 md:inset-0">
    <div class="relative m-auto max-h-full w-full max-w-md">
      <!-- Modal content -->
      <div
        class="relative rounded-lg bg-white text-carbon shadow dark:bg-charcoal dark:text-white">
        <!-- Modal body -->
        <div class="space-y-4 p-4 md:p-5">
          <p
            *ngIf="alertMessage.type"
            class="flex flex-col items-center justify-center text-[20px] font-medium leading-[25px]">
            {{ alertMessage.type }}
          </p>
          <p class="text-base leading-relaxed">
            {{ alertMessage.message }}
          </p>
          <div class="flex flex-col justify-center">
            <button
              type="button"
              class="rounded-lg bg-stratosphere px-5 py-2.5 text-center text-sm font-medium text-white dark:bg-troposphere"
              >Close</button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</crew-ui-overlay>
