import { computed, Injectable, signal } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private deviceService: DeviceDetectorService) {}

  locationSignal = signal<GeolocationPosition>({
    coords: {
      latitude: 0,
      longitude: 0,
      altitude: null,
      accuracy: 0,
      altitudeAccuracy: null,
      heading: null,
      speed: null,
    },
    timestamp: 0,
  });

  isDesktopDevice = computed(() => this.deviceService.isDesktop());

  checkDistance(coords: { latitude?: number; longitude?: number }) {
    return `${this.haversineDistance(coords).toFixed(2)} km`;
  }

  loadLocation(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.watchPosition(
          (position: GeolocationPosition) => {
            this.locationSignal.set(position);
            resolve();
          },
          (error: GeolocationPositionError) => {
            console.error('Error getting location:', error.message);
            reject('Error getting location');
          },
          {
            timeout: 30000,
            maximumAge: 300000,
          },
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        reject('Error getting location');
      }
    });
  }

  getDeviceInfo() {
    return this.deviceService.getDeviceInfo();
  }

  private haversineDistance(coords: {
    latitude?: number;
    longitude?: number;
  }): number {
    if (!coords.latitude || !coords.longitude) return 0;

    const R = 6371; // Radius of the Earth in kilometers
    const dLat = this.toRadians(
      this.locationSignal().coords.latitude - coords.latitude,
    );
    const dLon = this.toRadians(
      this.locationSignal().coords.longitude - coords.longitude,
    );

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.toRadians(coords.latitude)) *
        Math.cos(this.toRadians(this.locationSignal().coords.latitude)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Distance in kilometers
    return distance;
  }

  private toRadians(degrees: number) {
    return (degrees * Math.PI) / 180;
  }
}
