<div class="px-2 pb-2">
  <ol class="relative lt-sm:h-80">
    <li
      [ngClass]="[
        sequenceInfo.daysEvent.beginsToday ||
        showStandByStartDate(sequenceInfo, day)
          ? timelineStyle(sequenceInfo.daysEvent.start, 'border')
          : 'mt-3 border-solid border-cirrus dark:border-black'
      ]"
      class="border-s">
      <div class="ms-2">
        <ng-container
          *ngIf="
            sequenceInfo.daysEvent.beginsToday ||
            showStandByStartDate(sequenceInfo, day)
          ">
          <div
            [ngClass]="[timelineStyle(sequenceInfo.daysEvent.start, 'bg')]"
            class="absolute -start-[0.225rem] mt-2 h-2 w-2 rounded-full border"></div>
          <time
            class="mb-1 text-[12px] font-medium leading-[16px] text-carbon dark:text-white"
            >{{ getTime(sequenceInfo.daysEvent.start) }}</time
          >
        </ng-container>
        <div class="py-2">
          <section class="w-full justify-between">
            <div
              [ngClass]="[
                sequenceInfo.color.bgColor,
                sequenceInfo.color.textColor,
                sequenceInfo.color.borderColor
              ]"
              class="flex items-center rounded-tr-xl border-l-4 p-2">
              <ng-container
                *ngIf="
                  sequenceInfo.daysEvent.sequenceActivity;
                  else standByRowOne
                ">
                <span class="text-[15px] font-medium leading-[20px]">{{
                  'SEQ ' +
                    sequenceInfo.daysEvent.sequenceActivity.sequenceNumber
                }}</span>
                <div class="ml-auto flex justify-between pr-2">
                  <p class="items-center text-[15px] font-normal leading-[20px]"
                    >{{ getSequenceDays(sequenceInfo, day) }}
                  </p>
                </div>
              </ng-container>
              <ng-template #standByRowOne>
                <span class="text-[15px] font-medium leading-[20px]">{{
                  'SEQ ' + sequenceInfo.daysEvent.title
                }}</span>
                <div class="ml-auto flex justify-between pr-2">
                  <p class="items-center text-[15px] font-normal leading-[20px]"
                    >{{ showStandByDate(sequenceInfo, day) }}
                  </p>
                </div>
              </ng-template>
            </div>
            <div
              [ngClass]="[sequenceInfo.color.borderColor]"
              class="flex items-center rounded-bl rounded-br-xl border-l-4 bg-white p-2 text-carbon dark:bg-carbon dark:text-white">
              <ng-container
                *ngIf="
                  sequenceInfo.daysEvent.sequenceActivity;
                  else standByRowTwo
                ">
                <span class="flex items-center"
                  ><crew-ui-icon name="description"></crew-ui-icon>
                  <span class="pl-1 text-[15px] font-normal leading-[20px]"
                    >HSS</span
                  ></span
                >
                <div class="ml-auto flex justify-between pr-2">
                  <button
                    (click)="
                      callSabreCommand(
                        sabreKeywordDisplay.HSS,
                        sequenceInfo,
                        day
                      )
                    "
                    class="items-center text-[15px] font-normal leading-[20px] text-stratosphere dark:text-troposphere"
                    >View</button
                  >
                </div>
              </ng-container>
              <ng-template #standByRowTwo>
                <span class="flex items-center">
                  <span class="pl-1 text-[15px] font-normal leading-[20px]">{{
                    'Gate: ' + sequenceInfo.daysEvent.standbyGate
                  }}</span></span
                >
                <div class="ml-auto flex justify-between pr-2">
                  <span class="pl-1 text-[15px] font-normal leading-[20px]">{{
                    showStandByTime(sequenceInfo)
                  }}</span>
                </div>
              </ng-template>
            </div>
          </section>
        </div>
      </div>
    </li>
    <ng-container
      *ngIf="
        sequenceInfo.daysEvent.sequenceActivity?.flightDutyPeriods &&
        getFlightDutyPeriod(
          sequenceInfo?.daysEvent?.sequenceActivity?.flightDutyPeriods,
          sequenceInfo.daysEvent.beginsToday
        ).length > 0
      ">
      <ng-container
        *ngFor="
          let flightDutyPeriod of getFlightDutyPeriod(
            sequenceInfo?.daysEvent?.sequenceActivity?.flightDutyPeriods,
            sequenceInfo.daysEvent.beginsToday
          );
          let last = last
        ">
        <li
          *ngFor="let event of getFlightLegs(flightDutyPeriod); let last = last"
          [ngClass]="[flightLegTimeLineStyle(event, 'border')]"
          class="border-s">
          <div class="ms-2">
            <div
              [ngClass]="[flightLegTimeLineStyle(event, 'bg')]"
              class="absolute -start-[0.225rem] mt-2 h-2 w-2 rounded-full border"></div>
            <time
              class="mb-1 text-[12px] font-medium leading-[16px] text-carbon dark:text-white"
              >{{ getFlightTime(event) }}</time
            >
            <div
              class="pt-2"
              [class]="
                last && !flightDutyPeriod.layOverAirport ? 'pb-0' : 'pb-2'
              ">
              <crew-web-card-flight-info
                [eventColor]="sequenceInfo.color"
                [flightInfo]="event" />
            </div>
          </div>
        </li>
        <li
          *ngIf="
            flightDutyPeriod.flightLegs.length === 0 &&
            flightDutyPeriod.layOverAirport &&
            flightDutyPeriod.layoverInMinutes
          ">
          <div class="pb-2 pl-2 pt-2">
            <section class="w-full justify-between">
              <div
                [ngClass]="[sequenceInfo.color.borderColor]"
                class="flex items-center rounded-l rounded-r-xl border-l-4 bg-white px-2 py-3 text-carbon dark:bg-carbon dark:text-white">
                <div
                  class="flex items-center text-[16px] font-medium leading-[21px] text-carbon dark:text-white"
                  >{{ 'Layover in ' + flightDutyPeriod.layOverAirport }}</div
                >
                <div class="ml-auto flex justify-between pr-2">
                  <p
                    class="flex items-center text-[13px] font-normal leading-[18px]"
                    >{{ flightDutyPeriod.layoverInMinutes | flightTime }}</p
                  >
                </div>
              </div>
            </section>
          </div>
        </li>
        <li
          *ngIf="
            flightDutyPeriod.flightLegs.length > 0 &&
            flightDutyPeriod.layOverAirport &&
            flightDutyPeriod.layoverInMinutes
          "
          [ngClass]="[
            layoverTimeLine(
              sequenceInfo.daysEvent.sequenceActivity?.flightDutyPeriods,
              sequenceInfo.daysEvent.beginsToday,
              'border'
            )
          ]"
          class="border-s">
          <div class="ms-2">
            <div
              [ngClass]="[
                layoverTimeLine(
                  sequenceInfo.daysEvent.sequenceActivity?.flightDutyPeriods,
                  sequenceInfo.daysEvent.beginsToday,
                  'bg'
                )
              ]"
              class="absolute -start-[0.225rem] mt-2 h-2 w-2 rounded-full border"></div>
            <time
              class="mb-1 text-[12px] font-medium leading-[16px] text-carbon dark:text-white"
              >{{
                getLayoverArrivalTime(
                  sequenceInfo.daysEvent.sequenceActivity?.flightDutyPeriods,
                  sequenceInfo.daysEvent.beginsToday,
                  day
                )
              }}</time
            >
            <div [ngClass]="{ 'pb-2': !last }" class="pt-2">
              <section class="w-full justify-between">
                <div
                  [ngClass]="[sequenceInfo.color.borderColor]"
                  class="flex items-center rounded-l rounded-r-xl border-l-4 bg-white px-2 py-3 text-carbon dark:bg-carbon dark:text-white">
                  <button
                    type="button"
                    (click)="
                      callSabreCommand(
                        sabreKeywordDisplay.CREWHOTELLIMOINFO,
                        sequenceInfo,
                        day
                      )
                    "
                    class="flex cursor-pointer items-center text-[16px] font-medium leading-[21px] text-stratosphere dark:text-troposphere"
                    >{{
                      'Layover in ' + flightDutyPeriod.layOverAirport
                    }}</button
                  >
                  <div class="ml-auto flex justify-between pr-2">
                    <p
                      class="flex items-center text-[13px] font-normal leading-[18px]"
                      >{{ flightDutyPeriod.layoverInMinutes | flightTime }}</p
                    >
                  </div>
                </div>
              </section>
            </div>
          </div>
        </li>
      </ng-container>
    </ng-container>
    <li
      [ngClass]="[
        isSequenceLastDay(sequenceInfo.daysEvent.end, day)
          ? timelineStyle(sequenceInfo.daysEvent.end, 'border')
          : 'mt-3 border-solid border-cirrus dark:border-black'
      ]"
      class="border-s pt-2">
      <div class="ms-2">
        <ng-container
          *ngIf="isSequenceLastDay(sequenceInfo.daysEvent.end, day)">
          <div
            [ngClass]="[timelineStyle(sequenceInfo.daysEvent.start, 'bg')]"
            class="absolute -start-[0.225rem] mt-2 h-2 w-2 rounded-full border"></div>
          <time
            class="mb-1 text-[12px] font-medium leading-[16px] text-carbon dark:text-white"
            >{{ getTime(sequenceInfo.daysEvent.end) }}</time
          >
        </ng-container>
      </div>
    </li>
  </ol>
</div>
