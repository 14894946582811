import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { AlertModalComponent } from '../../../shared/components/alert-modal/alert-modal.component';
import {
  Colors,
  CrewUICardComponent,
  CrewUIIconComponent,
} from '../../../shared/components/crew-ui';
import { SabreDocumentService } from '../../../shared/components/sabre-document/sabre-document.service';
import { Events } from '../../../shared/services/azure/schedule.service.types';
import { formatDate } from '../../../shared/utils/date.utils';
import { ReadAndAcknowledgeService } from '../read-and-acknowledge.service';

@Component({
  selector: 'crew-web-sign-in-card',
  standalone: true,
  imports: [
    CommonModule,
    CrewUICardComponent,
    CrewUIIconComponent,
    AlertModalComponent,
  ],
  templateUrl: './sign-in-card.component.html',
  styleUrls: ['./sign-in-card.component.css'],
})
export class SignInCardComponent {
  get tripSignInInfoV3Response() {
    return this.readAndAcknowledgeService.tripSignInInfoV3Response();
  }

  get showTitleInReadAckPage() {
    return this.readAndAcknowledgeService.showTitleInReadAckPage();
  }

  /**
   * Toggle trip sign alert
   */
  get tripSignInAlert() {
    return this.sabreDocumentService.tripSignInAlert();
  }

  set clearTripSignInAlertMessage(event: Event) {
    this.sabreDocumentService.clearTripSignInAlertMessage(event);
  }

  constructor(
    private readAndAcknowledgeService: ReadAndAcknowledgeService,
    private sabreDocumentService: SabreDocumentService,
  ) {}

  colors = Colors;

  onClickTripSignIn(event: Event): void {
    if (event && this.tripSignInInfoV3Response) {
      const { standBy, departureStation, flightOriginDate, sequenceNumber } =
        this.tripSignInInfoV3Response;
      const payload = {
        standBy: standBy,
        signInDepSta: departureStation,
        sequenceOriginationDate: formatDate(flightOriginDate, 'YYYY-MM-DD'),
        sequenceNumber: sequenceNumber ? Number(sequenceNumber) : 0,
      };
      this.sabreDocumentService.callCrewTripSignIn(payload, {} as Events);
    }
  }

  showReminderMessage(): string {
    return this.readAndAcknowledgeService.showReminderMessage(
      this.tripSignInInfoV3Response,
    );
  }

  onClickClose(event: Event): void {
    this.clearTripSignInAlertMessage = event;
  }
}
