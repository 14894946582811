import { Pipe, PipeTransform } from '@angular/core';

import { minutesToHoursAndMinutes } from '../utils/date.utils';
@Pipe({
  name: 'flightTime',
  standalone: true,
})
export class FlightTimePipe implements PipeTransform {
  transform(time: number, text = ''): string {
    let response = `${time}m `;
    if (time > 0) {
      response = minutesToHoursAndMinutes(time);
    }
    return text ? `${response} ${text}` : `${response}`;
  }
}
