import { BusinessUnit } from '../../types';

export type GetScheduleDetailsRequest = {
  airlineCode: string;
  appSessionId?: string;
  businessUnit: BusinessUnit | undefined;
  employeeLogin: number | undefined;
  siteMinderEmployeeId: number;
  uniqueId?: string;
};

export type BidStatuses = {
  actualPayProjection: number;
  aggressive?: string | null;
  asgSequenceClickCount: number;
  asgStandByClickCount: number;
  checkAirman: boolean;
  contractMonth: string;
  contractMonthEndDate: string;
  contractMonthStartDate: string;
  contractMonthType: string;
  currentBase: string;
  currentDivision: string;
  currentEquipment: string;
  currentPosition: string;
  etbNet?: string | null;
  faBaseGuarantee: number;
  fsmSupervisorNumber: string | null;
  greaterTimetoDate: number;
  inactiveCrewMember: boolean;
  inactiveSupervisor: boolean;
  involuntaryCount: number;
  ipMax: number;
  maxHours?: string | null;
  monthlyMax?: string | null;
  mtdBlockPay?: string | null;
  mtdBlockTime?: string | null;
  nextBase?: string | null;
  nextDivision?: string | null;
  nextEquipment?: string | null;
  nextPosition?: string | null;
  otSum?: string | null;
  payProjection: number;
  projectionActuals?: string | null;
  scheduledBlockTime?: string | null;
  scheduledPay?: string | null;
  scheduledProjection: number;
  seatCategory: string;
  selectionType: string;
  seniorityNumber: number;
  standbyCount: number;
  totalETB?: string | null;
  totalETBPNCAdj?: string | null;
  totalETBTripHours?: string | null;
  totalPNC?: string | null;
  voluntaryCount: number;
  volunteer?: string | null;
  waiverInd: boolean;
  checkAirmanCurrentMonthStatus: CheckAirmanCurrentMonthStatus;
  checkAirmanOtherMonthStatus: string | null;
};

/**
 * ! This literally has bool strings :(
 *
 * checkAirmanCurrentMonthStatus: {
        checkAirmanType: '00',
        onActive: 'false',
        onSupplemental: 'false',
        onLine: 'false',
        onRotate: 'false',
        onSchoolhouse: 'false',
        onEagleIOECapt: 'false',
  }
 */
export interface CheckAirmanCurrentMonthStatus {
  checkAirmanType: string;
  onActive: string | boolean;
  onSupplemental: string | boolean;
  onLine: string | boolean;
  onRotate: string | boolean;
  onSchoolhouse: string | boolean;
  onEagleIOECapt: string | boolean;
}

export interface Calendar {
  days: Days[];
  displayName: string;
  calendarStartDate: string;
  contractMonth: ContractMonth;
}

export interface Days {
  date: string;
  daysEvents: DaysEvent[];
  displayDate: string;
  layover?: string | null;
  totalNumberOfLegs: number;
  today: boolean;
  firstDayOfWeek: boolean;
}

/**
 * Ref.
 * GOLDEN_DAYS: 'GD',
 * STANDBY: 'SB',
 * DFP: 'D',
 * PLANNED_ABSENCE: 'A',
 * CREDITED_REMOVAL: 'C',
 * SEQUENCE: 'S',
 * RAP: 'RAP',
 * RELEASE: 'RL',
 * TRAINING_1: 'TR',
 * TRAINING_2: 'T',
 * SICK: 'SK',
 * VACATION: 'VC',
 * LEAVE: 'LV'
 */
export enum EventType {
  S = 'S', // Sequence
  D = 'D', // ? Duty Free Period
  VC = 'VC', // Vacation
  TR = 'TR', // ?
  A = 'A', // ?
  C = 'C', // ?
  RAP = 'RAP', // ?
  T = 'T', // Training but this could also be TR
  STANDBY = 'SB',
}

export enum EventCode {
  TwoR = '2R',
  TI = 'TI',
}

export interface DaysEvent {
  id: number;
  start: string;
  end: string;
  startInGMT: string;
  endInGMT: string;
  title: string;
  showEvent?: boolean;
  styles: string;
  span: number;
  beginsToday: boolean;
  sequenceActivity?: SequenceActivity | null;
  order: number;
  eventType: EventType;
  eventCode: EventCode;
  standbyGate?: string | null;
  nonCollidingOrder: number;
  numberOfLegs: number;
  lastFlightEndsToday: boolean;
  firstFlightStartTime?: string | null;
  lastFlightEndTime?: string | null;
  sequenceLegsBreakdown?: string | null;
}

export interface SequenceActivity {
  addCode?: string | null;
  airlineCode?: string | null;
  base?: string | null;
  cabinType?: string | null;
  contractMonth: string;
  creditNextMonth?: number | null;
  creditThisMonth?: number | null;
  division?: string | null;
  durationInDays?: number | null;
  employeeID?: number | null;
  equipmentGroup?: string | null;
  equipmentQuals?: string | null;
  failsContinuity?: boolean | null;
  firstLegDeadHeadIndicator?: boolean | null;
  firstLegDepartureAirport?: string | null;
  firstLegDepartureTime?: DateTimeFormat | null;
  flightDutyPeriods: FlightDutyPeriod[];
  galleyPosition?: boolean | null;
  hotelInfo?: string | null;
  international?: boolean | null;
  isCoterminal?: boolean | null;
  isIPD?: boolean | null;
  isODAN?: boolean | null;
  isRedEye?: boolean | null;
  isRedFlag?: boolean | null;
  isSatellite?: boolean | null;
  isTrainingSequence?: boolean | null;
  lastLegDeadHeadIndicator?: boolean | null;
  layoverStations?: string | null;
  legsPerDutyPeriod: string;
  numberOfLegs: number;
  multipleEquipments?: boolean | null;
  positionCode: string;
  positionSequenceInfo1?: string | null;
  positionSequenceInfo1Binary?: string | null;
  positionSequenceInfo2?: string | null;
  quals?: string | null;
  removalCode?: string | null;
  rigs?: string | null;
  ronCities?: string[] | null;
  scheduledFlight?: number | null;
  sequenceBlockTime?: number | null;
  sequenceDutyTime?: string | null;
  sequenceEndDateTime?: DateTimeFormat | null;
  sequenceNumber: number;
  sequenceOriginDate: string;
  sequencePayCredit?: SequencePayCredit | null;
  sequencePayTime?: number | null;
  sequenceStartDateTime?: DateTimeFormat | null;
  serviceQuals?: string | null;
  speakerRequirement?: SpeakerRequirement | null;
  timeAwayFromBase?: number | null;
  timeOpened?: string | null;
  totalLegCount?: number | null;
  signedIn: boolean;
  sequenceStatus?: string | null;
  signInTimeInGMT?: string | null;
  tripSignInInfo?: string | null;
  ttotStatus?: string | null;
}

export interface DateTimeFormat {
  baseTime: string;
  gmt: string;
  localTime: string;
}

export interface FlightDutyPeriod {
  baseIndicator?: string | null;
  crewMeal?: string | null;
  duration: number;
  dutyPeriodNumber: number;
  endDateTime: DateTimeFormat;
  flightLegs: FlightLeg[];
  layOverAirport?: string | null;
  layoverInMinutes: number;
  layoverStation?: string | null;
  numberOfLegs: number;
  odMinutes: number;
  payCredit: PayCredit;
  startDateTime: DateTimeFormat;
}

export interface FlightLeg {
  actual?: ArrivalDepartureInfo | null;
  airlineCode: string;
  arrivalStation: string;
  arrivalDuplicateCode: number;
  baseIndicator?: string | null;
  blockTime: number;
  crewHotel?: string | null;
  deadHead: boolean;
  deadHeadAirlineCode?: string | null;
  departureDuplicateCode: number;
  departureStation: string;
  equipmentQuals: EquipmentQuals;
  flightNumber: number;
  flightOriginationDate: string;
  groundTime: number;
  legIndex: number;
  legType: string;
  limoDetails?: string | null;
  noShowDHD: boolean;
  endOfDutyPeriod: boolean;
  cancelled: boolean;
  removed: boolean;
  endOfSequence: boolean;
  legAssignmentCode?: string | null;
  scheduled: ArrivalDepartureInfo;
  reScheduled?: ArrivalDepartureInfo | null;
  status?: string | null;
  visaCountry?: string | null;
  visaIndicator: string;
  changeInFlightTime: boolean;
  departureGate?: string | null;
  departureTerminal?: string | null;
  arrivalGate?: string | null;
  arrivalTerminal?: string | null;
  hotelInfo?: string | null;
  limoInfo?: string | null;
  fltPlanSignature?: string | null;
  flightStatus?: string | null;
  flightStatusDisplayText?: string | null;
  crewMembersWithSameLayover?: string | null;
  infoIndicators?: string | null;
}

export interface ArrivalDepartureInfo {
  arrivalAirport?: string | null;
  arrivalDateTime: DateTimeFormat;
  departureAirport?: string | null;
  departureDateTime: DateTimeFormat;
}

export interface EquipmentQuals {
  equipmentGroup: string;
  equipmentNumber: string;
  equipmentType: string;
}

export interface PayCredit {
  actualCredit?: string | null;
  actualFlight?: string | null;
  actualTotalCredit: number;
  deadheadCredit: number;
  greaterTime: number;
  scheduledCredit?: string | null;
  scheduledFlight?: string | null;
  scheduledTotalCredit: number;
}

export interface SequencePayCredit {
  actualCredit?: string | null;
  actualFlight?: string | null;
  actualSeqCredit?: string | null;
  actualTotalCredit: number;
  deadheadCredit: number;
  greaterTime: number;
  scheduledCredit?: string | null;
  scheduledFlight?: string | null;
  scheduledSeqCredit?: string | null;
  scheduledTotalCredit: number;
}

export interface SpeakerRequirement {
  posLangQual?: string | null;
  posSpeakerRestricted: boolean;
  seqLangQuals?: string | null;
  totalMissingForCabin: number;
  totalMissingForSeq: number;
  totalRequiredForCabin: number;
  totalRequiredForSeq: number;
}

export interface ContractMonth {
  contractMonthStartDate: string;
  contractMonthEndDate: string;
  contractMonthType: string;
  contractMonthBase: string;
}

export type GetScheduleDetailsResponse = {
  crewType: string;
  success: boolean;
  bidStatuses?: BidStatuses[];
  calendarResponse: Calendar[];
};
