<div
  *ngIf="!hasSabreResponse"
  class="h-full overflow-y-auto bg-cirrus dark:bg-carbon">
  <!-- ! HEADER -->
  <section>
    <div
      class="flex items-center justify-center bg-white py-4 text-xl font-medium dark:bg-charcoal dark:text-white lt-sm:py-2">
      <div class="ml-4 gt-md:hidden">
        <crew-web-settings-button />
      </div>
      <span class="mx-auto">Check-in</span>
      <button
        *ngIf="supportsNotifications"
        class="mr-4"
        (click)="toggleNotificationModal()">
        <div
          *ngIf="hasNotifications"
          class="absolute right-4 top-4 h-1 w-1 rounded-full bg-afterburner p-1">
        </div>
        <crew-ui-icon
          name="notifications"
          [color]="isDark ? colors.Troposphere : colors.Stratosphere" />
      </button>
    </div>
    <div
      class="mt-px flex items-center border-b bg-white py-2 dark:border-nickel dark:bg-charcoal dark:text-white lt-sm:py-0">
      <h2 class="flex-grow p-2 xs:pl-3"> {{ showUpdatedTime() }} </h2>
      <button
        class="px-4 py-2 text-stratosphere disabled:text-steel dark:text-troposphere"
        (click)="refresh()">
        <crew-ui-icon
          name="refresh"
          [color]="isDark ? colors.Troposphere : colors.Stratosphere" />
      </button>
    </div>
  </section>
  <!-- ! -->
  <div
    class="mx-auto w-1/2 py-8 text-lg dark:text-white xs:w-11/12 sm:w-11/12 md:w-3/4 lt-sm:pt-2 lt-md:mb-20">
    <!-- ! TODAY -->
    <section>
      <div class="mb-4 text-[20px] font-medium leading-[25px]">
        Today, {{ todaysDate }}
      </div>
      <div *ngIf="todaysEvents.length > 0; else noEventsToday">
        <div *ngFor="let event of todaysEvents; let i = index">
          <div class="py-1" *ngIf="getType(event) === eventTypes.Sequence">
            <crew-web-overview-sequence-card [event]="event" />
          </div>
          <div *ngIf="getType(event) !== eventTypes.Sequence">
            <crew-web-overview-non-sequence-card [event]="event" />
          </div>
        </div>
      </div>
      <ng-template #noEventsToday
        ><crew-web-overview-no-events-card text="No events today"
      /></ng-template>
    </section>
    <!-- ! -->
    <!-- ! UPCOMING -->
    <section>
      <div class="mb-4 mt-8 text-[20px] font-medium leading-[25px]">
        Upcoming{{ upcomingDate ? ',' : '' }} {{ upcomingDate }}
      </div>
      <div *ngIf="upcomingEvents.length > 0; else noUpcomingEvents">
        <div *ngFor="let event of upcomingEvents">
          <div class="py-1" *ngIf="getType(event) === eventTypes.Sequence">
            <crew-web-overview-sequence-card [event]="event" />
          </div>
          <div *ngIf="getType(event) !== eventTypes.Sequence">
            <crew-web-overview-non-sequence-card [event]="event" />
          </div>
        </div>
      </div>
      <ng-template #noUpcomingEvents>
        <crew-web-overview-no-events-card text="No upcoming events" />
      </ng-template>
    </section>
    <!-- ! -->
  </div>
  <!-- ! SIGN IN BUTTON -->
  <ng-container *ngIf="isMobileOrEmulating; else desktopNotAllowed">
    <div
      class="absolute bottom-0 w-full lt-md:bottom-[4.5rem]"
      *ngIf="todaysEvents && todaysEvents.length > 0">
      <section *ngFor="let event of todaysEvents" class="dark:text-white">
        <div
          *ngIf="signInForTrip(event, true)"
          class="bg-ozone p-4 text-center dark:bg-charcoal dark:text-white gt-md:-ml-24 gt-md:pl-24">
          <div class="flex items-center justify-center">
            <div
              class="flex items-center text-[17px] font-normal leading-[24px]">
              <div class="mr-3">
                <crew-ui-icon
                  [color]="isDark ? colors.Troposphere : colors.Stratosphere"
                  name="info" />
              </div>
              <div
                class="text-[17px] font-medium leading-[24px] text-carbon dark:text-white">
                {{ signInForTrip(event, 'title') }}
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="eligibleForTrip(event, true)"
          class="bg-ozone p-4 text-center dark:bg-charcoal dark:text-white gt-md:-ml-24 gt-md:pl-24">
          <button
            (click)="onClickEligibleForTrip(event)"
            class="w-1/3 rounded bg-afterburner px-4 py-2 font-medium text-white dark:bg-sushi lt-sm:w-4/5">
            {{ eligibleForTrip(event, 'title') }}
          </button>
        </div>
        <div
          *ngIf="signFlightPlan(event) && validateTripOnFirstLegSuccess(event)"
          class="bg-ozone p-4 text-center dark:bg-charcoal dark:text-white gt-md:-ml-24 gt-md:pl-24">
          <button
            (click)="onClickSignFlightButton(event)"
            [ngClass]="[signFlightPlan(event, 'style')]"
            class="inline-flex w-1/3 justify-center rounded px-4 py-2 font-medium text-white lt-sm:w-4/5">
            <span class="pr-2" *ngIf="signFlightPlan(event, 'icon')">
              <crew-ui-icon name="edit_square" />
            </span>
            {{ signFlightPlan(event, 'title') }}
          </button>
        </div>
      </section>
    </div>
  </ng-container>
  <ng-template #desktopNotAllowed>
    <section
      *ngIf="todaysEvents && todaysEvents.length > 0"
      class="absolute bottom-0 w-full bg-opacity-50 lt-md:bottom-[4.5rem]">
      <div
        class="bg-afterburner bg-opacity-80 p-4 text-center gt-md:-ml-24 gt-md:pl-24">
        <div class="flex items-center justify-center text-white">
          <crew-ui-icon [color]="colors.White" name="report" />
          <span class="ml-2">
            Please use a mobile device to sign in for your trip.
          </span>
        </div>
      </div>
    </section>
  </ng-template>
</div>
<!-- ! NOTIFICATION MODAL-->
<crew-web-overview-notification-modal *ngIf="showNotificationModal" />
<!-- ! SABRE DOCUMENT-->
<crew-web-sabre-document
  (clickFlightPlanButton)="onClickSignFlightButton($event)"
  *ngIf="hasSabreResponse" />
<!-- ! TRIP SIGN IN API FAILURE ALERT -->
<crew-web-alert-modal
  *ngIf="tripSignInAlert && tripSignInAlert?.message !== ''"
  [alertMessage]="tripSignInAlert"
  (clickClose)="onClickClose($event)"></crew-web-alert-modal>
