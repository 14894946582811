import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import {
  Colors,
  CrewUIDarkModeService,
  CrewUIIconComponent,
} from '../../../../shared/components/crew-ui';
import { formatDate } from '../../../../shared/utils/date.utils';
import { CalendarCode } from '../../calendar.constant';
import { CalendarService } from '../../calendar.service';
import { CalendarEvent } from '../../calendar.types';
@Component({
  selector: 'crew-web-card-day-buttons',
  templateUrl: './card-day-buttons.component.html',
  styleUrls: ['./card-day-buttons.component.css'],
  standalone: true,
  imports: [CommonModule, CrewUIIconComponent],
})
export class CardDayButtonsComponent {
  @Input() showPreviousNextButton = false;

  @Input() showTodayButton = false;

  get selectedEvent(): CalendarEvent {
    return this.calendarService.selectedEvent();
  }

  get isPreviousDayValid(): boolean {
    return this.calendarService.isPreviousDayValid();
  }

  get isNextDayValid(): boolean {
    return this.calendarService.isNextDayValid();
  }

  get isDark(): boolean {
    return this.darkModeService.isDarkMode();
  }

  readonly calendarCode = CalendarCode;

  constructor(
    private calendarService: CalendarService,
    private darkModeService: CrewUIDarkModeService,
  ) {}

  showDate(date: string | null): string {
    const format = 'dddd, MMM D';
    return date ? formatDate(date, format) : formatDate(new Date(), format);
  }

  validateIsToday(day: string): boolean {
    return (
      formatDate(day, 'YYYY-MM-DD') === formatDate(new Date(), 'YYYY-MM-DD')
    );
  }

  onChangeDay(value: CalendarCode): void {
    this.calendarService.onSelectDay(value);
  }

  /**
   * @param value - true/false
   * @returns- Colors
   */
  iconColor(value: boolean): string {
    let style = this.isDark ? Colors.Troposphere : Colors.Stratosphere;
    if (value) {
      style = this.isDark ? Colors.Steel : Colors.Nickel;
    }
    return `text-${style}`;
  }

  showEventDate(selectedEvent: CalendarEvent): string {
    return selectedEvent && selectedEvent.day
      ? this.showDate(selectedEvent.day)
      : this.showDate(null);
  }
}
