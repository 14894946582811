import { computed, Injectable, OnDestroy, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CrewUIDarkModeService implements OnDestroy {
  isDarkMode = signal(false);
  isLightMode = computed(() => !this.isDarkMode());

  private localStorageKey = 'dark-mode';

  private get document(): HTMLElement {
    return document.documentElement;
  }

  private readonly observer = new MutationObserver(
    (mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        const match =
          mutation.type === 'attributes' &&
          mutation.attributeName === 'data-mode';
        if (match) {
          const preference = this.document.getAttribute('data-mode');
          this.isDarkMode.set(preference === 'dark');
        }
      }
    },
  );

  private readonly observerConfig: MutationObserverInit = {
    attributes: true,
    attributeFilter: ['data-mode'],
  };

  constructor() {
    if (this.isDarkPreferred()) this.document.setAttribute('data-mode', 'dark');
    else this.document.setAttribute('data-mode', 'light');

    this.isDarkMode.set(this.isDarkPreferred());

    this.setupDocumentObserver();
    this.setupSystemObserver();
  }
  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  toggle(on?: boolean): void {
    const state = on === undefined ? !this.isDarkMode() : on;

    this.isDarkMode.set(state);
    this.saveLocalStoragePreference(state);

    if (this.isDarkMode()) this.document.setAttribute('data-mode', 'dark');
    else this.document.setAttribute('data-mode', 'light');
  }

  private loadLocalStoragePreference(): boolean | undefined {
    const preference = localStorage.getItem(this.localStorageKey);
    const result = preference ? preference === 'true' : undefined;
    return result;
  }

  // Use the local storage value if it exists, otherwise use the document data-mode attribute value
  private isDarkPreferred(): boolean {
    const appPrefer = this.loadLocalStoragePreference();
    const docPrefer = this.document.getAttribute('data-mode') === 'dark';
    return appPrefer !== undefined ? appPrefer : docPrefer;
  }

  private saveLocalStoragePreference(isDarkMode: boolean): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify(isDarkMode));
  }

  private setupSystemObserver(): void {
    const media = window.matchMedia('(prefers-color-scheme: dark)');

    media.addEventListener('change', (e: MediaQueryListEvent) => {
      if (e.matches) this.document.setAttribute('data-mode', 'dark');
      else this.document.setAttribute('data-mode', 'light');
      this.isDarkMode.set(e.matches);
      this.saveLocalStoragePreference(e.matches);
    });

    if (!localStorage.getItem(this.localStorageKey)) {
      window.addEventListener('load', () => {
        if (media.matches) this.document.setAttribute('data-mode', 'dark');
        else this.document.setAttribute('data-mode', 'light');
        this.isDarkMode.set(media.matches);
      });
    }
  }

  private setupDocumentObserver(): void {
    this.observer.observe(this.document, this.observerConfig);
  }
}
