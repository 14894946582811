<ng-container *ngIf="commands && commands.length > 0">
  <div class="h-full bg-cirrus dark:bg-carbon gt-md:w-full">
    <div
      class="lt-sm:w-86 m-2 mx-auto flex cursor-pointer items-center justify-between rounded-xl bg-white p-3 dark:bg-charcoal dark:text-white gt-xs:w-96 gt-md:w-192"
      (click)="onClickCommand(command)"
      *ngFor="let command of commands">
      <div class="top-10 ml-3">
        <h2 class="text-[17px] font-medium leading-[24px]">
          {{ command.commandKeywordDisplay }}
        </h2>
        <p class="text-[17px] font-light leading-[24px]">{{
          command.commandDescription
        }}</p>
      </div>
      <button
        *ngIf="showIcon"
        class="ml-auto text-stratosphere dark:text-troposphere">
        <crew-ui-icon [color]="colors.Nickel" name="keyboard_arrow_right" />
      </button>
      <button
        *ngIf="showViewText"
        class="ml-auto text-stratosphere dark:text-troposphere">
        View
      </button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="websites && websites.length > 0">
  <div class="h-full bg-cirrus dark:bg-carbon gt-md:w-full">
    <div
      class="lt-sm:w-86 m-2 mx-auto flex cursor-pointer items-center justify-between rounded-xl bg-white p-3 dark:bg-charcoal dark:text-white gt-xs:w-96 gt-md:w-192"
      *ngFor="let website of websites"
      (click)="openWebsite(website.url)">
      <div class="top-10 ml-3">
        <h2 class="text-[17px] font-normal leading-[24px]">
          {{ website.name }}
        </h2>
      </div>
      <button
        *ngIf="showIcon"
        class="ml-auto text-stratosphere dark:text-troposphere">
        <crew-ui-icon [color]="colors.Nickel" name="keyboard_arrow_right" />
      </button>
    </div>
  </div>
</ng-container>
