import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

// TODO Use this when Techops Auth Library is ready for Angular 18
// bootstrapApplication(AppComponent, appConfig).catch((err) =>
//   console.error(err),
// );

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
