<div class="h-full w-full" *ngIf="routerUrl.includes('read-and-acknowledge')">
  <router-outlet></router-outlet>
</div>

<div class="h-full w-full" *ngIf="!routerUrl.includes('read-and-acknowledge')">
  <div class="h-full w-full" *ngIf="isUserServiceLoading">
    <crew-ui-overlay>
      <div class="flex flex-col items-center justify-center">
        <div class="mb-4 h-24 w-24">
          <crew-ui-logo [full]="false"></crew-ui-logo>
        </div>
        <crew-ui-spinner [size]="'sm'"></crew-ui-spinner>
      </div>
    </crew-ui-overlay>
  </div>

  <!-- Desktop Navigation -->
  <div class="flex h-full w-full" *ngIf="!isUserServiceLoading">
    <aside
      class="sticky top-0 border-r border-solid border-cirrus px-2 dark:border-charcoal dark:bg-carbon lt-md:hidden"
      #crewWebSideNav>
      <crew-web-side-nav
        [routerUrl]="routerUrl"
        [getCrewPath]="getCrewPath"
        [isActiveRoute]="isActiveRoute"
        (showModal)="showSettingsModal()" />
    </aside>
    <main class="h-full w-full bg-cirrus dark:bg-carbon">
      <router-outlet />
      <!-- Tablet, Mobile Navigation -->
      <div class="absolute bottom-0 w-full xs:visible gt-sm:hidden">
        <crew-web-bottom-nav
          [routerUrl]="routerUrl"
          [getCrewPath]="getCrewPath"
          [isActiveRoute]="isActiveRoute" />
      </div>
    </main>
  </div>

  <crew-ui-toast />
  <crew-ui-overlay [isHidden]="!isLoading">
    <div class="flex flex-col items-center justify-center">
      <div class="mb-4 h-24 w-24">
        <crew-ui-logo [full]="false"></crew-ui-logo>
      </div>
      <crew-ui-spinner [size]="'sm'"></crew-ui-spinner>
    </div>
  </crew-ui-overlay>
  <crew-web-settings-modal />
</div>
