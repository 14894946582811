<div class="pt-2">
  <div
    class="flex items-center justify-between rounded-xl bg-white shadow dark:bg-charcoal dark:text-white">
    <button
      class="relative left-4 text-stratosphere dark:text-troposphere"
      (click)="onClose()">
      Close
    </button>
    <h2 *ngIf="title" class="m-2 mx-auto text-center font-medium"
      >{{ title }}
    </h2>
  </div>
  <div
    class="dark:text-whites mx-auto w-11/12 rounded-xl py-3 dark:bg-carbon sm:w-9/12 md:w-1/2 lg:w-1/3">
    <crew-ui-search-bar
      type="text"
      [value]="value"
      class="border-none bg-transparent"
      (valueChange)="onKeyup($event)"
      [placeholder]="placeholder" />
  </div>
</div>
