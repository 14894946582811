<div class="{{ this.size }} relative">
  <div [ngClass]="buildBar()" class="one"></div>
  <div [ngClass]="buildBar()" class="two"></div>
  <div [ngClass]="buildBar()" class="three"></div>
  <div [ngClass]="buildBar()" class="four"></div>
  <div [ngClass]="buildBar()" class="five"></div>
  <div [ngClass]="buildBar()" class="six"></div>
  <div [ngClass]="buildBar()" class="seven"></div>
  <div [ngClass]="buildBar()" class="eight"></div>
  <div [ngClass]="buildBar()" class="nine"></div>
  <div [ngClass]="buildBar()" class="ten"></div>
  <div [ngClass]="buildBar()" class="eleven"></div>
  <div [ngClass]="buildBar()" class="twelve"></div>
  <div [ngClass]="buildBar()" class="thirteen"></div>
  <div [ngClass]="buildBar()" class="fourteen"></div>
  <div [ngClass]="buildBar()" class="fifteen"></div>
  <div [ngClass]="buildBar()" class="sixteen"></div>
</div>
