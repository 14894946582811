import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { getToken, Messaging } from '@angular/fire/messaging';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { CoreService } from '../../services/azure/core.service';
import {
  CaptureTripSignInOptInTimestampRequest,
  GetStartSessionV2Request,
} from '../../services/azure/core.service.types';
import { NotificationService } from '../../services/azure/notification.service';
import { FirebaseIdbService } from '../../services/idb/firebase.idb.service';
import { MessageIdbService } from '../../services/idb/message.idb.service';
import { TimeService } from '../../services/time.service';
import { UserService } from '../../services/user.service';
import { BusinessUnit } from '../../types';
import {
  Colors,
  CrewUIButtonComponent,
  CrewUICardComponent,
  CrewUIDarkModeService,
  CrewUIIconComponent,
  CrewUIInputComponent,
  CrewUIModalComponent,
  CrewUIOverlayComponent,
  CrewUISelectComponent,
  CrewUISelectOption,
  CrewUISwitchComponent,
  CrewUIToastService,
  Selected,
} from '../crew-ui';
import { SettingsModalService } from './settings-modal.service';

@Component({
  selector: 'crew-web-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    CrewUIModalComponent,
    CrewUIInputComponent,
    CrewUISelectComponent,
    FormsModule,
    CrewUIButtonComponent,
    CrewUIOverlayComponent,
    CrewUICardComponent,
    CrewUIIconComponent,
    CrewUISwitchComponent,
  ],
})
export class SettingsModalComponent implements OnInit {
  constructor(
    private coreService: CoreService,
    private darkModeService: CrewUIDarkModeService,
    private firebaseIdbService: FirebaseIdbService,
    private messageIdbService: MessageIdbService,
    private messaging: Messaging,
    private notificationService: NotificationService,
    private router: Router,
    private settingsModalService: SettingsModalService,
    private timeService: TimeService,
    private toastService: CrewUIToastService,
    private userService: UserService,
  ) {
    for (const [key, value] of Object.entries(BusinessUnit)) {
      this.businessUnitOptions.push({ value: key, text: value });
    }
  }

  isEmulationModalVisible = false;
  isTnCModal = false;
  showAcceptTnC = false;
  tncSwitchState = false;
  tncOptInTime = '';
  localOptInTime = '';

  businessUnitOptions = [] as CrewUISelectOption[];

  private readonly tncLocalStorageKey = 'sign-in-opt-in-time';

  get notificationsAllowed(): boolean {
    if (!window.Notification) return false;
    return Notification.permission === 'granted';
  }

  get isDarkMode(): boolean {
    return this.darkModeService.isDarkMode();
  }

  get isTimeFormat24(): boolean {
    return this.timeService.isTimeFormat24();
  }

  get isVisible(): boolean {
    return this.settingsModalService.isVisible();
  }

  get isAAPIandMQPIModal(): boolean {
    return this.userService.isAAPIMQPI();
  }

  get isAAFAandMQFAModal(): boolean {
    return this.userService.isAAFAMQFA();
  }

  get supportsNotifications(): boolean {
    return this.firebaseIdbService.supportsNotifications();
  }

  get $userService(): UserService {
    return this.userService;
  }

  version = environment.version;

  colors = Colors;

  inputBusinessUnit?: BusinessUnit;

  inputEmployeeNumber?: number;

  get emulateButtonDisabled(): boolean {
    return !this.inputBusinessUnit || !this.inputEmployeeNumber;
  }

  ngOnInit(): void {
    const acceptedTncTime = localStorage.getItem(this.tncLocalStorageKey);
    if (acceptedTncTime == null) return;
    const formattedTime = new Date(acceptedTncTime).toLocaleDateString(
      'en-US',
      {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: !this.isTimeFormat24,
      },
    );

    this.tncSwitchState = true;
    this.tncOptInTime = formattedTime;
  }

  allowNotifications(): void {
    if (!this.supportsNotifications) return;

    navigator.serviceWorker
      .register('firebase-messaging-sw.js')
      .then((serviceWorkerRegistration) => {
        getToken(this.messaging, {
          serviceWorkerRegistration,
          vapidKey: environment.firebase.vapidKey,
        })
          .then((deviceToken: string) => {
            const payload: GetStartSessionV2Request = {
              empIdLogin: this.userService.employeeNumber(),
              deviceToken,
              clientId: 'WEB',
              clientType: '',
              clientVersion: '',
              deviceOSType: '',
              fcm: true,
              sessionRefresh: false,
            };
            this.coreService.getStartSessionV2(payload).subscribe(() => {
              localStorage.setItem('fcm-token', deviceToken);
              this.toastService.showToast({
                message: 'Notifications enabled.',
                textColor: Colors.White,
                backgroundColor: Colors.Zanzibar,
              });
            });
          })
          .catch(() => {
            this.toastService.showToast({
              message:
                'Could not enable notifications, unable to retrieve token.',
              textColor: Colors.White,
              backgroundColor: Colors.Afterburner,
            });
          });
      })
      .catch(() => {
        this.toastService.showToast({
          message: 'Could not enable notifications, permission denied.',
          textColor: Colors.White,
          backgroundColor: Colors.Afterburner,
        });
      });
  }

  toggleVisibility(): void {
    this.settingsModalService.isVisible.set(
      !this.settingsModalService.isVisible(),
    );
  }

  toggleDarkMode($event: Selected): void {
    this.darkModeService.toggle($event.selected);
  }

  toggleTnCFromSwitch($event: Selected): void {
    if (!$event.selected) {
      this.tncSwitchState = !this.tncSwitchState;
      this.tncOptInTime = '';
      localStorage.removeItem(this.tncLocalStorageKey);
    } else {
      this.showAcceptTnC = true;
      this.tncSwitchState = !this.tncSwitchState;
      this.toggleTnCModal();
    }
  }

  async emulate(): Promise<void> {
    if (!this.inputEmployeeNumber || !this.inputBusinessUnit) return;
    this.userService.emulate(this.inputEmployeeNumber, this.inputBusinessUnit);
    this.messageIdbService.refreshMessages();
    this.firebaseIdbService.deleteNotifications();
    this.inputEmployeeNumber = undefined;
    this.inputBusinessUnit = undefined;
    this.toggleEmulationModalVisibility();
  }

  async clearEmulation(): Promise<void> {
    this.userService.clearEmulation();
    this.messageIdbService.refreshMessages();
    this.firebaseIdbService.deleteNotifications();
  }

  setSelectedBusinessUnit($event: CrewUISelectOption): void {
    this.inputBusinessUnit = $event.value as BusinessUnit;
  }

  setSelectedEmployeeNumber($event: string): void {
    this.inputEmployeeNumber = Number($event);
  }

  toggleEmulationModalVisibility(): void {
    this.isEmulationModalVisible = !this.isEmulationModalVisible;
  }

  toggleTnCModal(): void {
    this.isTnCModal = !this.isTnCModal;
  }

  goToInstallationGuide(): void {
    this.toggleVisibility();
    this.router.navigate(['/install']);
  }

  logout(): void {
    this.userService.logout();
  }

  acceptTnC(): Subscription {
    const optInTimeRawFormat = new Date();
    const payload: CaptureTripSignInOptInTimestampRequest = {
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      appSessionId: this.userService.appSession(),
      businessUnit: BusinessUnit.AAPI,
      empIdLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      optIn: true,
      optInTime: optInTimeRawFormat
        .toISOString()
        .replace(/T/, ' ')
        .replace(/\..+/, ''),
      siteMinderEmpId: this.userService.employeeNumber(),
    };
    return this.coreService
      .captureTripSignInOptInTimestamp(payload)
      .subscribe((resp) => {
        if (resp && resp.success) {
          const localFormatDateTime =
            optInTimeRawFormat.toLocaleDateString() +
            ', ' +
            optInTimeRawFormat.toLocaleTimeString();
          this.localOptInTime = localFormatDateTime.toString();
          this.tncOptInTime = this.localOptInTime;
        }
        this.showAcceptTnC = false;
        this.toggleTnCModal();
        localStorage.setItem(this.tncLocalStorageKey, this.tncOptInTime);
      });
  }

  toggleTimeFormat24($event: Selected): void {
    this.timeService.toggle($event.selected);
  }
}
