import moment from 'moment';

export const formatDate = (value?: string | Date, format = 'YYYY-MM-DD') => {
  if (!value) return '--';
  return moment(value).format(format);
};

export const unsetTimeZone = (value?: string) => {
  if (!value) return value;
  value = value.split('T').join(' ');
  return value;
};

export const getDates = (year: number, month: number) => {
  const startDate = new Date(year, month, 1);
  const endDate = new Date(
    year,
    month + 1,
    0,
  ); /* 0 `day` gets last day from prior month */

  /* result of getDay(): 0 means Sunday and 6 means Saturday */
  const startDay = startDate.getDay();
  /* last day number = total days in current month */
  const currentMonthTotalDays = endDate.getDate();
  const totalWeeks = Math.ceil((currentMonthTotalDays + startDay) / 7);

  const prevMonthEndDate = new Date(year, month, 0);
  let prevMonthDay = prevMonthEndDate.getDate() - startDay + 1;
  let nextMonthDay = 1;
  const days = [];

  for (let i = 0; i < totalWeeks * 7; i += 1) {
    let date;
    /* Previous month dates (if month does not start on Sunday) */
    if (i < startDay) {
      date = new Date(year, month - 1, prevMonthDay);
      prevMonthDay = prevMonthDay + 1;
      /* Next month dates (if month does not end on Saturday) */
    } else if (i > currentMonthTotalDays + (startDay - 1)) {
      date = new Date(year, month + 1, nextMonthDay);
      nextMonthDay = nextMonthDay + 1;
      /* Current month dates. */
    } else {
      date = new Date(year, month, i - startDay + 1);
    }
    days.push(date);
  }
  return days;
};

/**
 * Get Month Interval in Integer
 * Eg. start - new Date('2023', '10'), end - new Date('2023', '11') = 1
 * Eg. start - new Date('2023', '11'), end - new Date('2023', '10') = -1
 */
export const getMonthInterval = (start: Date, end: Date): number => {
  const symbol = start > end ? '-' : '';
  const timeDiff = Math.abs(end.getTime() - start.getTime());
  return parseInt(`${symbol}${Math.round(timeDiff / (2e3 * 3600 * 365.25))}`);
};

export const getTimeDiff = (
  startDate: string,
  endDate: string,
  format = 'HH:mm',
): string => {
  const startDateTime = moment(startDate, 'YYYY-MM-DD HH:mm:ss');
  const endDateTime = moment(endDate, 'YYYY-MM-DD HH:mm:ss');
  return moment.utc(endDateTime.diff(startDateTime)).format(format);
};

export const isDateInBetween = (
  startDate: string,
  endDate: string,
  compareDate: string | Date,
  format = 'YYYY-MM-DD',
): boolean => {
  const cDate = moment(compareDate, format);
  const sDate = moment(startDate, format);
  const eDate = moment(endDate, format);
  return cDate.isBetween(sDate, eDate, undefined, '[]');
};

export const setTime = (
  day: string,
  hour = 0,
  mins = 0,
  format = 'YYYY-MM-DD HH:mm:ss',
): string => {
  return moment(day)
    .set({
      hour: hour,
      minute: mins,
      second: 0,
      millisecond: 0,
    })
    .format(format);
};

export const addMonth = (
  day: string,
  value: number,
  format = 'YYYY-MM-DD',
): string => {
  return moment(day).add(value, 'M').format(format);
};

export const getNextMonthLastDay = (day: string): string => {
  return moment(day).add(1, 'M').endOf('month').format('YYYY-MM-DD');
};

export const getPrevMonthFirstDay = (day: string): string => {
  return moment(day).subtract(1, 'M').startOf('month').format('YYYY-MM-DD');
};

export const addDays = (day: Date, value: number): Date => {
  const next = moment(day).add(value, 'day').toDate();
  return next;
};

export const subtractDays = (day: Date, value: number): Date => {
  const previous = moment(day).subtract(value, 'day').toDate();
  return previous;
};

export const getTodayStartEndDateTimeInBetweenSequenceDates = (
  startDate: string,
  endDate: string,
  day: string,
): {
  startDateTime: string;
  endDateTime: string;
} => {
  const eventStartDate = formatDate(startDate, 'YYYY-MM-DD');
  const eventEndDate = formatDate(endDate, 'YYYY-MM-DD');
  const todayDate = formatDate(day, 'YYYY-MM-DD');
  const eventStartDateTime = formatDate(startDate, 'YYYY-MM-DD HH:mm:ss');
  const eventEndDateTime = formatDate(endDate, 'YYYY-MM-DD HH:mm:ss');
  const todayDateTime = formatDate(day, 'YYYY-MM-DD HH:mm:ss');
  const dayStartTime = setTime(todayDateTime, 0, 0, 'YYYY-MM-DD HH:mm:ss');
  const dayEndTime = setTime(todayDateTime, 23, 59, 'YYYY-MM-DD HH:mm:ss');
  const response = {
    startDateTime: dayStartTime,
    endDateTime: dayEndTime,
  };
  if (eventStartDate === todayDate && eventEndDate === todayDate) {
    response.startDateTime = eventStartDateTime;
    response.endDateTime = eventEndDateTime;
  } else if (eventStartDate === todayDate && eventEndDate !== todayDate) {
    response.startDateTime = eventStartDateTime;
    response.endDateTime = dayEndTime;
  } else if (eventStartDate !== todayDate && eventEndDate === todayDate) {
    response.startDateTime = dayStartTime;
    response.endDateTime = eventEndDateTime;
  }
  return response;
};

export const getNumberOfDays = (
  start: string | Date,
  to: string | Date,
): number => {
  return (
    Math.abs(
      moment(start, 'YYYY-MM-DD')
        .startOf('day')
        .diff(moment(to, 'YYYY-MM-DD').startOf('day'), 'days'),
    ) + 1
  );
};

export const isCurrentTimeLessThanGivenTime = (day: Date | string): boolean => {
  const givenTime = moment(day).format('YYYY-MM-DD HH:mm:ss');
  const currentTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  return currentTime < givenTime ? true : false;
};

export const minutesToHoursAndMinutes = (mins: number): string => {
  if (mins > 0) {
    const minutes = mins % 60;
    const hours = (mins - minutes) / 60;
    return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
  }
  return `0h 0m`;
};
