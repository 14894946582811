<crew-ui-card>
  <div class="my-2 p-4">
    <div class="flex items-center justify-between">
      <span class="font-bold dark:text-white">
        {{ notification.data?.messageTitle }}
      </span>
      <crew-ui-icon
        [name]="this.isAckRequired ? 'report' : 'info'"
        [color]="iconColor" />
    </div>
    <crew-ui-divider kind="secondary" />
    <div>
      <p class="dark:text-white">
        {{ notification.data?.messageBody }}
      </p>
    </div>
    <div (click)="onClick($event)" [ngClass]="buttonClass">
      <span class="font-bold"> {{ buttonText }}</span>
      <crew-ui-icon
        *ngIf="isAckRequired"
        name="chevron_right"
        [color]="iconColor" />
    </div>
  </div>
</crew-ui-card>
