<div class="flex h-full flex-col overflow-y-auto lt-md:h-[95%]">
  <div class="sticky top-0 flex flex-col bg-white pt-2 dark:bg-charcoal">
    <div class="flex items-center justify-between px-2">
      <button type="button" title="Back" class="p-2" (click)="goBack($event)">
        <crew-ui-icon
          name="chevron_left"
          [color]="isDark ? colors.Cirrus : colors.Black" />
      </button>
      <button type="button" title="Search" class="p-2" (click)="toggleSearch()">
        <crew-ui-icon
          [name]="showSearchBar ? 'search_off' : 'search'"
          [color]="isDark ? colors.Cirrus : colors.Black" />
      </button>
    </div>
    <div *ngIf="showSearchBar" class="mt-6 px-6">
      <crew-ui-search-bar (valueChange)="search($event)" />
    </div>
    <h1
      class="mt-2 border-b border-cirrus px-4 py-4 text-[32px] font-bold leading-10 dark:border-carbon dark:bg-charcoal dark:text-white">
      {{ title }}
    </h1>
  </div>
  <div
    class="flex h-full flex-col bg-white pb-4 dark:bg-charcoal lt-sm:pb-10"
    *ngIf="hasMessages; else noMessage">
    <cdk-virtual-scroll-viewport itemSize="57" class="h-full">
      <ul>
        <li
          class="cursor-pointer px-2 py-4"
          *cdkVirtualFor="
            let message of messages;
            let i = index;
            let last = last
          "
          (click)="onSelectMessage(message)"
          [ngClass]="{
            'rounded-lg bg-stratosphere text-white':
              selectedId === message.messageId,
            'border-b border-cirrus dark:border-steel': !last,
            'hover:rounded-lg hover:bg-cirrus dark:hover:bg-carbon':
              selectedId !== message.messageId
          }">
          <div class="flex w-full items-center justify-start">
            <div class="flex"
              ><div
                *ngIf="message.messageRead === 0"
                class="mr-1 h-2 w-2 rounded-full bg-stratosphere">
              </div
            ></div>
            <p
              class="line-clamp-2 w-full text-[16px] font-medium leading-[24px] dark:text-white"
              [class.mx-1]="message.messageRead === 0"
              [class.mx-4]="message.messageRead === 1">
              {{ message.messageTitle }}
            </p>
            <span
              class="ml-auto px-1 text-[14px] font-normal leading-[20px] dark:text-nickel">
              {{ showDateTime(message.effectiveDate) }}
            </span>
          </div>
          <!-- <div
          class="ml-4 text-[14px] font-normal leading-[20px] dark:text-white">
          {{ message.messageSignature }}
        </div> -->
        </li>
      </ul>
    </cdk-virtual-scroll-viewport>
  </div>
  <ng-template #noMessage
    ><div
      class="flex justify-center border-t border-turbine bg-cirrus dark:border-steel dark:bg-carbon">
      <p class="mt-16 cursor-none text-center text-nickel"> No messages </p>
    </div></ng-template
  >
</div>
