import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CalendarComponent } from './routes/calendar/calendar.component';
import { InstallationGuideComponent } from './routes/installation-guide/installation-guide.component';
import { MessageComponent } from './routes/message/message.component';
import { OverviewComponent } from './routes/overview/overview.component';
import { ReadAndAcknowledgeComponent } from './routes/read-and-acknowledge/read-and-acknowledge.component';
import { SearchComponent } from './routes/search/search.component';
import { CustomPingAuthGuard } from './shared/guards/custom-ping-auth.guard';

export const CREW_ROUTES = Object.freeze({
  authCallback: 'auth-callback',
  authError: 'error',
  authError401: 'error-401',
  authLogin: 'login',
  authLogout: 'logout',
  authRenewCallback: 'renew-callback',
  authSignoutCallback: 'signout-callback',
  overview: 'overview',
  calendar: 'calendar',
  message: 'message',
  search: 'search',
  install: 'install',
  readAndAcknowledge: 'read-and-acknowledge',
});

const routes: Routes = [
  {
    canActivate: [CustomPingAuthGuard],
    path: CREW_ROUTES.overview,
    component: OverviewComponent,
    data: {
      title: 'Dashboard',
    },
  },
  {
    canActivate: [CustomPingAuthGuard],
    path: CREW_ROUTES.calendar,
    component: CalendarComponent,
    data: {
      title: 'Calendar',
    },
  },
  {
    canActivate: [CustomPingAuthGuard],
    path: CREW_ROUTES.message,
    component: MessageComponent,
    data: {
      title: 'Message',
    },
  },
  {
    canActivate: [CustomPingAuthGuard],
    path: CREW_ROUTES.search,
    component: SearchComponent,
    data: {
      title: 'Search',
    },
  },
  {
    canActivate: [CustomPingAuthGuard],
    path: CREW_ROUTES.install,
    component: InstallationGuideComponent,
    data: {
      title: 'Install',
    },
  },
  {
    canActivate: [CustomPingAuthGuard],
    path: CREW_ROUTES.readAndAcknowledge,
    component: ReadAndAcknowledgeComponent,
  },
  { path: '**', redirectTo: CREW_ROUTES.overview },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), HttpClientModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
