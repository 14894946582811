import { ErrorHandler, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import {
  ApplicationInsights,
  ICustomProperties,
  IDependencyTelemetry,
  IEventTelemetry,
  IExceptionTelemetry,
  IMetricTelemetry,
  IPageViewTelemetry,
  ITraceTelemetry,
} from '@microsoft/applicationinsights-web';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { UserService } from '../user.service';

// * Extracted code for severity level used by app insights library
// export declare const enum eSeverityLevel {
//     Verbose = 0,
//     Information = 1,
//     Warning = 2,
//     Error = 3,
//     Critical = 4
// }

@Injectable()
export class InsightsService implements OnDestroy {
  constructor(
    private router: Router,
    private userService: UserService,
    private deviceService: DeviceDetectorService,
  ) {
    this.primaryAppInsights = this.hasPrimaryAppInsights
      ? new ApplicationInsights({
          config: {
            instrumentationKey:
              environment.azure.insights.primaryInstrumentationKey,
            extensions: [this.angularPlugin],
            extensionConfig: {
              [this.angularPlugin.identifier]: {
                router: this.router,
                errorServices: [new ErrorHandler()],
              },
            },
          },
        })
      : undefined;

    this.secondaryAppInsights = this.hasSecondaryAppInsights
      ? new ApplicationInsights({
          config: {
            instrumentationKey:
              environment.azure.insights.secondaryInstrumentationKey,
            extensions: [this.angularPlugin],
            extensionConfig: {
              [this.angularPlugin.identifier]: {
                router: this.router,
                errorServices: [new ErrorHandler()],
              },
            },
          },
        })
      : undefined;

    this.primaryAppInsights?.loadAppInsights();
    this.secondaryAppInsights?.loadAppInsights();
  }

  private onTokenRenewalFailed$?: Subscription;

  private get hasPrimaryAppInsights(): boolean {
    return environment.azure.insights.primaryInstrumentationKey !== '';
  }

  private get hasSecondaryAppInsights(): boolean {
    return environment.azure.insights.secondaryInstrumentationKey !== '';
  }

  private angularPlugin = new AngularPlugin();

  private primaryAppInsights: ApplicationInsights | undefined;

  private secondaryAppInsights: ApplicationInsights | undefined;

  private get employeeNumber(): string {
    return this.userService.employeeNumber().toString() ?? 'Unknown';
  }

  private get emulatedEmployeeNumber(): string {
    return this.userService.emulatedEmployeeNumber()?.toString() ?? 'Unknown';
  }

  /**
   * @description User actions and other events. Used to track user behavior or to monitor performance.
   */
  trackEvent(
    event: IEventTelemetry,
    customProperties?: ICustomProperties,
  ): void {
    const properties = this.buildCustomProperties(customProperties);

    this.primaryAppInsights?.trackEvent(event, properties);
    this.secondaryAppInsights?.trackEvent(event, properties);

    // eslint-disable-next-line no-console
    if (!environment.production) console.log('Event:', event);
  }

  /**
   * @description Resource Diagnostic log messages. You can also capture third-party logs.
   */
  trackTrace(
    trace: ITraceTelemetry,
    customProperties?: ICustomProperties,
  ): void {
    const properties = this.buildCustomProperties(customProperties);

    this.primaryAppInsights?.trackTrace(trace, properties);
    this.secondaryAppInsights?.trackTrace(trace, properties);

    // eslint-disable-next-line no-console
    if (!environment.production) console.log('Trace:', trace);
  }

  /**
   * @description Logging exceptions for diagnosis. Trace where they occur in relation to other events and examine stack traces.
   */
  trackException(
    exception: IExceptionTelemetry,
    customProperties?: ICustomProperties,
  ): void {
    const properties = this.buildCustomProperties(customProperties);

    this.primaryAppInsights?.trackException(exception, properties);
    this.secondaryAppInsights?.trackException(exception, properties);

    // eslint-disable-next-line no-console
    if (!environment.production) console.log('Exception:', exception);

    // Using 'catchError' in an interceptor does not catch exceptions.
    // This is because the microsoft angular plugin swallows all thrown exceptions.
    // Therefore, we need to check for 401 here.
    // See this for more information: https://github.com/microsoft/applicationinsights-angularplugin-js/issues/12
    if (exception.exception?.message.includes('401')) {
      // this will force the guard to trigger token renewal process or logout user if refresh token is expired
      window.location.reload();
    }
  }

  /**
   * @description Pages, screens, panes, or forms.
   */
  trackPageView(pageView: IPageViewTelemetry): void {
    pageView.properties = this.buildCustomProperties(pageView.properties);

    this.primaryAppInsights?.trackPageView(pageView);
    this.secondaryAppInsights?.trackPageView(pageView);

    // eslint-disable-next-line no-console
    if (!environment.production) console.log('Page View:', pageView);
  }

  /**
   * @description Performance measurements such as queue lengths not related to specific events.
   */
  trackMetric(
    metric: IMetricTelemetry,
    customProperties: ICustomProperties,
  ): void {
    const properties = this.buildCustomProperties(customProperties);

    this.primaryAppInsights?.trackMetric(metric, properties);
    this.secondaryAppInsights?.trackMetric(metric, properties);

    // eslint-disable-next-line no-console
    if (!environment.production) console.log('Metric:', metric);
  }

  /**
   * @description Logging the duration and frequency of calls to external components that your app depends on.
   */
  trackDependency(dependency: IDependencyTelemetry): void {
    dependency.iKey = `${this.employeeNumber}-${this.emulatedEmployeeNumber}`;

    this.primaryAppInsights?.trackDependencyData(dependency);
    this.secondaryAppInsights?.trackDependencyData(dependency);

    // eslint-disable-next-line no-console
    if (!environment.production) console.log('Dependency:', dependency);
  }

  ngOnDestroy(): void {
    if (this.onTokenRenewalFailed$) this.onTokenRenewalFailed$.unsubscribe();
  }

  private buildCustomProperties(
    customProperties?:
      | ICustomProperties
      | {
          [key: string]: unknown;
          duration?: number;
        },
  ): ICustomProperties {
    return {
      ...customProperties,
      employeeNumber: this.employeeNumber,
      emulateEmployeeNumber: this.emulatedEmployeeNumber,
      device: this.deviceService.device,
      os: this.deviceService.os,
      osVersion: this.deviceService.os_version,
      browser: this.deviceService.browser,
      browserVersion: this.deviceService.browser_version,
      userAgent: this.deviceService.userAgent,
    };
  }
}
