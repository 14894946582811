import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpErrorInterceptor } from './http-error.interceptor';
import { LoadingOverlayInterceptor } from './loading-overlay.interceptor';

/** Http interceptor providers in outside-in order */
export const sharedInterceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingOverlayInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
];
