import { APP_BASE_HREF } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, RouterOutlet } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import {
  PingAuthenticationConfig,
  PingAuthenticationModule,
} from '@techops-ui/ping-authentication';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BottomNavComponent } from './layout/bottom-nav/bottom-nav.component';
import { SideNavComponent } from './layout/side-nav/side-nav.component';
import {
  CrewUILogoComponent,
  CrewUIOverlayComponent,
  CrewUISpinnerComponent,
  CrewUIToastComponent,
} from './shared/components/crew-ui';
import { SettingsModalComponent } from './shared/components/settings-modal/settings-modal.component';
import { sharedInterceptors } from './shared/interceptors/shared.interceptor';
import { InsightsService } from './shared/services/azure/insights.service';

export const PING_AUTH_CONFIG: PingAuthenticationConfig = {
  authority: environment.pingFederate.url,
  client_id: environment.pingFederate.clientId,
  use_html5_routing: true,
  scope: 'openid profile',
  idle_threshold: 1814400,
  timeout_duration: 60,
  post_logout_redirect_uri: window.location.origin,
  use_session_storage: false,
  base_href: '', // ! this is extremely important, leave it alone
} as const;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterOutlet,
    CrewUIToastComponent,
    CrewUIOverlayComponent,
    CrewUILogoComponent,
    CrewUISpinnerComponent,
    SideNavComponent,
    BottomNavComponent,
    SettingsModalComponent,
    RouterModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,
      registrationStrategy: 'registerWhenStable:10000',
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      // registrationStrategy: 'registerWhenStable:30000'
      // registrationStrategy: 'registerImmediately'
    }),
    PingAuthenticationModule.forRoot(PING_AUTH_CONFIG),
    AppRoutingModule,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    InsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    provideFirebaseApp(() => initializeApp(environment.firebase.config)),
    provideMessaging(() => getMessaging()),
    sharedInterceptors,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private insights: InsightsService) {}
}
