<crew-ui-card>
  <div class="p-4">
    <div class="flex justify-between">
      <span class="text-lg font-bold">Sign in reminder</span>
      <crew-ui-icon [color]="colors.Troposphere" name="info" />
    </div>
    <p class="mt-4">
      {{ showReminderMessage() }}
    </p>
    <button
      type="button"
      (click)="onClickTripSignIn($event)"
      class="mt-4 flex w-full cursor-pointer items-center justify-between rounded-lg bg-troposphere bg-opacity-10 p-2">
      <span class="font-bold text-troposphere">
        {{ showTitleInReadAckPage }}
      </span>
      <crew-ui-icon [color]="colors.Troposphere" name="arrow_forward_ios" />
    </button>
  </div>
</crew-ui-card>
<!-- ! TRIP SIGN IN API FAILURE ALERT -->
<crew-web-alert-modal
  *ngIf="tripSignInAlert && tripSignInAlert?.message !== ''"
  [alertMessage]="tripSignInAlert"
  (clickClose)="onClickClose($event)"></crew-web-alert-modal>
