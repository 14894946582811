<div
  class="z-50 h-[4.5rem] w-full border-t border-cirrus bg-white hover:border-cirrus dark:border-carbon dark:bg-carbon dark:hover:border-charcoal">
  <div class="mx-auto grid h-full grid-cols-4 font-medium">
    <button
      (click)="closeHSS()"
      [routerLink]="[getCrewPath(routes.overview)]"
      [ngClass]="[buildIconText(!isActiveRoute(routes.overview))]"
      class="group inline-flex items-center justify-center px-5 hover:bg-cirrus dark:hover:bg-charcoal xs:flex-col sm:flex-row">
      <crew-ui-icon name="where_to_vote" />
      <span
        [ngClass]="[buildIconText(!isActiveRoute(routes.overview))]"
        class="px-1 pb-1 pt-1 xs:text-[12px] xs:font-medium xs:leading-[12px] sm:text-[15px] sm:font-normal sm:leading-[18px]">
        Overview
      </span>
    </button>
    <button
      (click)="closeHSS()"
      [routerLink]="[getCrewPath(routes.calendar)]"
      [ngClass]="[buildIconText(!isActiveRoute(routes.calendar))]"
      class="group inline-flex items-center justify-center px-5 hover:bg-cirrus dark:hover:bg-charcoal xs:flex-col sm:flex-row">
      <crew-ui-icon name="calendar_month" />
      <span
        [ngClass]="[buildIconText(!isActiveRoute(routes.calendar))]"
        class="px-1 pb-1 pt-1 xs:text-[12px] xs:font-medium xs:leading-[12px] sm:text-[15px] sm:font-normal sm:leading-[18px]">
        Schedule
      </span>
    </button>
    <button
      (click)="closeHSS()"
      [routerLink]="[getCrewPath(routes.message)]"
      [ngClass]="[buildIconText(!isActiveRoute(routes.message))]"
      class="group inline-flex items-center justify-center px-5 hover:bg-cirrus dark:hover:bg-charcoal xs:flex-col sm:flex-row">
      <div class="relative">
        <crew-ui-icon name="chat" />
        <div
          class="absolute -right-2 -top-2 flex h-[18px] w-[18px] items-center justify-center rounded-full bg-afterburner p-[0.2rem] text-center text-[0.6rem] text-white"
          *ngIf="messageCount + hi6Count > 0">
          {{ messageCount + hi6Count > 99 ? '99+' : messageCount + hi6Count }}
        </div>
      </div>
      <span
        [ngClass]="[buildIconText(!isActiveRoute(routes.message))]"
        class="px-1 pb-1 pt-1 xs:text-[12px] xs:font-medium xs:leading-[12px] sm:text-[15px] sm:font-normal sm:leading-[18px]">
        Messages
      </span>
    </button>
    <button
      (click)="closeHSS()"
      [routerLink]="[getCrewPath(routes.search)]"
      [ngClass]="[buildIconText(!isActiveRoute(routes.search))]"
      class="group inline-flex items-center justify-center px-5 hover:bg-cirrus dark:hover:bg-charcoal xs:flex-col sm:flex-row">
      <crew-ui-icon name="more_horiz" />
      <span
        [ngClass]="[buildIconText(!isActiveRoute(routes.search))]"
        class="px-1 pb-1 pt-1 xs:text-[12px] xs:font-medium xs:leading-[12px] sm:text-[15px] sm:font-normal sm:leading-[18px]">
        More
      </span>
    </button>
  </div>
</div>
