import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CrewUISearchBarComponent } from '../../../shared/components/crew-ui';

@Component({
  selector: 'crew-web-search-filter-card',
  templateUrl: './search-filter-card.component.html',
  styleUrls: ['./search-filter-card.component.css'],
  standalone: true,
  imports: [CommonModule, CrewUISearchBarComponent],
})
export class SearchFilterCardComponent {
  @Input()
  title = '';

  @Input()
  placeholder = '';

  @Input()
  value = '';

  @Output()
  clickClose: EventEmitter<boolean> = new EventEmitter();

  @Output()
  searchValue: EventEmitter<string> = new EventEmitter();

  onKeyup(input: string) {
    this.searchValue.emit(input);
  }

  onClose() {
    this.clickClose.emit(true);
  }
}
