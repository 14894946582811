import { computed, Injectable, signal, WritableSignal } from '@angular/core';
import {
  FcmOptions,
  NotificationPayload as FcmNotificationPayload,
} from '@angular/fire/messaging';

import { NotificationService } from '../azure/notification.service';
import {
  GetUnAckNotificationsRequest,
  MessageAction,
} from '../azure/notification.service.types';
import { UserService } from '../user.service';
import { IdbService, Permissions, Stores } from './idb.service';

/**
 * @description This type is shared between CCI Push Notifications and ONE Push Notifications.
 */
export type NotificationPayload = {
  data?: {
    action?: MessageAction;
    effectiveDate: ''; // ! This property is useless and can be ignored
    isAckRequired: 'false' | 'true'; // ! Only pertains to ONE Message Notifications.
    isReadAndSign: 'false' | 'true'; // ! Only pertains to CCI Message Notifications.

    'gcm.notification.isReadAndSign': 'false' | 'true';
    messageBody: string;
    messageId: string;
    messageTitle: string;
    order: string; // ? is actually a number
    priority: string; // ? is actually a number
  };
  collapseKey: string;
  fcmOptions?: FcmOptions;
  from: string;
  messageId: string;
  notification?: FcmNotificationPayload;
};

@Injectable({
  providedIn: 'root',
})
export class FirebaseIdbService {
  notifications: WritableSignal<NotificationPayload[]> = signal([]);

  supportsNotifications = computed(() => {
    if (
      'navigator' in window &&
      'serviceWorker' in navigator &&
      'PushManager' in window
    )
      return true;
    return false;
  });

  constructor(
    private idbService: IdbService,
    private notificationsService: NotificationService,
    private userService: UserService,
  ) {
    this.loadIdbNotifications();
    setInterval(() => this.loadIdbNotifications(), 5000);
  }

  get firebaseDeviceToken(): string | null {
    return localStorage.getItem('fcm-token');
  }

  reloadUnacknowledgedONENotifications(bypassLoader = true): void {
    if (!this.firebaseDeviceToken) return;

    const payload: GetUnAckNotificationsRequest = {
      airlineCode: this.userService.airlineCode(),
      businessUnit: this.userService.emulatedOrDefaultBusinessUnit(),
      deviceToken: '',
      empIdLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      siteMinderEmpId: this.userService.employeeNumber(),
      clientId: navigator.userAgent,
      appSessionId: navigator.userAgent,
      uniqueValue: navigator.userAgent,
    };

    this.notificationsService
      .getUnAckNotifications(payload, bypassLoader)
      .subscribe((response) => {
        if (!response.success || !response.notificationArray) return;

        const notifications = response.notificationArray.map((n) => {
          const acknowledgmentRequired = n.ackRequired ? 'true' : 'false';
          const mapping: NotificationPayload = {
            from: '',
            collapseKey: '',
            messageId: n.messageId,
            data: {
              messageId: n.messageId,
              effectiveDate: '',
              isAckRequired: acknowledgmentRequired,
              isReadAndSign: 'false',
              action: response.messageAction,

              'gcm.notification.isReadAndSign': acknowledgmentRequired,
              messageBody: n.messageBody,
              messageTitle: n.title,
              order: '0',
              priority: '0',
            },
          };
          return mapping;
        });

        this.deleteNotifications().then(() =>
          this.putNotifications(notifications),
        );
      });
  }

  /**
   * @description
   * Puts notifications into the firebase table by message id.
   * Creates a new record if the notification does not exist.
   */
  putNotifications(notifications: NotificationPayload[]) {
    this.idbService.dbPromise.then(async (db) => {
      const transaction = db.transaction(Stores.Firebase, Permissions.Write);
      const store = transaction.objectStore(Stores.Firebase);

      notifications.forEach(async (n) => {
        await store.put(n, n.messageId);
      });
    });

    this.loadIdbNotifications();
  }

  /**
   * @description Loads all notifications from firebase table into the signal.
   */
  loadIdbNotifications() {
    this.getAllNotifications().then((n) => this.notifications.set(n));
  }

  /**
   * @description Finds a single notification from firebase table.
   */
  async findNotification(id: string): Promise<NotificationPayload> {
    return this.idbService.dbPromise.then(async (db) => {
      const transaction = db.transaction(Stores.Firebase, Permissions.Read);
      const store = transaction.objectStore(Stores.Firebase);
      const all: NotificationPayload = await store.get(id);
      return all;
    });
  }

  /**
   * @description Adds a single notification from firebase table. Referenced by main project and firebase-messaging-sw.js
   */
  async createNotification(payload: NotificationPayload): Promise<boolean> {
    return this.idbService.dbPromise
      .then(async (db) => {
        const transaction = db.transaction(Stores.Firebase, Permissions.Write);
        const store = transaction.objectStore(Stores.Firebase);
        await store.add(payload, payload.messageId);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  }

  /**
   * @description Deletes a single notification from firebase table.
   */
  async deleteNotification(id: string): Promise<void> {
    return this.idbService.dbPromise.then(async (db) => {
      const transaction = db.transaction(Stores.Firebase, Permissions.Write);
      const store = transaction.objectStore(Stores.Firebase);
      await store.delete(id);
    });
  }

  /**
   * @description Deletes all notifications from firebase table.
   */
  async deleteNotifications(): Promise<void> {
    return this.idbService.dbPromise.then(async (db) => {
      const transaction = db.transaction(Stores.Firebase, Permissions.Write);
      const store = transaction.objectStore(Stores.Firebase);
      await store.clear();
    });
  }

  /**
   * @description Retrieves all notifications from firebase table.
   */
  private async getAllNotifications(): Promise<NotificationPayload[]> {
    return this.idbService.dbPromise.then(async (db) => {
      const transaction = db.transaction(Stores.Firebase, Permissions.Read);
      const store = transaction.objectStore(Stores.Firebase);
      const all: NotificationPayload[] = await store.getAll();
      return all;
    });
  }
}
