import {
  computed,
  Injectable,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';
import { firstValueFrom } from 'rxjs';

import { environment } from '../../../environments/environment';
import { BidStatus, BusinessUnit } from '../types';
import { formatDate } from '../utils/date.utils';
import { CoreService } from './azure/core.service';
import {
  CoreServiceBaseRequest,
  CoreServiceWebsite,
  GetUserDetailsByBuResponse,
  UserBidStatus,
} from './azure/core.service.types';
import { ScheduleService } from './azure/schedule.service';
import {
  BidStatuses,
  GetDashboardInfoRequest,
} from './azure/schedule.service.types';
import {
  ActionType,
  EmulationKey,
  IdbService,
  Permissions,
  Stores,
} from './idb/idb.service';
import { TokenService } from './token.service';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private coreService: CoreService,
    private tokenService: TokenService,
    private scheduleService: ScheduleService,
    private pingAuthService: PingAuthenticationService,
    private idbService: IdbService,
  ) {
    this.loadEmulationState();
  }

  isLoading = signal(true);

  airlineCode: Signal<string> = computed(
    () => this.tokenService.tokens()?.accessToken.Organization ?? '',
  );

  firstName: Signal<string> = computed(
    () => this.tokenService.tokens()?.accessToken.first_name ?? '',
  );

  lastName: Signal<string> = computed(
    () => this.tokenService.tokens()?.accessToken.last_name ?? '',
  );

  employeeNumber: Signal<number> = computed(() =>
    Number.parseInt(this.tokenService.tokens()?.accessToken.uid ?? ''),
  );

  employeeBusinessUnit: WritableSignal<BusinessUnit | undefined> =
    signal(undefined);
  isEmulationEnabled: Signal<boolean> = computed(
    () => !!this.emulatedBusinessUnit(),
  );

  isLoggedIn: Signal<boolean> = computed(
    () => !!this.tokenService.tokens()?.accessToken,
  );

  bidStatus: WritableSignal<UserBidStatus | undefined> = signal(undefined);

  apiDetails: WritableSignal<
    | {
        businessUnit: BusinessUnit;
        isAdmin: boolean;
        isActive: boolean;
        emulatedFirstName: string;
        emulatedLastName: string;
        websites: CoreServiceWebsite[];
        mailBox: string;
      }
    | undefined
  > = signal(undefined);

  emulatedEmployeeNumber: WritableSignal<number | undefined> =
    signal(undefined);

  emulatedBusinessUnit: WritableSignal<BusinessUnit | undefined> =
    signal(undefined);

  emulatedAirlineCode: WritableSignal<string | undefined> = signal(undefined);

  isAAFAMQFA = computed(() => {
    return (
      this.apiDetails()?.businessUnit === BusinessUnit.AAFA ||
      this.apiDetails()?.businessUnit === BusinessUnit.MQFA
    );
  });

  isAAPIMQPI = computed(() => {
    return (
      this.apiDetails()?.businessUnit === BusinessUnit.AAPI ||
      this.apiDetails()?.businessUnit === BusinessUnit.MQPI
    );
  });

  paddedEmployeeNumber = computed(() => {
    let employeeNumber = this.employeeNumber().toString();
    if (employeeNumber && employeeNumber.length !== 6) {
      employeeNumber = employeeNumber.toString().padStart(6, '0');
    }
    return employeeNumber;
  });

  emulatedOrDefaultEmployeeNumber = computed(
    () => this.emulatedEmployeeNumber() ?? this.employeeNumber(),
  );

  emulatedOrDefaultBusinessUnit = computed(() =>
    this.isEmulationEnabled()
      ? this.emulatedBusinessUnit()
      : this.apiDetails()?.businessUnit,
  );

  emulatedOrDefaultAirlineCode = computed(
    () => this.emulatedAirlineCode() ?? this.airlineCode(),
  );

  // {versionNumber}-{empId}-{emulatedEmpId}-{useragent}
  appSession = computed(
    () =>
      `${environment.version}-${this.employeeNumber()}-${
        this.emulatedEmployeeNumber() ?? 'NotEmulating'
      }-${navigator.userAgent}`,
  );

  isEmployeeCheckAirman = computed(
    () => this.bidStatus()?.checkAirman || this.bidStatus()?.inactiveSupervisor,
  );

  /**
   * Set four part Bit status
   * It is used in search, overview, message pages
   * @returns
   */
  async setFourPartBitStatus(
    employeeLogin: number,
    airlineCode: string,
    businessUnit: BusinessUnit,
  ): Promise<void> {
    const bidStatusPayload: GetDashboardInfoRequest = {
      localDate: formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss'),
      siteMinderEmployeeId: this.employeeNumber(),
      employeeLogin,
      airlineCode,
      businessUnit,
      appSessionId: this.appSession(),
    };
    const bidStatusResult = await firstValueFrom(
      this.scheduleService.getDashBoardInfo(bidStatusPayload),
    );

    if (bidStatusResult.success && bidStatusResult.dashBoardResponse) {
      const bidStatus = bidStatusResult?.dashBoardResponse?.bidStatuses?.find(
        (bitStatus: BidStatuses) =>
          bitStatus.contractMonthType === BidStatus.CONTRACT,
      );
      const departureStation =
        bidStatusResult?.dashBoardResponse?.departureStations;
      if (bidStatus) {
        this.bidStatus.set({
          contractMonth: bidStatus.contractMonth,
          crewBase: bidStatus.currentBase,
          crewDivision: bidStatus.currentDivision,
          crewEquipment: bidStatus.currentEquipment,
          crewSeat: bidStatus.seatCategory,
          checkAirman: bidStatus.checkAirman,
          fsmSupervisorNumber: bidStatus?.fsmSupervisorNumber ?? '',
          checkAirmanType:
            bidStatus.checkAirmanCurrentMonthStatus?.checkAirmanType,
          depatureStations: departureStation,
          inactiveSupervisor: bidStatus.inactiveSupervisor,
        });
      }
    } else {
      this.isLoading.set(false);
      // console.error('Failed to load bid status', bidStatusResult);
      return;
    }
  }

  /**
   * On emulate get the emulated user details
   * @returns
   */
  async setApiDetails(isEmulated = false): Promise<void> {
    const payload: CoreServiceBaseRequest = {
      airlineCode: this.airlineCode(),
      appSessionId: this.appSession(),
      empIdLogin: this.emulatedOrDefaultEmployeeNumber(),
      siteMinderEmpId: this.employeeNumber(),
    };
    const userDetailsResult = await firstValueFrom(
      this.coreService.getUserDetailsByBu(payload),
    );
    if (!userDetailsResult || !userDetailsResult.success) {
      this.isLoading.set(false);
      console.error('Failed to load user details', userDetailsResult);
      return;
    } else {
      this.emulatedAirlineCode.set(userDetailsResult.businessUnit.airlineCode);
      this.apiDetails.set({
        businessUnit: userDetailsResult.businessUnit.bu as BusinessUnit,
        isActive: userDetailsResult.businessUnit.active,
        isAdmin: userDetailsResult.businessUnit.admin,
        emulatedFirstName: userDetailsResult.firstName,
        emulatedLastName: userDetailsResult.lastName,
        websites: userDetailsResult.websites,
        mailBox: userDetailsResult.businessUnit.mailBox,
      });

      if (isEmulated) {
        await this.storeEmulatedData(userDetailsResult);
      } else {
        this.employeeBusinessUnit.set(
          userDetailsResult.businessUnit.bu as BusinessUnit,
        );
      }
      const employeeLogin = this.emulatedOrDefaultEmployeeNumber();
      const airlineCode = this.emulatedOrDefaultAirlineCode();
      const businessUnit = this.emulatedOrDefaultBusinessUnit()!;
      await this.setFourPartBitStatus(employeeLogin, airlineCode, businessUnit);
    }
  }

  async storeEmulatedData(
    buDetails: GetUserDetailsByBuResponse,
  ): Promise<void> {
    const emulationState: EmulationState = {
      employeeId: buDetails.employeeID ?? '',
      businessUnit: buDetails.businessUnit.bu ?? '',
      emulatedFirstName: buDetails.firstName ?? '',
      emulatedLastName: buDetails.lastName ?? '',
      websites: buDetails?.websites ?? ([] as CoreServiceWebsite[]),
    };
    await this.emulationState(ActionType.Put, emulationState);
  }

  async load(isEmulated = false): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        this.isLoading.set(true);
        const loggedIn = await firstValueFrom(this.pingAuthService.loggedIn$);
        if (!loggedIn) {
          this.isLoading.set(false);
          reject();
          return;
        }
        this.tokenService.load();
        await this.setApiDetails(isEmulated);
        this.isLoading.set(false);
        resolve();
      } catch (error) {
        this.isLoading.set(false);
        reject(error);
      }
    });
  }

  async clearEmulation(): Promise<void> {
    this.emulatedBusinessUnit.set(undefined);
    this.emulatedEmployeeNumber.set(undefined);
    await this.emulationState(ActionType.Delete);
    this.load();
  }

  emulate(employeeId: number, businessUnit: BusinessUnit): void {
    this.emulatedBusinessUnit.set(businessUnit);
    this.emulatedEmployeeNumber.set(employeeId);
    this.load(true);
  }

  /**
   * Update/Delete/Get - Emulation data in the store
   * @param action - Delete/Get/Put
   * @param emulationData - {} as EmulationState
   */
  async emulationState(
    action: ActionType,
    emulationData?: EmulationState,
  ): Promise<void> {
    try {
      const db = await this.idbService.dbPromise;
      const transaction = db.transaction(Stores.Emulation, Permissions.Write);
      const store = transaction.objectStore(Stores.Emulation);

      switch (action) {
        case ActionType.Put:
          await store.put(emulationData, EmulationKey);
          return;
        case ActionType.Delete:
          await store.delete(EmulationKey);
          return;
        case ActionType.Get:
          const result = await store.get(EmulationKey);
          this.getEmulatedStateData(result);
          return;
      }
    } catch (error) {
      console.error('Emulation error');
      console.error(error);
    }
  }

  getEmulatedStateData(emulatedData: EmulationState): void {
    if (emulatedData) {
      this.apiDetails.set({
        businessUnit: emulatedData.businessUnit as BusinessUnit,
        isAdmin: false,
        isActive: false,
        emulatedFirstName: emulatedData.emulatedFirstName ?? '',
        emulatedLastName: emulatedData.emulatedLastName ?? '',
        websites: emulatedData.websites as CoreServiceWebsite[],
        mailBox: '',
      });
      this.emulate(
        Number(emulatedData.employeeId),
        emulatedData.businessUnit as BusinessUnit,
      );
    }
  }

  async loadEmulationState(): Promise<void> {
    await this.emulationState(ActionType.Get);
  }

  logout(): void {
    this.clearEmulation();
    this.tokenService.logout();
  }
}

type EmulationState = {
  employeeId: number;
  businessUnit: BusinessUnit;
  emulatedFirstName: string;
  emulatedLastName: string;
  websites: CoreServiceWebsite[];
};
