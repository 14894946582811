import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpService } from '../http.service';
import {
  GetUnAckNotificationsRequest,
  GetUnAckNotificationsResponse,
  SaveNotificationAckRequest,
  SaveNotificationAckResponse,
} from './notification.service.types';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private httpService: HttpService) {}

  private readonly base = `${environment.azure.services.url}/notifications`;

  getUnAckNotifications(
    body: GetUnAckNotificationsRequest,
    bypassLoader = false,
  ) {
    const url = `${this.base}/getUnAckNotifications`;
    return this.httpService.post<
      GetUnAckNotificationsRequest,
      GetUnAckNotificationsResponse
    >(url, body, bypassLoader);
  }

  saveNotificationAck(body: SaveNotificationAckRequest, bypassLoader = false) {
    const url = `${this.base}/saveNotificationAck`;
    return this.httpService.post<
      SaveNotificationAckRequest,
      SaveNotificationAckResponse
    >(url, body, bypassLoader);
  }

  // ! TESTING ONLY
  // sendNotification(body: SendNotificationRequest, bypassLoader = false) {
  //   const url = `${this.base}/sendPushNotifications`;
  //   return this.httpService.put<
  //     SendNotificationRequest,
  //     SendNotificationResponse
  //   >(url, body, bypassLoader);
  // }
}

export type SendNotificationRequest = {
  ackRequired: boolean;
  airlineCode: string;
  effectiveDate: string;
  empId: string;
  messageContents: string;
  messageId: string;
  messageType: string;
  priority: number;
};

export type SendNotificationResponse = object;
