<div class="flex h-full flex-col">
  <div class="grid grid-cols-7">
    <div
      class="bg-white text-center text-xs font-normal text-carbon dark:bg-charcoal dark:text-white">
      S
    </div>
    <div
      class="bg-white text-center text-xs font-normal text-carbon dark:bg-charcoal dark:text-white">
      M
    </div>
    <div
      class="bg-white text-center text-xs font-normal text-carbon dark:bg-charcoal dark:text-white">
      T
    </div>
    <div
      class="bg-white text-center text-xs font-normal text-carbon dark:bg-charcoal dark:text-white">
      W
    </div>
    <div
      class="bg-white text-center text-xs font-normal text-carbon dark:bg-charcoal dark:text-white">
      T
    </div>
    <div
      class="bg-white text-center text-xs font-normal text-carbon dark:bg-charcoal dark:text-white">
      F
    </div>
    <div
      class="bg-white text-center text-xs font-normal text-carbon dark:bg-charcoal dark:text-white">
      S
    </div>
  </div>
  <div
    class="flex h-full flex-col border border-turbine dark:border-steel xs:h-[85%]">
    <div
      class="grid h-full grid-cols-7 gap-px border-turbine bg-turbine dark:border-steel dark:bg-steel">
      <ng-container *ngFor="let day of daysInMonth; let i = index">
        <div
          (click)="onSelectDay($event, day)"
          class="flex h-full flex-1 flex-col items-center text-center"
          [ngClass]="showDayStyle(day)">
          <div class="w-full p-1">
            <p class="h-[14px]" [ngClass]="showDayTextStyle(day)"
              >{{ day.getDate() }}
            </p>
          </div>
          <div class="relative h-full w-full">
            <ng-container *ngIf="getEventsForDay(i).length > 0">
              <div
                *ngFor="
                  let event of getEventsForDay(i);
                  let eventsForDayLast = last
                ">
                <ng-container *ngIf="event.showEvent">
                  <div
                    *ngIf="checkCurrentDay(event, day)"
                    [ngClass]="drawEventLine(event, day)"
                    class="flex flex-row justify-center pb-1">
                    <div
                      [ngClass]="[
                        setCalendarStyle(event, day),
                        setEventLine(event, day),
                        setEventTitleStyle(event)
                      ]"
                      class="flex h-[16px] w-full cursor-pointer flex-col justify-end text-left text-[11px] font-bold leading-[14px] gt-md:h-[18px] gt-md:leading-[16px]">
                      <div class="flex justify-between">
                        <span
                          *ngIf="showEventTitle(event, day) === '<'"
                          class="calendar-icon"
                          ><crew-ui-icon leading="[14px]" weight="bold" size="[17px]" name="chevron_left"></crew-ui-icon
                        ></span>
                        <span
                          class="truncate pl-1"
                          *ngIf="showEventTitle(event, day) !== '<'"
                          >{{ showEventTitle(event, day) }}</span
                        >
                        <span
                          *ngIf="showEventDutyPeriodValue(event, day) === '>'"
                          class="calendar-icon"
                          ><crew-ui-icon leading="[14px]" weight="bold" size="[17px]" name="chevron_right"></crew-ui-icon
                        ></span>
                        <span
                          *ngIf="showEventDutyPeriodValue(event, day) !== '>'"
                          >{{ showEventDutyPeriodValue(event, day) }}
                        </span>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="
                    event.showEvent &&
                    event.sequenceActivity &&
                    event.sequenceActivity.flightDutyPeriods &&
                    event.sequenceActivity.flightDutyPeriods.length > 0
                  ">
                  <div
                    class="absolute bottom-0 flex w-full justify-between p-1 text-[11px] font-bold leading-[14px] text-carbon dark:text-white">
                    <ng-container
                      *ngIf="getEventsForDay(i).length > 1 && eventsForDayLast">
                      <span class="flex flex-wrap">{{
                        getNumberOfLegsHasMoreThanOneSequence(i)
                      }}</span>
                      <span class="flex flex-wrap truncate">
                        {{ getLayOverAirportHasMoreThanOneSequence(i, day) }}
                      </span>
                    </ng-container>
                    <ng-container *ngIf="getEventsForDay(i).length === 1">
                      <span class="flex flex-wrap">{{
                        event.numberOfLegs !== 0 ? event.numberOfLegs : ''
                      }}</span>
                      <span class="flex flex-wrap truncate">{{
                        getLayOverAirport(
                          event.sequenceActivity.flightDutyPeriods,
                          event.end,
                          day,
                          event.beginsToday
                        )
                      }}</span>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
