import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import {
  Colors,
  CrewUIDarkModeService,
  CrewUIIconComponent,
} from '../../../shared/components/crew-ui';
import { SettingsButtonComponent } from '../../../shared/components/settings-button/settings-button.component';
import { MessagePriority } from '../../../shared/services/azure/message.service.types';
import { HttpService } from '../../../shared/services/http.service';
import { MessageIdbService } from '../../../shared/services/idb/message.idb.service';
import { UserService } from '../../../shared/services/user.service';
import { BusinessUnit } from '../../../shared/types';
import { MessageService, MessageTitles } from '../message.service';
import { MessageMenuListItemComponent } from '../message-menu-list-item/message-menu-list-item.component';

@Component({
  selector: 'crew-web-message-menu',
  templateUrl: './message-menu.component.html',
  styleUrls: ['./message-menu.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    SettingsButtonComponent,
    CrewUIIconComponent,
    MessageMenuListItemComponent,
  ],
})
export class MessageMenuComponent implements OnInit {
  constructor(
    private darkModeService: CrewUIDarkModeService,
    private messageService: MessageService,
    private userService: UserService,
    private httpService: HttpService,
    private messageIdbService: MessageIdbService,
  ) {}

  businessUnits = BusinessUnit;
  colors = Colors;
  messagePriority = MessagePriority;
  messageTitles = MessageTitles;

  get businessUnit(): BusinessUnit | undefined {
    return this.userService.apiDetails()?.businessUnit;
  }

  get selectedPriority(): MessagePriority | undefined {
    return this.messageService.selectedMenu();
  }

  get isDark(): boolean {
    return this.darkModeService.isDarkMode();
  }

  get hi6Count(): number {
    return this.messageService.hi6Count();
  }

  get lastRefreshTime(): string {
    return this.messageService.lastRefreshTimeFormatted();
  }

  ngOnInit(): void {
    this.refreshMessage();
  }

  refreshMessage(): void {
    this.messageIdbService.refreshMessages();
  }

  messageCount(priority: MessagePriority): number {
    return this.messageService.getUnreadCount(priority) || 0;
  }
}
