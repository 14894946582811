import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml',
  pure: false,
  standalone: true,
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  transform(value?: string): SafeHtml | void {
    if (!value) return;
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
