import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { CalendarEventTitlePipe } from '../../../../shared/pipes/calendarEventTitle.pipe';
import { TimeService } from '../../../../shared/services/time.service';
import { getTodayStartEndDateTimeInBetweenSequenceDates } from '../../../../shared/utils/date.utils';
import { SequenceInfo } from '../../calendar.types';

@Component({
  selector: 'crew-web-card-non-sequence-info',
  templateUrl: './card-non-sequence-info.component.html',
  styleUrls: ['./card-non-sequence-info.component.css'],
  standalone: true,
  imports: [CommonModule, CalendarEventTitlePipe],
})
export class CardNonSequenceInfoComponent {
  @Input() day = '' as string;

  @Input() sequenceInfo = {} as SequenceInfo;

  constructor(private timeService: TimeService) {}

  displaySequenceTime(startDate: string, endDate: string, day: string): string {
    const { startDateTime, endDateTime } =
      getTodayStartEndDateTimeInBetweenSequenceDates(startDate, endDate, day);
    return `${this.timeService.getTime(
      startDateTime,
    )} - ${this.timeService.getTime(endDateTime)}`;
  }
}
