<div *ngIf="isVisible" class="fixed left-0 top-0 z-50 h-full w-full">
  <crew-ui-modal *ngIf="isEmulationModalVisible">
    <div class="rounded-xl bg-white dark:bg-charcoal dark:text-white">
      <button
        class="relative left-4 top-4 mb-4 text-stratosphere dark:text-troposphere"
        (click)="toggleEmulationModalVisibility()">
        Back
      </button>
      <div class="flex w-full flex-col px-6">
        <span class="mt-2">Employee number</span>
        <crew-ui-input
          ngDefaultControl
          class="my-2 ml-2 mr-3 border-none dark:bg-charcoal"
          type="number"
          [(ngModel)]="inputEmployeeNumber"
          (valueChange)="setSelectedEmployeeNumber($event)" />
        <span class="mt-2">Business unit</span>
        <crew-ui-select
          ngDefaultControl
          class="my-2 ml-2 mr-4"
          [(ngModel)]="inputBusinessUnit"
          (selectedOptionChange)="setSelectedBusinessUnit($event)"
          [options]="businessUnitOptions" />
      </div>
      <div class="flex items-center justify-center">
        <crew-ui-button
          [disabled]="emulateButtonDisabled"
          class="my-4"
          (click)="emulate()">
          Apply
        </crew-ui-button>
      </div>
    </div>
  </crew-ui-modal>
  <crew-ui-overlay *ngIf="!isEmulationModalVisible">
    <crew-ui-card class="xs:h-full">
      <div class="-mb-2 rounded-xl bg-white dark:bg-charcoal dark:text-white">
        <div
          class="lt-sm:w-100 h-full w-screen gt-xs:w-128"
          *ngIf="!isTnCModal">
          <div
            class="overflow-auto rounded-t-xl bg-white shadow dark:bg-charcoal">
            <button
              class="relative left-4 top-4 text-stratosphere dark:text-troposphere"
              (click)="toggleVisibility()">
              Close
            </button>
            <div class="m-2 mt-4 flex items-center justify-between p-2">
              <crew-ui-icon [color]="colors.Nickel" name="account_circle" />
              <div class="top-10 ml-3 mr-3">
                <h2 class="text-xs text-aluminum">
                  {{ $userService.employeeNumber() }}
                  <span> / </span>
                  {{ $userService.employeeBusinessUnit() }}
                </h2>
                <p class="font-semibold">
                  {{ $userService.firstName().toUpperCase() }}
                  {{ $userService.lastName().toUpperCase() }}
                </p>
              </div>
              <button
                class="ml-auto text-stratosphere dark:text-troposphere"
                (click)="logout()"
                >Log Out</button
              >
            </div>
          </div>
          <div
            *ngIf="
              $userService.apiDetails()?.isAdmin ||
              $userService.isEmulationEnabled()
            "
            class="m-2 mt-3 flex items-center justify-between rounded-md bg-white p-2 shadow-sm dark:bg-charcoal dark:shadow-none">
            <div class="top-10 ml-3" *ngIf="!$userService.isEmulationEnabled()">
              <button
                class="text-stratosphere dark:text-troposphere"
                (click)="toggleEmulationModalVisibility()">
                Emulate user
              </button>
            </div>
            <div class="top-10 ml-3" *ngIf="$userService.isEmulationEnabled()">
              <h2 class="text-xs text-aluminum">
                {{ $userService.emulatedEmployeeNumber() }}
                <span> / </span>
                {{ $userService.emulatedBusinessUnit() }}
              </h2>
              <p class="font-semibold">
                {{
                  $userService.apiDetails()?.emulatedFirstName?.toUpperCase()
                }}
                {{ $userService.apiDetails()?.emulatedLastName?.toUpperCase() }}
              </p>
              <button
                class="text-stratosphere dark:text-troposphere"
                (click)="clearEmulation()">
                Stop emulating
              </button>
            </div>
          </div>
          <div
            class="m-2 mt-3 rounded-md bg-white p-4 pt-2 shadow-sm dark:bg-charcoal dark:shadow-none">
            <div class="flex h-12 items-center">
              <crew-ui-icon name="dark_mode" />
              <p class="ml-2">Dark mode</p>
              <div class="ml-auto mt-4">
                <crew-ui-switch
                  (selected)="toggleDarkMode($event)"
                  [checked]="isDarkMode"></crew-ui-switch>
              </div>
            </div>
            <div class="flex h-12 items-center">
              <crew-ui-icon name="schedule" />
              <p class="ml-2">24-hour time</p>
              <div class="ml-auto mt-4">
                <crew-ui-switch
                  (selected)="toggleTimeFormat24($event)"
                  [checked]="isTimeFormat24"></crew-ui-switch>
              </div>
            </div>
            <div class="flex h-12 items-center" *ngIf="supportsNotifications">
              <crew-ui-icon name="notifications" />
              <p class="ml-2">Notifications</p>
              <div class="ml-auto mt-4">
                <crew-ui-switch
                  (selected)="allowNotifications()"
                  [checked]="notificationsAllowed"
                  [disabled]="notificationsAllowed" />
              </div>
            </div>
            <p *ngIf="notificationsAllowed" class="text-center text-steel">
              Reset site permissions to disable notifications.
            </p>
          </div>
          <div
            class="m-2 mt-3 rounded-md bg-white p-4 pt-2 shadow-sm dark:bg-charcoal dark:shadow-none">
            <div class="flex items-center justify-between">
              <div>
                <p class="font-medium">Trip sign-in opt-in</p>
                <p class="text-xs">{{ tncOptInTime }}</p>
              </div>
              <div class="ml-auto mt-4">
                <crew-ui-switch
                  [checked]="tncSwitchState"
                  (selected)="toggleTnCFromSwitch($event)"></crew-ui-switch>
              </div>
            </div>
            <button
              class="text-stratosphere dark:text-troposphere"
              (click)="toggleTnCModal()"
              >Terms and conditions</button
            >
          </div>
          <div
            class="mx-2 my-4 rounded-md bg-white p-4 pt-2 shadow-sm dark:bg-charcoal dark:shadow-none">
            <div
              class="mt-4 flex cursor-pointer items-center"
              (click)="goToInstallationGuide()">
              <div class="mt-4 flex items-center">
                <crew-ui-icon name="find_in_page" />
                <p class="ml-2">Installation Guide</p>
              </div>
            </div>
            <div class="mt-4 flex items-center">
              <crew-ui-icon name="article" />
              <p class="ml-2">Version {{ version }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- TnC Modal for business units AAPI & MQPI -->
      <div
        class="lt-sm:w-100 h-192 rounded-xl bg-cirrus dark:bg-charcoal lt-sm:h-128 gt-xs:w-128"
        *ngIf="isTnCModal">
        <div
          class="rounded-xl border-b bg-white p-4 dark:border-nickel dark:bg-charcoal dark:text-white"
          *ngIf="isAAPIandMQPIModal">
          <div class="flex">
            <button
              class="text-stratosphere dark:text-troposphere"
              (click)="toggleTnCModal()">
              Close
            </button>
            <p class="mx-auto font-semibold">Terms and conditions</p>
            <button
              *ngIf="showAcceptTnC"
              class="text-stratosphere dark:text-troposphere"
              (click)="acceptTnC()">
              Accept
            </button>
          </div>
          <p class="mt-4 font-semibold">Trip sign-in opt-in</p>
          <ul class="ml-4 mt-4 list-disc">
            <li
              >This access does not change the requirement to sign in for my
              trip on time.</li
            >
            <li
              >I may only sign in for my trip when I'm at my departure
              airport.</li
            >
            <li
              >I must activate location services on my device so the application
              can determine I'm at the airport.</li
            >
            <li
              >If CCI fails to sign me in for my trip, I will use a computer at
              the airport to sign in.</li
            >
            <li
              >I understand that signing in for my trip will confirm me Fit For
              Duty for the first leg of my sequence.
            </li>
          </ul>
        </div>

        <!-- TnC Modal for business units AAFA & MQFA  -->
        <div
          class="rounded-xl border-b bg-white p-4 dark:border-nickel dark:bg-charcoal dark:text-white"
          *ngIf="isAAFAandMQFAModal">
          <div class="flex">
            <button
              class="text-stratosphere dark:text-troposphere"
              (click)="toggleTnCModal()">
              Close
            </button>
            <p class="mx-auto font-semibold">Terms and conditions</p>
            <button
              *ngIf="showAcceptTnC"
              class="text-stratosphere dark:text-troposphere"
              (click)="acceptTnC()">
              Accept
            </button>
          </div>
          <p class="mt-4 font-semibold">Trip sign-in opt-in</p>
          <ul class="ml-4 mt-4 list-disc">
            <li
              >This access does not change the requirement to sign in for my
              trip on time.</li
            >
            <li
              >I may only sign in for my trip when I'm at my departure
              airport.</li
            >
            <li
              >I must activate location services on my device so the application
              can determine I'm at the airport.</li
            >
            <li
              >If CCI fails to sign me in for my trip, I will use a computer at
              the airport to sign in.</li
            >
          </ul>
        </div>
      </div>
    </crew-ui-card>
  </crew-ui-overlay>
</div>
