import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

export type CrewUISelectOption = {
  value: string;
  text: string;
};

@Component({
  selector: 'crew-ui-select',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './crew-ui-select.component.html',
  styleUrls: ['./crew-ui-select.component.css'],
})
export class CrewUISelectComponent {
  @Input()
  assistiveLabel?: string;

  @Input()
  placeholder = 'Select a option...';

  @Input()
  label?: string;

  @Input()
  options: CrewUISelectOption[] = [];

  @Input()
  selectedOption: CrewUISelectOption[] = [];

  @Output()
  selectedOptionChange = new EventEmitter<CrewUISelectOption>();

  @Input()
  isFocused?: boolean;

  @Output()
  isFocusedChange = new EventEmitter<boolean>();

  @Input()
  isInvalid = false;
}
