<span
  class="inline-flex items-center text-[16px] font-medium leading-[21px] dark:text-white">
  {{ showFlightNumber(flightInfo) }}
</span>
<span
  *ngIf="showAirlineCodeStyle(flightInfo, 'style')"
  [ngClass]="[showAirlineCodeStyle(flightInfo, 'style')]"
  class="ml-2 inline-flex items-center rounded border border-solid px-1 py-0.5 align-top text-[12px] font-medium leading-[16px]">
  {{ showAirlineCodeStyle(flightInfo, 'title') }}
</span>
