<div class="overflow-hidden xs:h-[93%] gt-xs:h-[92%] gt-sm:h-full">
  <div
    *ngIf="!hasSabreResponse"
    class="flex h-full w-full lt-sm:flex-col gt-sm:flex-row">
    <div
      class="relative flex h-full flex-col rounded bg-cirrus px-0 dark:bg-black xs:w-full sm:w-[62%] sm:pl-1 md:w-[64%] gt-md:w-[70%]">
      <!-- Calendar menu -->
      <crew-web-calendar-menu />
      <!-- Calendar UI -->
      <crew-web-calendar-info class="h-full" />
    </div>
    <div
      class="flex flex-col bg-cirrus dark:bg-black xs:w-full sm:w-[38%] sm:px-0 md:w-[36%] gt-md:w-[30%] gt-md:pr-0">
      <!-- Sequence info -->
      <crew-web-calendar-event-details />
    </div>
  </div>
  <crew-web-sabre-document *ngIf="hasSabreResponse" />
</div>
