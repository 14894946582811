<section
  *ngIf="flightInfo && (flightInfo | json) !== '{}'"
  [ngClass]="[eventColor.borderColor]"
  class="card-shadow w-full justify-between rounded-l rounded-r-xl border-l-4 bg-white px-2 pb-3 pt-3 text-carbon dark:bg-carbon dark:text-white">
  <div class="flex items-center pb-1 pt-1">
    <crew-web-card-text-highlight [flightInfo]="flightInfo" />
  </div>
  <div class="flex items-center pb-1 pt-1">
    <span class="text-[16px] font-medium leading-[21px]">{{
      flightInfo.departureStation
    }}</span>
    <span class="px-1">&#183;</span>
    <span
      class="text-[16px] font-normal leading-[21px]"
      [ngClass]="{
        'line-through': validateActualRescheduledDepartureTime(flightInfo)
      }"
      >{{ getTime(flightInfo.scheduled.departureDateTime.localTime) }}</span
    >
    <span
      *ngIf="validateActualRescheduledDepartureTime(flightInfo)"
      [ngClass]="[getDepartureColor(flightInfo)]"
      class="pl-1 text-[16px] font-normal leading-[21px]"
      >{{ showDepartureTime(flightInfo) }}</span
    >
  </div>
  <div class="flex items-center">
    <div class="flex-grow border-t border-cirrus dark:border-charcoal"></div>
    <span
      class="mx-2 flex-shrink items-center border-cirrus text-[13px] font-normal leading-[18px]"
      >{{ flightInfo.blockTime | flightTime : 'flight time' }}</span
    >
  </div>
  <div class="flex items-center pb-1 pt-1">
    <span class="text-[16px] font-medium leading-[21px]">{{
      flightInfo.arrivalStation
    }}</span>
    <span class="px-1">&#183;</span>
    <span
      class="text-[16px] font-normal leading-[21px]"
      [ngClass]="{
        'line-through': validateActualRescheduledArrivalTime(flightInfo)
      }"
      >{{ getTime(flightInfo.scheduled.arrivalDateTime.localTime) }}</span
    >
    <span
      *ngIf="validateActualRescheduledArrivalTime(flightInfo)"
      [ngClass]="[getArrivalColor(flightInfo)]"
      class="pl-1 text-[16px] font-normal leading-[21px]"
      >{{ showArrivalTime(flightInfo) }}</span
    >
  </div>
  <div *ngIf="flightInfo.groundTime > 0" class="flex items-center">
    <div class="flex-grow border-t border-cirrus dark:border-charcoal"></div>
    <span
      class="mx-2 flex-shrink items-center border-cirrus text-[13px] font-normal leading-[18px] dark:border-charcoal"
      >{{ flightInfo.groundTime | flightTime : 'ground time' }}</span
    >
  </div>
</section>
