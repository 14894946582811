import { Component } from '@angular/core';
import { Messaging, onMessage } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';

import { environment } from '../environments/environment';
import { CREW_ROUTES } from './app-routing.module';
import { MessageService } from './routes/message/message.service';
import { SettingsModalService } from './shared/components/settings-modal/settings-modal.service';
import { InsightsService } from './shared/services/azure/insights.service';
import { HttpService } from './shared/services/http.service';
import {
  FirebaseIdbService,
  NotificationPayload,
} from './shared/services/idb/firebase.idb.service';
import { MessageIdbService } from './shared/services/idb/message.idb.service';
import { TokenService } from './shared/services/token.service';
import { UserService } from './shared/services/user.service';

// Defining these in a global scope so they are singleton.
// Otherwise they will be redefined every time the component is initialized.
// This would cause the app to send out way too many requests.
// ? maybe in the future this could be moved to an interval service ?

// This interval ensures the user is navigated to the RA page when a RA message comes in.
let readAckMessageRefreshInterval: number | undefined = undefined;

// This interval ensures unacknowledged ONE notifications are loaded periodically.
let unAckRefreshInterval: number | undefined = undefined;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  constructor(
    private httpService: HttpService,
    private insights: InsightsService,
    private messaging: Messaging,
    private tokenService: TokenService,
    private firebaseIdbService: FirebaseIdbService,
    private messageIdbService: MessageIdbService,
    private messageService: MessageService,
    private router: Router,
    private settingsModalService: SettingsModalService,
    private userService: UserService,
    private pingAuthService: PingAuthenticationService,
  ) {
    // ! DYNATRACE NOT WORKING
    // if (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
    //   dT_.initAngularNg(http, HttpHeaders);
    //   // console.log('Dynatrace Angular Monitoring initialized');
    // } else console.error('Dynatrace Angular Monitoring not initialized');

    this.pingAuthService.identity$.subscribe(() => this.tokenService.load());
    this.pingAuthService.OnSignInComplete.subscribe(() =>
      this.tokenService.load(),
    );

    if (this.firebaseIdbService.supportsNotifications()) {
      onMessage(this.messaging, async (payload) => {
        // We do not want to store silent notification or read and acknowledge notifications
        const messageId = payload.data?.['messageId'];
        const isReadAndSign = payload.data?.['isReadAndSign'];
        if (!messageId || isReadAndSign === 'true') return;
        await this.messageIdbService.refreshMessages();
        await this.firebaseIdbService.createNotification(
          payload as NotificationPayload,
        );
      });
    }

    // effectively this prevents someone from being stuck at the auth callback page
    // this, for example, could happen while navigating backwards
    // on the initial load, keys aren't populated yet which prevents this from running
    if (!window.location.href.includes(CREW_ROUTES.authCallback)) return;
    const keys = this.tokenService.getLocalStorageKeyStartingWith('oidc.user');
    if (keys === null) return;
    setTimeout(() => {
      // we just want to capture how often this is occurring
      insights.trackTrace({
        message: `Timeout trigger at auth callback.`,
      });
      window.location.href = CREW_ROUTES.overview;
    }, 1000);
  }

  isSettingsModalVisible = false;

  get routerUrl(): string {
    return this.router.url;
  }

  get isUserServiceLoading(): boolean {
    return this.userService.isLoading();
  }

  get isLoading(): boolean {
    return this.httpService.isLoading();
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = (): boolean => false;

    if (this.userService.apiDetails()) {
      // It is necessary to refresh messages on page load to ensure RA message page redirection happens.
      this.messageIdbService
        .refreshMessages(true)
        .then(() => this.readAckMessageRefreshAction());

      this.setRefreshIntervals();
    } else {
      this.userService
        .load()
        .then(() => {
          this.messageIdbService
            .refreshMessages(true)
            .then(() => this.readAckMessageRefreshAction());

          this.setRefreshIntervals();
        })
        .catch(() => {
          // TODO: The auth flow isn't particularly clean, this is a workaround to ensure the user details are loaded.
          setTimeout(() => this.ngOnInit(), 1000);
        });
    }
  }

  setRefreshIntervals(): void {
    if (readAckMessageRefreshInterval && unAckRefreshInterval) return;

    readAckMessageRefreshInterval = window.setInterval(
      () => this.readAckMessageRefreshAction(),
      environment.azure.services.readAndAckMessageRefreshIntervalMs,
    );

    unAckRefreshInterval = window.setInterval(
      () => this.unAckNotificationRefreshAction(),
      environment.azure.services.unAckRefreshIntervalMs,
    );
  }

  /**
   *  Logic for : Don't show RNA messages for the emulated user.
   *  Code used : this.userService.employeeNumber() ===
        this.userService.emulatedOrDefaultEmployeeNumber()
   */
  readAckMessageRefreshAction(): void {
    const count =
      this.messageService.readAcknowledgePriorityUnreadMessagesCount();
    if (
      count &&
      count > 0 &&
      this.userService.employeeNumber() ===
        this.userService.emulatedOrDefaultEmployeeNumber()
    ) {
      this.router.navigate(['/read-and-acknowledge']);
    }
  }

  unAckNotificationRefreshAction(): void {
    this.firebaseIdbService.reloadUnacknowledgedONENotifications();
  }

  getCrewPath(path: string): string {
    return path ? `/${path}` : `/`;
  }

  isActiveRoute(path = ''): boolean {
    return this.routerUrl.includes(path);
  }

  showSettingsModal(): void {
    this.settingsModalService.isVisible();
  }
}
