<crew-ui-card>
  <div class="my-2 flex">
    <div class="relative min-h-full">
      <div
        class="absolute bottom-0 left-0 top-0 min-h-full w-2 rounded-l-2xl"
        [ngClass]="getColorClass()">
      </div>
    </div>
    <div class="ml-2 flex w-full items-center justify-between p-4">
      <span class="font-medium">{{ title }}</span>
      <span class="font-light">
        {{
          formatTime(
            event.activityStartTime,
            event.activityEndTime,
            event.originationDate
          )
        }}
      </span>
    </div>
  </div>
</crew-ui-card>
