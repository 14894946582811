import { Component, Input } from '@angular/core';

import { CrewUICardComponent } from '../../../shared/components/crew-ui';

@Component({
  selector: 'crew-web-overview-no-events-card',
  templateUrl: './overview-no-events-card.component.html',
  styleUrls: ['./overview-no-events-card.component.css'],
  standalone: true,
  imports: [CrewUICardComponent],
})
export class OverviewNoEventsCardComponent {
  @Input({ required: true })
  text!: string;
}
