/**
 * Sorts an array of objects by a specified field in either ascending or descending order.
 *
 * @template T - The type of the objects in the array.
 * @param {T[]} array - The array of objects to be sorted.
 * @param {keyof T} field - The field by which to sort the objects.
 * @param {string} [sortBy='asc'] - The order in which to sort the objects. Can be 'asc' for ascending or 'desc' for descending. Defaults to 'asc'.
 * @returns {T[]} The sorted array.
 */
export const sortArrayBy = <T>(
  array: T[],
  field: keyof T,
  sortBy: 'asc' | 'desc' = 'asc',
): T[] => {
  if (!Array.isArray(array)) {
    return array;
  }

  array.sort((a, b) => {
    if (sortBy === 'desc') {
      if (a[field] > b[field]) {
        return -1;
      } else if (a[field] < b[field]) {
        return 1;
      } else {
        return 0;
      }
    } else {
      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  return array;
};
