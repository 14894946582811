import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { CrewUIIconComponent } from '../../../shared/components/crew-ui';
import { EventType } from '../../../shared/services/azure/calendar.service.types';
import { GridHeightOperationType } from '../../../shared/types';
import { formatDate } from '../../../shared/utils/date.utils';
import { CalendarService } from '../calendar.service';
import { CalendarEvent, SequenceInfo } from '../calendar.types';
import { CardDayButtonsComponent } from '../templates/card-day-buttons/card-day-buttons.component';
import { CardNonSequenceInfoComponent } from '../templates/card-non-sequence-info/card-non-sequence-info.component';
import { CardSequenceInfoComponent } from '../templates/card-sequence-info/card-sequence-info.component';
@Component({
  selector: 'crew-web-calendar-event-details',
  templateUrl: './calendar-event-details.component.html',
  styleUrls: ['./calendar-event-details.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    CrewUIIconComponent,
    CardDayButtonsComponent,
    CardNonSequenceInfoComponent,
    CardSequenceInfoComponent,
  ],
})
export class CalendarEventDetailsComponent {
  get selectedEvent() {
    return this.calendarService.selectedEvent();
  }

  get calendarSelectedDate() {
    return this.calendarService.calendarSelectedDate();
  }

  readonly gridHeightOperationType = GridHeightOperationType;

  readonly eventType = EventType;

  isEventVisible = false;

  constructor(private calendarService: CalendarService) {}

  showDate(date: string | null): string {
    const format = 'dddd, MMM D';
    return date ? formatDate(date, format) : formatDate(new Date(), format);
  }

  showEventDate(selectedEvent: CalendarEvent): string {
    return selectedEvent && selectedEvent.day
      ? this.showDate(selectedEvent.day)
      : this.showDate(null);
  }

  toggleEventData(): void {
    this.isEventVisible = !this.isEventVisible;
  }

  showFlightLegCount(selectedEvent: CalendarEvent): string {
    let legCount = 0;
    if (
      selectedEvent &&
      selectedEvent.eventData &&
      selectedEvent.eventData.length > 0
    ) {
      selectedEvent.eventData.filter((event: SequenceInfo) => {
        legCount += event.legsCount;
      });
    }
    return legCount > 0 ? `${legCount} legs` : ``;
  }
}
