import { Component } from '@angular/core';

import { CrewUIIconComponent } from '../crew-ui';
import { SettingsModalService } from '../settings-modal/settings-modal.service';

@Component({
  selector: 'crew-web-settings-button',
  templateUrl: './settings-button.component.html',
  styleUrls: ['./settings-button.component.css'],
  standalone: true,
  imports: [CrewUIIconComponent],
})
export class SettingsButtonComponent {
  constructor(private settingsModalService: SettingsModalService) {}

  show() {
    this.settingsModalService.show();
  }
}
