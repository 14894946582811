import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import {
  Colors,
  CrewUICardComponent,
  CrewUIDarkModeService,
  CrewUIIconComponent,
  CrewUIOverlayComponent,
} from '../../../shared/components/crew-ui';
import { FirebaseIdbService } from '../../../shared/services/idb/firebase.idb.service';
import { OverviewService } from '../overview.service';
import { OverviewNotificationComponent } from '../overview-notification/overview-notification.component';

@Component({
  selector: 'crew-web-overview-notification-modal',
  templateUrl: './overview-notification-modal.component.html',
  styleUrls: ['./overview-notification-modal.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    CrewUICardComponent,
    CrewUIIconComponent,
    CrewUIOverlayComponent,
    OverviewNotificationComponent,
  ],
})
export class OverviewNotificationModalComponent {
  constructor(
    private darkModeService: CrewUIDarkModeService,
    private firebaseIdbService: FirebaseIdbService,
    private overviewService: OverviewService,
  ) {}

  get isDark() {
    return this.darkModeService.isDarkMode();
  }

  get hasNotifications() {
    return this.notifications.length > 0;
  }

  get hasDisabledNotifications() {
    if (Notification.permission === 'granted') return false;
    return true;
  }

  get notifications() {
    return this.firebaseIdbService.notifications();
  }

  colors = Colors;

  refresh() {
    this.firebaseIdbService.reloadUnacknowledgedONENotifications(false);
  }

  close(event?: Event) {
    event?.stopPropagation();
    this.overviewService.toggleNotificationModal();
  }
}
