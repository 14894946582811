<label
  [class.mb-5]="size === sizes.Small || size === sizes.Default"
  class="relative inline-flex cursor-pointer items-center">
  <input
    [attr.id]="id"
    [attr.value]="value"
    [attr.name]="name"
    (change)="onSelect($event, value)"
    type="checkbox"
    class="peer sr-only"
    [disabled]="disabled"
    [checked]="checked" />
  <div
    [ngClass]="checkboxDivStyle()"
    class="peer rounded-full bg-gray-200 after:absolute after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-stratosphere peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-stratosphere dark:border-steel dark:bg-steel dark:after:border-steel dark:peer-checked:bg-troposphere dark:peer-focus:ring-troposphere"></div>
</label>
