import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { CrewUIIconComponent } from '../../../../shared/components/crew-ui';
import { SabreDocumentKeywordDisplay } from '../../../../shared/components/sabre-document/sabre-document.constant';
import { SabreDocumentService } from '../../../../shared/components/sabre-document/sabre-document.service';
import { FlightTimePipe } from '../../../../shared/pipes/flightTime.pipe';
import {
  EventType,
  FlightDutyPeriod,
  FlightLeg,
} from '../../../../shared/services/azure/calendar.service.types';
import { TimeService } from '../../../../shared/services/time.service';
import {
  formatDate,
  getNumberOfDays,
  minutesToHoursAndMinutes,
} from '../../../../shared/utils/date.utils';
import { SequenceInfo } from '../../calendar.types';
import { CardFlightInfoComponent } from '../card-flight-info/card-flight-info.component';

@Component({
  selector: 'crew-web-card-sequence-info',
  templateUrl: './card-sequence-info.component.html',
  styleUrls: ['./card-sequence-info.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    CrewUIIconComponent,
    CardFlightInfoComponent,
    FlightTimePipe,
  ],
})
export class CardSequenceInfoComponent {
  @Input() day = '' as string;

  @Input() sequenceInfo = {} as SequenceInfo;

  readonly sabreKeywordDisplay = SabreDocumentKeywordDisplay;

  constructor(
    private timeService: TimeService,
    private sabreDocumentService: SabreDocumentService,
  ) {}

  showStandByTime(sequenceInfo: SequenceInfo): string {
    return `${this.timeService.getTime(
      sequenceInfo.daysEvent.start,
    )} - ${this.timeService.getTime(sequenceInfo.daysEvent.end)}`;
  }

  showStandByDate(sequenceInfo: SequenceInfo, day: string): string {
    let response = ``;
    if (
      formatDate(sequenceInfo.daysEvent.start, 'YYYY-MM-DD') ===
      formatDate(day, 'YYYY-MM-DD')
    ) {
      response = formatDate(sequenceInfo.daysEvent.start, 'ddd, MMM D');
    } else if (
      formatDate(sequenceInfo.daysEvent.end, 'YYYY-MM-DD') ===
      formatDate(day, 'YYYY-MM-DD')
    ) {
      response = formatDate(sequenceInfo.daysEvent.end, 'ddd, MMM D');
    }
    return response;
  }

  showStandByStartDate(sequenceInfo: SequenceInfo, day: string): boolean {
    return (
      sequenceInfo.daysEvent.eventType === EventType.STANDBY &&
      formatDate(sequenceInfo.daysEvent.start, 'YYYY-MM-DD') ===
        formatDate(day, 'YYYY-MM-DD')
    );
  }

  isSequenceLastDay(date: string, day: string): boolean {
    return formatDate(date, 'YYYY-MM-DD') === formatDate(day, 'YYYY-MM-DD');
  }

  timelineStyle(date: string, type = 'border'): string {
    let borderStyle = ``;
    let bgStyle = ``;
    if (
      formatDate(date, 'YYYY-MM-DD HH:mm') <
      formatDate(new Date(), 'YYYY-MM-DD HH:mm')
    ) {
      borderStyle = `border-solid dark:border-troposphere border-stratosphere`;
      bgStyle = `bg-stratosphere dark:bg-troposphere dark:border-troposphere border-stratosphere`;
    }
    if (
      formatDate(date, 'YYYY-MM-DD HH:mm') >
      formatDate(new Date(), 'YYYY-MM-DD HH:mm')
    ) {
      borderStyle = `border-dashed dark:border-steel border-nickel`;
      bgStyle = `bg-nickel dark:bg-steel dark:border-steel border-nickel`;
    }
    return type === 'border' ? borderStyle : bgStyle;
  }

  getSequenceTimeLineStyle(
    start: string,
    beginsToday: boolean,
    type: string,
  ): string {
    return beginsToday ? this.timelineStyle(start, type) : ``;
  }

  flightLegTimeLineStyle(flight: FlightLeg, type = 'border'): string {
    let departureTime = flight.scheduled.departureDateTime.localTime;
    if (flight && flight.actual) {
      departureTime = flight.actual.departureDateTime.localTime;
    }
    return this.timelineStyle(departureTime, type);
  }

  getSequenceDays(sequenceInfo: SequenceInfo, day: string): string {
    const { daysEvent } = sequenceInfo;
    const { start, end } = daysEvent;
    const sequenceDays = getNumberOfDays(start, end);
    const currentDay = getNumberOfDays(start, day);
    return `Day ${currentDay} of ${sequenceDays}`;
  }

  getTime(dateTime: string) {
    return `${this.timeService.getTime(dateTime)}`;
  }

  getFlightLegs(flightDutyPeriods: FlightDutyPeriod | undefined): FlightLeg[] {
    let response = [] as FlightLeg[];
    const hasFlightLegs =
      flightDutyPeriods &&
      flightDutyPeriods.flightLegs &&
      flightDutyPeriods.flightLegs.length > 0;
    if (hasFlightLegs) {
      response = flightDutyPeriods.flightLegs;
    }
    return response;
  }

  getFlightDutyPeriod(
    flightDutyPeriods: FlightDutyPeriod[] | undefined,
    beginsToday: boolean,
  ): FlightDutyPeriod[] {
    let response = [] as FlightDutyPeriod[];
    if (flightDutyPeriods && flightDutyPeriods.length > 0) {
      if (beginsToday) {
        response.push(flightDutyPeriods[0]);
      }
      if (!beginsToday) {
        response = flightDutyPeriods;
      }
    }
    return response;
  }

  getFlightTime(flight: FlightLeg): string {
    let departureTime = flight.scheduled.departureDateTime.localTime;
    if (flight && flight.actual) {
      departureTime = flight.actual.departureDateTime.localTime;
    }
    return this.getTime(departureTime);
  }

  getLegsBySequenceDays(
    flightDutyPeriods: FlightDutyPeriod[],
    beginsToday: boolean,
  ) {
    let legs = flightDutyPeriods[flightDutyPeriods.length - 1].flightLegs;
    if (beginsToday) {
      legs = flightDutyPeriods[0].flightLegs;
    }
    return legs[legs.length - 1];
  }

  getLayoverArrivalTime(
    flightDutyPeriods: FlightDutyPeriod[] | undefined,
    beginsToday: boolean,
    day: string,
  ): string {
    let response = ``;
    const hasFlightLegs =
      day && flightDutyPeriods && flightDutyPeriods.length > 0;
    if (hasFlightLegs) {
      const lastLeg = this.getLegsBySequenceDays(
        flightDutyPeriods,
        beginsToday,
      );
      let arrivalTime = lastLeg.scheduled.arrivalDateTime.localTime;
      if (lastLeg && lastLeg.actual) {
        arrivalTime = lastLeg.actual.arrivalDateTime.localTime;
      }
      response = this.getTime(arrivalTime);
    }
    return response;
  }

  layoverTimeLine(
    flightDutyPeriods: FlightDutyPeriod[] | undefined,
    beginsToday: boolean,
    type: string,
  ): string {
    let response = ``;
    const hasFlightLegs = flightDutyPeriods && flightDutyPeriods.length > 0;
    if (hasFlightLegs) {
      const lastLeg = this.getLegsBySequenceDays(
        flightDutyPeriods,
        beginsToday,
      );
      let arrivalTime = lastLeg.scheduled.arrivalDateTime.localTime;
      if (lastLeg && lastLeg.actual) {
        arrivalTime = lastLeg.actual.arrivalDateTime.localTime;
      }
      response = this.timelineStyle(arrivalTime, type);
    }
    return response;
  }

  getLayoverTime(time: number): string {
    let response = `${time}m `;
    if (time > 0) {
      response = minutesToHoursAndMinutes(time);
    }
    return response;
  }

  callSabreCommand(
    keyword: string,
    sequenceInfo: SequenceInfo,
    day: string,
  ): void {
    let parameters = ``;
    let flightLeg = {} as FlightLeg;
    if (keyword === SabreDocumentKeywordDisplay.HSS) {
      const sequenceNumber =
        sequenceInfo.daysEvent?.sequenceActivity?.sequenceNumber;
      const positionCode =
        sequenceInfo.daysEvent?.sequenceActivity?.positionCode;
      parameters = `/${positionCode}/${sequenceNumber}/${formatDate(
        sequenceInfo.daysEvent.start,
        'DDMMM',
      ).toUpperCase()}`;
    } else if (keyword === SabreDocumentKeywordDisplay.CREWHOTELLIMOINFO) {
      const flightDutyPeriods = this.getFlightDutyPeriod(
        sequenceInfo.daysEvent.sequenceActivity?.flightDutyPeriods,
        sequenceInfo.daysEvent.beginsToday,
      );
      const lastLeg = this.getLegsBySequenceDays(
        flightDutyPeriods,
        sequenceInfo.daysEvent.beginsToday,
      );
      parameters = `/${lastLeg.flightNumber}/${formatDate(
        day,
        'DDMMM',
      ).toUpperCase()}/${lastLeg.departureStation}/${lastLeg.arrivalStation}`;
      flightLeg = lastLeg;
    }
    this.sabreDocumentService.doSabreCall(keyword, flightLeg, parameters);
  }
}
