import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CREW_ROUTES } from '../../app-routing.module';
import { MessageService } from '../../routes/message/message.service';
import {
  Colors,
  CrewUIDarkModeService,
  CrewUIIconComponent,
} from '../../shared/components/crew-ui';
import { SabreDocumentService } from '../../shared/components/sabre-document/sabre-document.service';
import { SettingsModalService } from '../../shared/components/settings-modal/settings-modal.service';

@Component({
  selector: 'crew-web-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css'],
  standalone: true,
  imports: [CrewUIIconComponent, RouterModule, CommonModule],
})
export class SideNavComponent {
  constructor(
    private darkModeService: CrewUIDarkModeService,
    private settingsModalService: SettingsModalService,
    private sabreDocumentService: SabreDocumentService,
    private messageService: MessageService,
  ) {}

  @Input()
  routerUrl = '';

  @Input()
  getCrewPath!: (args: string) => void;

  @Input()
  isActiveRoute!: (args: string) => boolean;

  routes = CREW_ROUTES;
  colors = Colors;

  get darkMode() {
    return this.darkModeService.isDarkMode();
  }

  get messageCount() {
    return this.messageService.lowAndMediumUnreadMessageCount();
  }

  get hi6Count() {
    return this.messageService.hi6Count();
  }

  showSettingsModal() {
    this.settingsModalService.show();
  }

  buildIconColor(): Colors {
    if (!this.isActiveRoute) return Colors.Nickel;

    if (this.darkMode) return Colors.Troposphere;

    return Colors.Stratosphere;
  }

  closeHSS() {
    this.sabreDocumentService.clearSabreResponse();
  }
}
