<label
  *ngIf="label && label !== ''"
  class="mb-2 ml-2 block font-medium"
  [ngClass]="{
    'text-afterburner': isInvalid,
    'text-charcoal': !isInvalid,
    'dark:text-white': !isInvalid
  }">
  {{ label }}
</label>
<select
  [(ngModel)]="selectedOption"
  class="m-0 block h-14 w-full rounded-lg p-4 pr-10 text-base text-carbon ring-1 transition ease-in-out focus:ring-stratosphere dark:bg-charcoal dark:bg-opacity-0 dark:text-white"
  [ngClass]="{
        'dark:ring-aluminum': !selectedOption && !isInvalid,
        'dark:ring-steel': selectedOption && !isInvalid,
        'focus:ring-afterburner': isInvalid,
        'ring-afterburner': isInvalid,
        'ring-steel': selectedOption && !isInvalid,
        'ring-turbine': !selectedOption && !isInvalid,
      }">
  <option *ngIf="placeholder && placeholder !== ''" selected>
    {{ placeholder }}
  </option>
  <option *ngFor="let option of options" [value]="option.value">
    {{ option.text }}
  </option>
</select>
<span
  *ngIf="assistiveLabel && assistiveLabel !== ''"
  class="ml-4 mt-1 flex text-base"
  [ngClass]="{
      'text-afterburner':isInvalid,
      'text-nickel':!isInvalid,
    }">
  - {{ assistiveLabel }}
</span>
