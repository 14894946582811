import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { SabreDocumentComponent } from '../../shared/components/sabre-document/sabre-document.component';
import { SabreDocumentService } from '../../shared/components/sabre-document/sabre-document.service';
import { InsightsService } from '../../shared/services/azure/insights.service';
import { UserService } from '../../shared/services/user.service';
import { CalendarService } from './calendar.service';
import { CalendarEventDetailsComponent } from './calendar-event-details/calendar-event-details.component';
import { CalendarInfoComponent } from './calendar-info/calendar-info.component';
import { CalendarMenuComponent } from './calendar-menu/calendar-menu.component';
@Component({
  selector: 'crew-web-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    CalendarMenuComponent,
    CalendarInfoComponent,
    CalendarEventDetailsComponent,
    SabreDocumentComponent,
  ],
})
export class CalendarComponent implements OnInit {
  get hasSabreResponse() {
    return this.sabreDocumentService.hasSabreResponse();
  }

  constructor(
    private calendarService: CalendarService,
    private insightsService: InsightsService,
    private sabreDocumentService: SabreDocumentService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.insightsService.trackPageView({
      name: 'Calendar',
    });
    this.calendarService.loadCalendar();
  }
}
