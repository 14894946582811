import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';

/*
 * This Directive is used to calculate the font-size and fit
 * the text within the viewport width.
 */
@Directive({
  standalone: true,
  selector: '[cciWebFitText]',
})
export class FitTextDirective implements AfterViewInit {
  @Input() fitText = false;

  private elRef;

  private unit = 'px';

  private maxFontSize = 16;

  private minFontSize = 7;

  private maxDeviceSm = 959;

  private previousWidth = 0;

  private newWidth = 0;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.elRef = this.el.nativeElement;
    this.previousWidth = window.innerWidth;
  }

  @HostListener('window:resize')
  public onWindowResize = (): void => {
    this.newWidth = window.innerWidth;
    this.setFontSize();
    this.previousWidth = this.newWidth;
  };

  ngAfterViewInit(): void {
    this.setFontSize();
  }

  private getInitFontSize(): number {
    const elRefFontSize = this.elRef.style.fontSize;
    return elRefFontSize ? parseInt(elRefFontSize) : this.maxFontSize;
  }

  private getRatio(): number {
    return Math.round(window.innerWidth / this.elRef.scrollWidth);
  }

  private setFontSize = (): void => {
    if (this.fitText) {
      const ratio = this.getRatio();
      const fontSize = this.getInitFontSize();
      if (
        this.newWidth === 0 &&
        this.previousWidth > 0 &&
        this.previousWidth < this.maxDeviceSm
      ) {
        this.decreaseFontSize(fontSize, ratio);
      } else if (this.newWidth > 0 && this.newWidth > this.maxDeviceSm) {
        this.setStyles(this.maxFontSize);
      } else if (this.newWidth > 0 && this.newWidth < this.maxDeviceSm) {
        if (
          this.previousWidth > 0 &&
          this.newWidth > 0 &&
          this.newWidth > this.previousWidth &&
          this.newWidth < this.maxDeviceSm
        ) {
          this.increaseFontSize();
        } else if (
          this.previousWidth > 0 &&
          this.newWidth > 0 &&
          this.newWidth < this.previousWidth &&
          this.newWidth < this.maxDeviceSm
        ) {
          this.decreaseFontSize(fontSize, ratio);
        }
      }
    }
  };

  private decreaseFontSize(fontSize: number, ratio: number): void {
    for (let i = fontSize; i >= 0; i--) {
      if (this.elRef.scrollWidth > this.elRef.clientWidth) {
        if (fontSize >= this.minFontSize) {
          fontSize--;
          this.setStyles(fontSize * ratio);
        }
      }
    }
  }

  private increaseFontSize(): void {
    for (let i = 0; i <= this.elRef.textContent.length; i++) {
      const fontSize = Math.round(Math.min(this.elRef.clientWidth / 35, 20));
      this.setStyles(fontSize);
    }
  }

  private setStyles = (fontSize: number): void => {
    this.renderer.setStyle(
      this.elRef,
      'fontSize',
      fontSize.toString() + this.unit,
    );
    this.renderer.setStyle(
      this.elRef,
      'display',
      window.getComputedStyle(this.elRef).display,
    );
  };
}
