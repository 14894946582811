import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { environment } from '../../../../environments/environment';
import {
  Colors,
  CrewUIDarkModeService,
  CrewUIDividerComponent,
  CrewUIIconComponent,
} from '../../../shared/components/crew-ui';
import { SabreDocumentKeywordDisplay } from '../../../shared/components/sabre-document/sabre-document.constant';
import { SabreDocumentService } from '../../../shared/components/sabre-document/sabre-document.service';
import { FlightTimePipe } from '../../../shared/pipes/flightTime.pipe';
import {
  CrewMemberWithSameLayover,
  Events,
  FlightDutyPeriods,
  FlightLeg,
  ScheduleType,
} from '../../../shared/services/azure/schedule.service.types';
import { TimeService } from '../../../shared/services/time.service';
import { UserService } from '../../../shared/services/user.service';
import {
  DateFormat,
  FlightPosition,
  TripSignInButtonTitle,
} from '../../../shared/types/index';
import {
  formatDate,
  isCurrentTimeLessThanGivenTime,
} from '../../../shared/utils/date.utils';
import {
  airlineCodeStyle,
  getArrivalColor,
  getDepartureColor,
  showArrivalTime,
  showDepartureTime,
  validateActualRescheduledArrivalTime,
  validateActualRescheduledDepartureTime,
} from '../../../shared/utils/flightLeg.utils';
import { SeatMapComponent } from './seat-map/seat-map.component';
@Component({
  selector: 'crew-web-overview-sequence-card',
  templateUrl: './overview-sequence-card.component.html',
  styleUrls: ['./overview-sequence-card.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    CrewUIIconComponent,
    CrewUIDividerComponent,
    FlightTimePipe,
    SeatMapComponent,
  ],
})
export class OverviewSequenceCardComponent {
  constructor(
    private darkModeService: CrewUIDarkModeService,
    private sabreService: SabreDocumentService,
    private timeService: TimeService,
    private userService: UserService,
  ) {}

  @Input({ required: true })
  event!: Events;

  @Input() day = '' as string;

  @Input()
  isExpanded = false;

  isExpandedCrewStay = false;

  blankValue = `--`;

  colors = Colors;

  readonly sabreKeywordDisplay = SabreDocumentKeywordDisplay;

  get isDark(): boolean {
    return this.darkModeService.isDarkMode();
  }

  get isAAFAMQFA(): boolean {
    return this.userService.isAAFAMQFA();
  }

  get isAAPIMQPI(): boolean {
    return this.userService.isAAPIMQPI();
  }

  showStandByTime(event: Events): string {
    return `${this.timeService.getTime(
      event.activityStartTime,
    )} - ${this.timeService.getTime(event.activityEndTime)}`;
  }

  showFlightNumber(flightInfo: FlightLeg): string {
    let response = `${flightInfo.airlineCode} ${flightInfo.flightNumber}`;
    if (flightInfo.deadHeadAirlineCode || flightInfo.cancelled) {
      response = flightInfo.deadHeadAirlineCode
        ? `${flightInfo.deadHeadAirlineCode} ${flightInfo.flightNumber}`
        : response;
    }
    return response;
  }

  showAirlineCodeStyle(flightInfo: FlightLeg, type: string): string {
    let info = airlineCodeStyle(flightInfo, type);
    if (info && type === 'title') {
      info = `: ${info}`;
    }
    return info;
  }

  formatTimeDifference(
    startDateTimeString?: string | null,
    endDateTimeString?: string | null,
  ): string {
    if (!startDateTimeString || !endDateTimeString) return '--';
    return this.timeService.findTimeDifference(
      startDateTimeString,
      endDateTimeString,
    );
  }

  formatTime(dateTimeString?: string | null): string | null {
    let formatted = this.timeService.getTime(dateTimeString);
    if (formatted === '') formatted = '--';
    return formatted;
  }

  /**
   * * Validate flight plan is signed for the leg.
   * @param leg
   * @returns
   */
  isFlightPlanSigned(leg: FlightLeg): boolean {
    let response = false;
    if (leg && leg.fltPlanSignature && leg.fltPlanSignature.signedFlightPlan) {
      response = true;
    }
    return response;
  }

  /**
   * Display `Confirmed FFD` for the FA
   * Employee Bu = AAPI/MQPI
   * If they have the leg index greater than 1
   */
  isConfirmedFFD(leg: FlightLeg): boolean {
    let response = false;
    if (
      leg &&
      this.isAAPIMQPI &&
      leg.fltPlanSignature &&
      leg.fltPlanSignature.confirmedFFD &&
      leg.legIndex !== 1
    ) {
      response = true;
    }
    return response;
  }

  /**
   * * Validate signed in for the trip & confirmed FFD.
   * if pilot the txt should be "You have signed in for the trip & confirmed FFD"
   * if !pilot the text should be "You have signed in for the trip".
   * @returns
   */
  signedInAndConfirmedFfd(
    event: Events,
    type: string | boolean,
  ): boolean | string {
    const { signedIn, sequence, isStandby } = event;
    let responseAsBoolean = false;
    if (
      (signedIn && isStandby) ||
      (signedIn && !isStandby && sequence?.activeFlightLeg?.legIndex === 1)
    ) {
      responseAsBoolean = true;
    }
    if (this.isAAFAMQFA && sequence?.tripSignInInfo?.signedIn) {
      responseAsBoolean = true;
    }
    if (
      sequence?.activeFlightLeg?.legIndex !== 1 &&
      sequence?.activeFlightLeg?.fltPlanSignature &&
      typeof sequence?.activeFlightLeg?.fltPlanSignature === 'object' &&
      sequence?.activeFlightLeg?.fltPlanSignature !== null &&
      sequence?.activeFlightLeg?.fltPlanSignature.signedFlightPlan
    ) {
      responseAsBoolean = true;
    }
    if (type === 'title' && responseAsBoolean) {
      let title = TripSignInButtonTitle.SignedInForTrip;
      if (this.isAAPIMQPI) {
        title =
          sequence?.activeFlightLeg?.legIndex === 1
            ? TripSignInButtonTitle.SignedInForTripConfirmedFFD
            : TripSignInButtonTitle.SignedFlightPlanConfirmedFFD;
      }
      return title;
    }
    return responseAsBoolean;
  }

  /**
   * * Validate Sign in for the trip.
   * * Eg. Sign in for trip at 05:31.
   * * if current time is smaller than the sign in time
   * @returns *
   */
  signInForTrip(event: Events, type: string | boolean): boolean | string {
    const { signedIn, isSignInEligible, signInTime } = event;
    const responseAsBoolean =
      !signedIn &&
      isSignInEligible &&
      signInTime &&
      isCurrentTimeLessThanGivenTime(signInTime)
        ? true
        : false;
    return type === 'title' && responseAsBoolean
      ? `${TripSignInButtonTitle.SignInForTripAt} ${this.formatTime(
          signInTime,
        )}`
      : responseAsBoolean;
  }

  getFlightDutyPeriods(event: Events): FlightDutyPeriods[] {
    if (
      event &&
      event.sequence &&
      event.sequence.flightDutyPeriods &&
      event.sequence.flightDutyPeriods.length > 0
    ) {
      return event.sequence.flightDutyPeriods;
    }
    return [] as FlightDutyPeriods[];
  }

  /**
   * return the flight time based on the Scheduled/reScheduled/Actual
   * @param leg
   * @returns
   */
  getFlightTime(leg: FlightLeg): ScheduleType {
    let flightTime = leg.scheduled;
    if (leg?.actual) {
      flightTime = leg.actual;
    } else if (leg?.reScheduled) {
      flightTime = leg.reScheduled;
    }
    return flightTime;
  }

  /**
   * We should not use the this.formatTime. It can be 24/12 format based on client selection
   * @param keyword
   * @param leg
   */
  doSabreCall(keyword: string, leg: FlightLeg): void {
    let parameters = ``;
    const { originationDate, sequence } = this.event;
    const sequenceStartDate = formatDate(
      originationDate,
      DateFormat.DDMMM,
    ).toUpperCase();
    const flightTime = this.getFlightTime(leg);
    const departureDateTimeLocal = formatDate(
      flightTime?.departureDateTime?.localTime,
      DateFormat.HHmm,
    );
    const departureDateTimeGmt = formatDate(
      flightTime?.departureDateTime?.gmt,
      DateFormat.HHmm,
    );
    const arrivalDateTimeLocal = formatDate(
      flightTime?.arrivalDateTime?.localTime,
      DateFormat.HHmm,
    );
    const flightOriginationDate = formatDate(
      leg.flightOriginationDate,
      DateFormat.DDMMM,
    ).toUpperCase();
    switch (keyword) {
      case SabreDocumentKeywordDisplay.NSNST:
        parameters = `/${leg.flightNumber}/${flightOriginationDate}/${leg.departureStation}/${departureDateTimeLocal}`;
        break;
      case SabreDocumentKeywordDisplay.CDI:
        parameters = `${leg.flightNumber}${flightOriginationDate}${leg.departureStation}${leg.arrivalStation}${departureDateTimeLocal}${departureDateTimeGmt}`;
        break;
      case SabreDocumentKeywordDisplay.RGT:
        parameters = `${leg.arrivalStation}/${leg.arrivalGate}/${departureDateTimeLocal}/${arrivalDateTimeLocal}`;
        break;
      case SabreDocumentKeywordDisplay.HSS:
        const sequenceNumber = sequence?.sequenceNumber;
        const positionCode = sequence?.positionCode;
        parameters = `/${positionCode}/${sequenceNumber}/${sequenceStartDate}`;
        break;
      case SabreDocumentKeywordDisplay.CREWHOTELLIMOINFO:
        const orgDate = formatDate(
          this.event.originationDate,
          DateFormat.DDMMM,
        ).toUpperCase();
        parameters = `/${leg.flightNumber}/${orgDate}/${leg.departureStation}/${leg.arrivalStation}`;
        break;
    }
    this.sabreService.doSabreCall(keyword, leg, parameters);
  }

  viewHSS(keyword: string): void {
    this.doSabreCall(keyword, {} as FlightLeg);
  }

  getCrewStaying(): CrewMemberWithSameLayover[] {
    const { sequence } = this.event;
    let response = [] as CrewMemberWithSameLayover[];
    if (
      sequence &&
      sequence.lastFlightLeg &&
      sequence.lastFlightLeg.crewMembersWithSameLayover &&
      sequence.lastFlightLeg.crewMembersWithSameLayover.length > 0
    ) {
      response = sequence.lastFlightLeg.crewMembersWithSameLayover;
    }
    return response;
  }

  getFlightPosition(fltPosition: string): string {
    let position = fltPosition;
    if (fltPosition && fltPosition === FlightPosition.CA) {
      position = FlightPosition.CAPTAIN;
    } else if (fltPosition && fltPosition === FlightPosition.FO) {
      position = FlightPosition.FIRST_OFFICER;
    }
    return position;
  }

  /**
   * On click go to aircraft url external link
   * @param assignedTail
   */
  goToUrl(assignedTail: string | null | undefined): void {
    if (assignedTail) {
      window.location.href = `${environment.aircraftUrl}${assignedTail}`;
    }
  }

  getDepartureColor(flightInfo: FlightLeg): string {
    return getDepartureColor(flightInfo);
  }

  getArrivalColor(flightInfo: FlightLeg): string {
    return getArrivalColor(flightInfo);
  }

  showArrivalTime(flightInfo: FlightLeg): string {
    let arrivalTime = ``;
    if (showArrivalTime(flightInfo)) {
      arrivalTime = `${this.timeService.getTime(showArrivalTime(flightInfo))}`;
    }
    return arrivalTime;
  }

  showDepartureTime(flightInfo: FlightLeg): string {
    let departureTime = ``;
    if (showArrivalTime(flightInfo)) {
      departureTime = `${this.timeService.getTime(
        showDepartureTime(flightInfo),
      )}`;
    }
    return departureTime;
  }

  validateActualRescheduledDepartureTime(flightInfo: FlightLeg): boolean {
    return validateActualRescheduledDepartureTime(flightInfo);
  }

  validateActualRescheduledArrivalTime(flightInfo: FlightLeg): boolean {
    return validateActualRescheduledArrivalTime(flightInfo);
  }
}
