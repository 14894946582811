import { computed, Injectable, signal, WritableSignal } from '@angular/core';

import { CoreService } from './azure/core.service';
import {
  CheckListItem,
  GetChecklistItemRequest,
  GetChecklistItemResponse,
} from './azure/core.service.types';
import { UserService } from './user.service';
export enum FilterByType {
  PCMD = 'Pilot Commands',
  PICMD = 'Pilot Input Commands',
  HI = 'HI Commands',
  I = 'Input',
}
export enum FilterKey {
  K = 'K',
  R = 'R',
}
@Injectable({
  providedIn: 'root',
})
export class CommandService {
  private commands: WritableSignal<GetChecklistItemResponse> = signal(
    {} as GetChecklistItemResponse,
  );

  constructor(
    private userService: UserService,
    private coreService: CoreService,
  ) {}

  checkPilotCommands = computed(() =>
    this.filterChecklistItemArray(this.commands(), FilterByType.PCMD),
  );

  checkPilotInputCommands = computed(() =>
    this.filterChecklistItemArray(this.commands(), FilterByType.PICMD),
  );

  hiCommands = computed(() =>
    this.filterChecklistItemArray(this.commands(), FilterByType.HI),
  );

  inputCommands = computed(() =>
    this.filterChecklistItemArray(this.commands(), FilterByType.I),
  );

  filterChecklistItemArray(
    commands: GetChecklistItemResponse,
    filterKey: string,
  ): CheckListItem[] {
    const response = [] as CheckListItem[];
    if (commands && commands.checklistItemArray) {
      commands.checklistItemArray.filter((c: CheckListItem) => {
        if (c.commandTypeCode) {
          if (
            filterKey === FilterByType.PCMD &&
            c.commandTypeCode === FilterKey.K
          ) {
            response.push(c);
          } else if (
            filterKey === FilterByType.PICMD &&
            c.commandTypeCode === FilterKey.R
          ) {
            response.push(c);
          } else if (
            filterKey === FilterByType.HI &&
            c.commandKeywordDisplay &&
            c.commandKeywordDisplay.startsWith('HI') &&
            c.commandTypeCode !== 'I'
          ) {
            response.push(c);
          } else if (
            filterKey === FilterByType.I &&
            c.commandTypeCode === 'I'
          ) {
            response.push(c);
          }
        }
      });
    }
    return response;
  }

  getCheckListItem() {
    const payload: GetChecklistItemRequest = {
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      businessUnit: this.userService.emulatedOrDefaultBusinessUnit(),
      empIdLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      siteMinderEmpId: this.userService.employeeNumber(),
      checklistId: '0',
    };
    this.coreService.getChecklistItem(payload).subscribe({
      next: (response: GetChecklistItemResponse) => {
        this.commands.set(response);
      },
      error: (e) => {
        console.error(e);
      },
    });
  }
}
