import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpService } from '../http.service';
import {
  CaptureTripSignInOptInTimestampRequest,
  CaptureTripSignInOptInTimestampResponse,
  DelProbyEmpBySurveyIdRequest,
  GetChecklistItemRequest,
  GetChecklistItemResponse,
  GetCrewMemberV6Request,
  GetCrewMemberV6Response,
  GetEmployeeByBURequest,
  GetEmployeeByBUResponse,
  GetEmployeeFavCommandsResponse,
  GetEmployeeIdsRequest,
  GetEmployeeIdsResponse,
  GetEmployeePrintPreferenceRequest,
  GetEmployeePrintPreferenceResponse,
  GetEmployeeProbyDetailsRequest,
  GetEmployeeProbyDetailsResponse,
  GetEmpPrintPreferenceRequest,
  GetEmpPrintPreferenceResponse,
  GetFlightInfoV3Request,
  GetFlightInfoV3Response,
  GetFlightInfoV4Request,
  GetFlightInfoV4Response,
  GetHotelLimoInfoRequest,
  GetHotelLimoInfoResponse,
  GetProbyReportRequest,
  GetProbyReportResponse,
  GetProbySurveyDetailsRequest,
  GetProbySurveyDetailsResponse,
  GetProbySurveyRequest,
  GetProbySurveyResponse,
  GetSequenceInfoRequest,
  GetSequenceInfoResponse,
  GetStartSessionRequest,
  GetStartSessionResponse,
  GetStartSessionV2Request,
  GetStartSessionV2Response,
  GetSurveyDetailsBySurveyIdRequest,
  GetSurveyDetailsBySurveyIdResponse,
  GetSWSLogoutSessionRequest,
  GetSWSLogoutSessionResponse,
  GetTripSignInInfoV3Request,
  GetTripSignInInfoV3Response,
  GetUserDetailsByBuRequest,
  GetUserDetailsByBuResponse,
  GetUserDetailsForHomeV2Request,
  GetUserDetailsForHomeV2Response,
  JpyServiceRequest,
  JpyServiceResponse,
  LogDeviceDetailRequest,
  LogDeviceDetailResponse,
  ManageEmployeeFavCommandsRequest,
  ManageEmployeeFavCommandsResponse,
  ManageEmpPrintPreferencesRequest,
  ManageEmpPrintPreferencesResponse,
  MobileGetMessageRequest,
  MobileGetMessageResponse,
  RefreshAppInfoRequest,
  RefreshAppInfoResponse,
  ReorderEmpPrintPreferencesV1Request,
  ReorderEmpPrintPreferencesV1Response,
  RetrieveProbyInformationRequest,
  RetrieveProbyInformationResponse,
  SearchFlightsRequest,
  SearchFlightsResponse,
  SendEmailsV1Response,
  SendFeedbackEmailRequest,
  SendFeedbackEmailResponse,
  SendFeedbackEmailV2Request,
  SendFeedbackEmailV2Response,
  ServiceGetDBFunctionsRequest,
  ServiceGetDBFunctionsResponse,
  SubmitProbySurveyRequest,
  SubmitProbySurveyResponse,
  SubmitToIPPrintRequest,
  SubmitToIPPrintResponse,
} from './core.service.types';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(private httpService: HttpService) {}

  private readonly base = `${environment.azure.services.url}/coreservices`;

  captureTripSignInOptInTimestamp(
    body: CaptureTripSignInOptInTimestampRequest,
    bypassLoader = false,
  ) {
    const url = `${this.base}/CrewService/captureTripSignInOptInTimestamp`;
    return this.httpService.post<
      CaptureTripSignInOptInTimestampRequest,
      CaptureTripSignInOptInTimestampResponse
    >(url, body, bypassLoader);
  }

  delProbyEmpBySurveyId(
    body: DelProbyEmpBySurveyIdRequest,
    bypassLoader = false,
  ) {
    const url = `${this.base}/CrewService/delProbyEmpBySurveyId`;
    return this.httpService.post(url, body, bypassLoader);
  }

  getChecklistItem(body: GetChecklistItemRequest, bypassLoader = false) {
    const url = `${this.base}/CrewService/getChecklistItem`;
    return this.httpService.post<
      GetChecklistItemRequest,
      GetChecklistItemResponse
    >(url, body, bypassLoader);
  }

  getCrewMemberV6(body: GetCrewMemberV6Request, bypassLoader = false) {
    const url = `${this.base}/CalendarService/v6/getCrewMember`;
    return this.httpService.post<
      GetCrewMemberV6Request,
      GetCrewMemberV6Response
    >(url, body, bypassLoader);
  }

  getEmployeeByBU(body: GetEmployeeByBURequest, bypassLoader = false) {
    const url = `${this.base}/EmployeeService/getEmployeeByBU`;
    return this.httpService.post<
      GetEmployeeByBURequest,
      GetEmployeeByBUResponse
    >(url, body, bypassLoader);
  }

  getEmployeeFavCommands(bypassLoader = false) {
    const url = `${this.base}/CrewService/getEmployeeFavCommands`;
    return this.httpService.get<GetEmployeeFavCommandsResponse>(
      url,
      bypassLoader,
    );
  }

  getEmployeeIds(body: GetEmployeeIdsRequest, bypassLoader = false) {
    const url = `${this.base}/EmployeeService/getEmployeeIds/${body.businessUnit}/${body.employeeCount}/${body.airlineCode}`;
    return this.httpService.post<GetEmployeeIdsRequest, GetEmployeeIdsResponse>(
      url,
      body,
      bypassLoader,
    );
  }

  getEmployeePrintPreference(
    body: GetEmployeePrintPreferenceRequest,
    bypassLoader = false,
  ) {
    const url = `${this.base}/CrewService/getEmployeePrintPreference`;
    return this.httpService.post<
      GetEmployeePrintPreferenceRequest,
      GetEmployeePrintPreferenceResponse
    >(url, body, bypassLoader);
  }

  getEmployeeProbyDetails(
    body: GetEmployeeProbyDetailsRequest,
    bypassLoader = false,
  ) {
    const url = `${this.base}/CrewService/getEmployeeProbyDetails`;
    return this.httpService.post<
      GetEmployeeProbyDetailsRequest,
      GetEmployeeProbyDetailsResponse
    >(url, body, bypassLoader);
  }

  getEmpPrintPreference(
    body: GetEmpPrintPreferenceRequest,
    bypassLoader = false,
  ) {
    const url = `${this.base}/CrewService/getEmpPrintPreference`;
    return this.httpService.post<
      GetEmpPrintPreferenceRequest,
      GetEmpPrintPreferenceResponse
    >(url, body, bypassLoader);
  }

  getFlightInfoV3(body: GetFlightInfoV3Request, bypassLoader = false) {
    const url = `${this.base}/CrewService/v3/getFlightInfo`;
    return this.httpService.post<
      GetFlightInfoV3Request,
      GetFlightInfoV3Response
    >(url, body, bypassLoader);
  }

  getFlightInfoV4(body: GetFlightInfoV4Request, bypassLoader = false) {
    const url = `${this.base}/CrewService/v4/getFlightInfo`;
    return this.httpService.post<
      GetFlightInfoV4Request,
      GetFlightInfoV4Response
    >(url, body, bypassLoader);
  }

  getHotelLimoInfo(body: GetHotelLimoInfoRequest, bypassLoader = false) {
    const url = `${this.base}/HotelLimoService/getHotelLimoInfo`;
    return this.httpService.post<
      GetHotelLimoInfoRequest,
      GetHotelLimoInfoResponse
    >(url, body, bypassLoader);
  }

  getProbyReport(body: GetProbyReportRequest, bypassLoader = false) {
    const url = `${this.base}/CrewService/getProbyReport`;
    return this.httpService.post<GetProbyReportRequest, GetProbyReportResponse>(
      url,
      body,
      bypassLoader,
    );
  }

  getProbySurvey(body: GetProbySurveyRequest, bypassLoader = false) {
    const url = `${this.base}/CrewService/getProbySurvey`;
    return this.httpService.post<GetProbySurveyRequest, GetProbySurveyResponse>(
      url,
      body,
      bypassLoader,
    );
  }

  getProbySurveyDetails(
    body: GetProbySurveyDetailsRequest,
    bypassLoader = false,
  ) {
    const url = `${this.base}/CrewService/getProbySurveyDetails`;
    return this.httpService.post<
      GetProbySurveyDetailsRequest,
      GetProbySurveyDetailsResponse
    >(url, body, bypassLoader);
  }

  getProbySurveyResponsesAsPdf(bypassLoader = false) {
    const url = `${this.base}/CrewService/getProbySurveyResponsesAsPdf?request={request}`;
    return this.httpService.getFile(url, bypassLoader);
  }

  getSequenceInfo(body: GetSequenceInfoRequest, bypassLoader = false) {
    const url = `${this.base}/CalendarService/v1/getSequenceInfo`;
    return this.httpService.post<
      GetSequenceInfoRequest,
      GetSequenceInfoResponse
    >(url, body, bypassLoader);
  }

  getStartSession(body: GetStartSessionRequest, bypassLoader = false) {
    const url = `${this.base}/CrewService/getStartSession`;
    return this.httpService.post<
      GetStartSessionRequest,
      GetStartSessionResponse
    >(url, body, bypassLoader);
  }

  getStartSessionV2(body: GetStartSessionV2Request, bypassLoader = false) {
    const url = `${this.base}/CrewService/v2/getStartSession`;
    return this.httpService.post<
      GetStartSessionV2Request,
      GetStartSessionV2Response
    >(url, body, bypassLoader);
  }

  getSurveyDetailsBySurveyId(
    body: GetSurveyDetailsBySurveyIdRequest,
    bypassLoader = false,
  ) {
    const url = `${this.base}/CrewService/getSurveyDetailsBySurveyId`;
    return this.httpService.post<
      GetSurveyDetailsBySurveyIdRequest,
      GetSurveyDetailsBySurveyIdResponse
    >(url, body, bypassLoader);
  }

  getSWSLogoutSession(body: GetSWSLogoutSessionRequest, bypassLoader = false) {
    const url = `${this.base}/CrewService/v1/getLogoutSession`;
    return this.httpService.post<
      GetSWSLogoutSessionRequest,
      GetSWSLogoutSessionResponse
    >(url, body, bypassLoader);
  }

  getTripSignInInfoV3(body: GetTripSignInInfoV3Request, bypassLoader = false) {
    const url = `${this.base}/SabreService/v3/getTripSignInInfo`;
    return this.httpService.post<
      GetTripSignInInfoV3Request,
      GetTripSignInInfoV3Response
    >(url, body, bypassLoader);
  }

  getUserDetailsByBu(body: GetUserDetailsByBuRequest, bypassLoader = false) {
    const url = `${this.base}/CrewService/getUserDetailsByBu`;
    return this.httpService.post<
      GetUserDetailsByBuRequest,
      GetUserDetailsByBuResponse
    >(url, body, bypassLoader);
  }

  getUserDetailsForHomeV2(
    body: GetUserDetailsForHomeV2Request,
    bypassLoader = false,
  ) {
    const url = `${this.base}/CrewService/v2/getUserDetailsForHome`;
    return this.httpService.post<
      GetUserDetailsForHomeV2Request,
      GetUserDetailsForHomeV2Response
    >(url, body, bypassLoader);
  }

  jpyService(body: JpyServiceRequest, bypassLoader = false) {
    const url = `${this.base}/SabreService/getJPYResponse`;
    return this.httpService.post<JpyServiceRequest, JpyServiceResponse>(
      url,
      body,
      bypassLoader,
    );
  }

  logDeviceDetail(body: LogDeviceDetailRequest, bypassLoader = false) {
    const url = `${this.base}/DeviceLogService/logDeviceDetail`;
    return this.httpService.post<
      LogDeviceDetailRequest,
      LogDeviceDetailResponse
    >(url, body, bypassLoader);
  }

  manageEmployeeFavCommands(
    body: ManageEmployeeFavCommandsRequest,
    bypassLoader = false,
  ) {
    const url = `${this.base}/CrewService/manageEmployeeFavCommands`;
    return this.httpService.post<
      ManageEmployeeFavCommandsRequest,
      ManageEmployeeFavCommandsResponse
    >(url, body, bypassLoader);
  }

  manageEmpPrintPreferences(
    body: ManageEmpPrintPreferencesRequest,
    bypassLoader = false,
  ) {
    const url = `${this.base}/EmployeePrintPrefService/v2/manageEmpPrintPreferences`;
    return this.httpService.post<
      ManageEmpPrintPreferencesRequest,
      ManageEmpPrintPreferencesResponse
    >(url, body, bypassLoader);
  }

  mobileGetMessage(body: MobileGetMessageRequest, bypassLoader = false) {
    const url = `${this.base}/CrewService/mobileGetMessage`;
    return this.httpService.post<
      MobileGetMessageRequest,
      MobileGetMessageResponse
    >(url, body, bypassLoader);
  }

  refreshAppInfo(body: RefreshAppInfoRequest, bypassLoader = false) {
    const url = `${this.base}/CrewService/refreshAppInfo`;
    return this.httpService.post<RefreshAppInfoRequest, RefreshAppInfoResponse>(
      url,
      body,
      bypassLoader,
    );
  }

  reorderEmpPrintPreferencesV1(
    body: ReorderEmpPrintPreferencesV1Request,
    bypassLoader = false,
  ) {
    const url = `${this.base}/EmployeePrintPrefService/v1/reOrderEmpPrintPreferences`;
    return this.httpService.post<
      ReorderEmpPrintPreferencesV1Request,
      ReorderEmpPrintPreferencesV1Response
    >(url, body, bypassLoader);
  }

  retrieveProbyInformation(
    body: RetrieveProbyInformationRequest,
    bypassLoader = false,
  ) {
    const url = `${this.base}/CrewService/retrieveProbyInformation`;
    return this.httpService.post<
      RetrieveProbyInformationRequest,
      RetrieveProbyInformationResponse
    >(url, body, bypassLoader);
  }

  searchFlights(body: SearchFlightsRequest, bypassLoader = false) {
    const url = `${this.base}/CrewService/searchFlights`;
    return this.httpService.post<SearchFlightsRequest, SearchFlightsResponse>(
      url,
      body,
      bypassLoader,
    );
  }

  sendFeedbackEmail(body: SendFeedbackEmailRequest, bypassLoader = false) {
    const url = `${this.base}/CrewService/sendFeedbackEmail`;
    return this.httpService.post<
      SendFeedbackEmailRequest,
      SendFeedbackEmailResponse
    >(url, body, bypassLoader);
  }

  sendFeedbackEmailV2(body: SendFeedbackEmailV2Request, bypassLoader = false) {
    const url = `${this.base}/CrewService/v2/sendFeedbackEmail`;
    return this.httpService.post<
      SendFeedbackEmailV2Request,
      SendFeedbackEmailV2Response
    >(url, body, bypassLoader);
  }

  serviceGetDBFunctions(
    body: ServiceGetDBFunctionsRequest,
    bypassLoader = false,
  ) {
    const url = `${this.base}/CrewService/serviceGetDBFunctions`;
    return this.httpService.post<
      ServiceGetDBFunctionsRequest,
      ServiceGetDBFunctionsResponse
    >(url, body, bypassLoader);
  }

  submitProbySurvey(body: SubmitProbySurveyRequest, bypassLoader = false) {
    const url = `${this.base}/CrewService/submitProbySurvey`;
    return this.httpService.post<
      SubmitProbySurveyRequest,
      SubmitProbySurveyResponse
    >(url, body, bypassLoader);
  }

  submitToIPPrint(body: SubmitToIPPrintRequest, bypassLoader = false) {
    const url = `${this.base}/SabreService/submitToIPPrint`;
    return this.httpService.post<
      SubmitToIPPrintRequest,
      SubmitToIPPrintResponse
    >(url, body, bypassLoader);
  }

  sendEmailsV1(body: FormData, bypassLoader = false) {
    const url = `${this.base}/CrewService/v1/sendEmails`;
    return this.httpService.postFormData<FormData, SendEmailsV1Response>(
      url,
      body,
      bypassLoader,
    );
  }
}
