import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'crew-web-dialog-confirm-modal',
  templateUrl: './dialog-confirm-modal.component.html',
  styleUrls: ['./dialog-confirm-modal.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class DialogConfirmModalComponent {
  @Output() confirmYes: EventEmitter<boolean> = new EventEmitter();

  onClickYes(event: Event) {
    event.stopPropagation();
    this.confirmYes.emit(true);
  }
}
