<div
  *ngIf="!loaded"
  class="flex h-full w-full flex-col items-center justify-center bg-cirrus dark:bg-carbon">
  <div class="mb-4 h-24 w-24">
    <crew-ui-logo [full]="false" />
  </div>
  <crew-ui-spinner [size]="'sm'" />
</div>
<div
  *ngIf="!hasSabreResponse && loaded"
  class="h-full overflow-y-auto bg-cirrus px-4 py-4 dark:bg-carbon dark:text-white">
  <div>
    <div class="mt-4" *ngFor="let message of readAndAcknowledgeMessages">
      <crew-web-message-card [message]="message" />
    </div>
  </div>
  <div
    *ngIf="
      readAndAcknowledgeMessages &&
      readAndAcknowledgeMessages.length > 0 &&
      (tripSignInInfoV3Response | json) !== '{}' &&
      tripSignInInfoV3Response.signInEligible &&
      !tripSignInInfoV3Response.signedIn
    "
    class="mt-4">
    <crew-web-sign-in-card />
  </div>
</div>
<!-- ! SABRE DOCUMENT-->
<crew-web-sabre-document
  (clickClose)="onCloseSabreDocument($event)"
  *ngIf="hasSabreResponse" />
